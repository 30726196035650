.footer {
  background-color: var(--color-dark);
  z-index: 450;

  &__social {
    margin-top: 10px;
  }

  &__right,
  &__policy,
  &__agreement,
  &__developer {
    color: var(--color-white-text-60);
  }

  &__right,
  &__policy,
  &__agreement,
  &__developer,
  &__address {
    font-weight: 400;
    line-height: 145%;
    transition: color var(--default-transition-time) var(--default-timing-function);
    font-size: 13px;
  }

  &__policy,
  &__agreement {
    &:hover {
      color: var(--primary-color);
    }
  }

  &__right {
    grid-column: 1/3;
  }

  &__policy {
    grid-column: 4/7;
  }

  &__agreement {
    grid-column: 7/10;
  }

  &__developer {
    grid-column: 10/12;
  }

  &__phone {
    color: white;
  }

  &__main {
  }

  &__address {
    color: white;
  }

  &__bottom-wrapper {
    border-top: 1px solid var(--color-gray-text);
  }

  &__address-wrapper {
    display: flex;
    align-items: flex-start;
  }

  &__address-img {
    margin-right: 12px;
    display: flex;
  }

  &__bottom {
  }

  &__info-block {
    display: flex;
    flex-direction: column;
    position: relative;
  }

  &__developer-link {
    color: var(--primary-color);
  }

  &__logo {
    display: flex;
    align-items: center;
  }
}

@include respond-up('large') {
  .footer {
    &__logo {
      grid-column: 1/3;
    }

    &__phone {
      margin-bottom: 10px;
    }

    &__info-block {
      grid-column: 8/13;
      padding-left: 40px;

      &::before {
        position: absolute;
        content: '';
        left: 0;
        top: 0;
        bottom: 0;
        border-left: 1px solid var(--color-gray-text);
      }
    }

    &__address {
      font-size: 16px;
    }

    &__bottom {
      padding: 40px 0 50px;
    }
    &__main {
      padding: 40px 0 45px;
    }
  }
}

@include respond-down('medium') {
  .footer {
    &__logo {
      grid-column: 1/5;

      svg {
        flex: 0 0 178px;
        width: 178px;
        height: 34px;
      }
    }

    &__info-block {
      grid-column: 1/5;
      padding-top: 70px;
    }

    &__address {
      font-size: 15px;
    }

    &__phone {
      margin-bottom: 18px;
    }

    &__right, &__policy, &__agreement, &__developer {
      grid-column: 1/5;
    }

    &__bottom, &__main {
      padding: 40px 0 60px;
    }

    &__developer {
      margin-top: var(--grid-gap);
    }
  }
}
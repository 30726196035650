.markup-page {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-gap: var(--grid-gap);
    padding-bottom: 120px;

    &__h1 {
        font-weight: 700;
        font-size: 46px;
        line-height: 135%;
        color: var(--color-dark);
        text-transform: uppercase;
        padding: 30px 0 40px;
    }

    &__h3 {
        margin-bottom: 30px;
    }

    &__inner {
        grid-column: 1/9;
    }

    &__type-tabs, &__categories-tabs {
        display: flex;
    }

    &__categories {
        background-color: white;
        padding: 30px var(--grid-spacer);
        border-radius: 0 2px 0 0;
    }

    &__markup-list {
        margin-top: var(--grid-gap);
        border-radius: 2px;
    }

    &__markup-item {
    }

    &__form {
        display: flex;
        background-color: white;
        align-items: flex-end;
        padding: 0 var(--grid-spacer) 40px;
        border-radius: 0 0 2px 2px;
    }

    &__fields {
        display: flex;
        flex-direction: column;
        flex: 1 1 auto;
    }

    &__field {
        position: relative;
        flex: 1 1 auto;
        &:nth-child(n + 1) {
            margin-top: var(--grid-gap);
        }
    }

    &__select-arrow {
        position: absolute;
        height: 100%;
        width: 10px;
        right: 12px;
        top: 0;
        display: flex;
        align-items: center;
        pointer-events: none;
    }

    &__button {
        margin-left: 28px;
    }

    &__client-list {

    }

    &__client-item {
        position: relative;
        background-color: white;
        display: flex;
        transition: {
            property: box-shadow, border-color;
            duration: var(--default-transition-time);
        };

        &:nth-child(n + 2) {
            border-top: 1px solid var(--color-gray-border);
        }

        &:hover {
            z-index: 10;
            box-shadow: 0 1px 20px rgba(0, 0, 0, 0.1);
            border-top-color: transparent;

            .markup-page__copy {
                border-left-color: var(--color-gray-border);
            }

            & + .markup-page__client-item {
                border-top-color: transparent;
            }
        }

        &._hide {
            display: none;
        }
    }

    &__client-link {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 18px 40px 18px 30px;
        flex: 1 1 auto;

        &:hover {
            .markup-page__client-arrow {
                opacity: 1;
            }
        }
    }

    &__copy {
        padding: 0 30px;
        display: flex;
        align-items: center;
        border-left: 1px solid var(--t);
        transition: {
            property: border-left-color;
            duration: var(--default-transition-time);
        };

        svg {
            path, rect {
                transition: {
                    property: stroke;
                    duration: var(--default-transition-time);
                };
            }
        }

        &:hover {
            svg {
                path, rect {
                    stroke: var(--primary-color);
                }
            }
        }
    }

    &__client-name {
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        color: var(--color-dark);
        flex: 1 0 auto;
    }

    &__client-id {
        flex: 0 0 40px;
        font-weight: bold;
        font-size: 14px;
    }

    &__client-arrow {
        display: flex;
        align-items: center;
        flex: 0 0 auto;
        margin-left: var(--grid-gap);
        transition: {
            property: opacity;
            duration: var(--default-transition-time);
        };
        opacity: 0;
    }

    &__back-link {
        display: flex;
        align-items: center;
        color: var(--primary-color);
        margin-top: 60px;

        svg {
            margin-right: 9px;
        }
    }
}
.notifications-item {
  position: relative;

  &__title {
    transition: color var(--default-transition-time) var(--default-timing-function);
  }

  &__link {
    &:hover {
      .notifications-item__title {
        color: var(--primary-color);
      }
    }
  }

  &::after {
    content: '';
    position: absolute;
    background-color: var(--primary-color);
    width: 8px;
    height: 8px;
    border-radius: 100%;
    opacity: 0;
    transition: opacity var(--default-transition-time) var(--default-timing-function);;
  }

  &:not(:last-child) {
    border-bottom: 1px solid var(--border-color);
  }

  &__link {
    display: flex;
    padding: 20px 40px 20px 30px;

    &_inactive {
      cursor: default;
    }
  }


  &__icon {
    flex: 0 0 20px;
  }

  &__main {
    display: flex;
    flex-direction: column;
  }

  &._unread {
    &::after {
      opacity: 1;
    }

    .notifications-item__title {

    }
  }
}

@include respond-up('large') {
  .notifications-item {
    &::after {
      top: 50%;
      right: 40px;
      transform: translate3d(0, -50%, 0);
    }

    &__link {
      padding: 20px 40px 20px 30px;
    }

    &__main {
      flex: 1 1 auto;
      margin-left: 20px;
    }
  }
}

@include respond-down('medium') {
  .notifications-item {
    &::after {
      top: 20px;
      right: 20px;
    }

    &__link {
      padding: 20px;
    }

    &__main {
      margin-left: 10px;
      flex: 0 0 calc(var(--grid-column3) - 30px);
    }

    &__date {
      margin-top: 6px;
    }
  }
}
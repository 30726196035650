.cart-result-block {
  background-color: white;

  &__total-count {
    font-weight: 700;
    line-height: 125%;
    color: var(--color-gray-text);
  }

  &__total-price {
    font-weight: 700;
    line-height: 133%;
    color: var(--primary-color);
  }

  &__button {
    width: 100%;

    &__continue {
    }
  }

  &__one-c-block {
    padding-top: var(--grid-gap);
  }

  &__one-c-label {
    text-align: center;
    color: var(--reddish-color);
    padding-bottom: 10px;
  }
}

@include respond-up('large') {
  .cart-result-block {
    padding: 30px var(--grid-spacer) 40px;

    &__total-count {
      font-size: 16px;
    }

    &__total-price {
      font-size: 30px;
      margin: 10px 0 30px;
    }

    &__button {
      &_send.button {
        margin-bottom: 20px;
      }
    }
  }
}

@include respond-down('medium') {
  .cart-result-block {
    padding: var(--grid-spacer) var(--grid-spacer) 30px var(--grid-spacer);

    &__total-count {
      font-size: 15px;
      text-decoration: underline;
    }

    &__total-price {
      font-size: 24px;
      margin: 10px 0 20px;
    }

    &__button {
      &_send.button {
        margin-bottom: 12px;
      }
    }
  }
}

.notify-settings {
  height: 100%;
  display: flex;
  align-items: center;

  &__link {
    display: flex;
    align-items: center;
  }

  &__icon {
    display: flex;

    svg path {
      transition: stroke var(--default-transition-time) var(--default-timing-function);
    }
  }

  &__title {
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 133%;
    color: var(--color-dark);
    margin-left: 5px;
    transition: color var(--default-transition-time) var(--default-timing-function);
  }
}

@include respond-up('large') {
  .notify-settings {
    &__link {
      &:hover {
        .notify-settings__title {
          color: var(--primary-color);
        }
        svg path {
          stroke: var(--primary-color);
        }
      }
    }
  }
}

@include respond-down('medium') {
  .notify-settings {
    &__link {
      width: 100%;
      background: var(--color-gray-bg-dark);
      justify-content: center;
      padding: 13px;

      svg path {
        stroke: var(--color-dark);
      }
    }
  }
}
.confirm-page {
  &__title {

  }

  &__subtitle {
    margin-top: 15px;
  }
}

@include respond-up('large') {
  .confirm-page {

  }
}

@include respond-up('medium') {
  .confirm-page {

  }
}

@include respond('medium') {
  .confirm-page {

  }
}

@include respond-down('medium') {
  .confirm-page {

  }
}

@include respond-down('small') {
  .confirm-page {

  }
}
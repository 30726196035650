.identify-header {
  --identify-header-height: 59px;
  z-index: 1000;
  position: relative;
  height: var(--identify-header-height);

  &__container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100vw;
    height: var(--identify-header-height);
    background-color: black;
  }

  &__inner {
    display: flex;
    //align-items: center;
    justify-content: space-between;
    //padding: 10px 20px;
    //height: 50px;
  }

  &__left {
  }

  &__right {
  }

  &__logo {
    font-weight: bold;
    font-size: 30px;
    color: white;
    margin-right: 30px;
    padding: 10px 0;
  }

  &__back {
    color: white;
  }

  &__menu {
    display: flex;
    height: 100%;
  }

  &__menu-item {
    position: relative;
    height: 100%;

    &:hover {
      .identify-header__child-menu {
        display: block;
      }
    }
  }

  &__menu-link {
    height: 100%;
    display: flex;
    align-items: center;
    padding: 10px;
    color: white;
    text-align: center;
    font-size: 15px;
    text-decoration: underline;
  }

  &__child-menu {
    display: none;
    position: absolute;
    left: 0;
    top: 100%;
    background-color: #2a2a2a;
    min-width: 100%;
  }

  &__child-menu-item {
    min-width: 100%;
  }

  &__child-menu-link {
    display: block;
    white-space: nowrap;
    color: white;
    padding: 10px;
  }
}

.price-editor {
  background-color: #ececec;
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 10000;
  border-radius: 6px 0 0 0;
  width: 90px;

  &._open {
    .price-editor {
      &__wrap {
        display: block;
      }
    }
  }

  &__title {
    font-weight: bold;
    padding: 7px 10px;
    text-align: center;
    display: block;
  }

  &__wrap {
    padding: 7px 10px;
    display: none;
  }
  &__input {
    width: 100%;
  }
}

@include respond-up('large') {
  .price-editor {

  }
}

@include respond-down('medium') {
  .price-editor {
    display: none;
  }
}
.index-header {
  position: fixed;
  width: 100%;
  z-index: 200;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);

  &_dark {
    .index-header__background {
      background: var(--color-dark-backgorund);
    }
  }

  &__background {
    width: 100%;
    height: 100%;
    transition: {
      property: background-color;
      duration: var(--default-transition-time);
    };
  }

  &._sticky {
    .index-header {
      &__background {
        background: var(--color-dark-backgorund);
      }
    }
  }

  &__contact {
    height: var(--index-header-height);
    display: flex;
    justify-content: flex-start;
    align-items: center;

  }

  &__phone-icon {
    display: flex;
    align-items: center;
  }

  &__inner {
    grid-column: 10/13;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .button {
      display: flex;
      align-items: center;
      justify-content: center;
      height: fit-content;
      min-width: 140px;
      padding: 12px 27px 14px;

      &__link {
        margin-right: 3px;
      }
    }
  }
}

@include respond-up('large') {
  .index-header {
    --index-header-height: 83px;

    &__logo {
      grid-column: 1/3;
    }

    &__phone {
      grid-column: 4/6;
    }

    &__address {
      grid-column: 6/10;
    }

    &__phone-icon_mobile {
      display: none;
    }

    &__contact {
      svg {
        margin-right: 8px;
      }
    }
  }
}

@include respond-down('medium') {
  .index-header {
    --index-header-height: 50px;

    &__grid.grid-block {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &__address, &__inner, &__phone-text, &__phone-icon_desktop {
      display: none;
    }

    &__logo {
      flex: 0 0 128px;
      width: 128px;
    }
  }
}
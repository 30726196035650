.your-manager-block {
  background-color: white;
  border-radius: 2px;

  &__label {
    background: var(--color-gray-bg);
    border-radius: 2px;
    font-weight: 400;
    line-height: 133%;
    display: inline-block;
  }

  &__name {
    font-weight: 700;
    line-height: 145%;
  }

  &__points-wrap {
    display: flex;
    flex-direction: column;
  }

  &__point {
    position: relative;
    font-weight: 400;
    line-height: 150%;
    display: inline-block;
    padding-left: 20px;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      transform: translate3d(0, -50%, 0);
      width: 14px;
      height: 14px;
    }

    &._number {
      &::before {
        background: {
          image: url('../images/svg/number.svg');
          size: contain;
        };
      }
    }

    &._email {
      &::before {
        background: {
          image: url('../images/svg/mail.svg');
          size: contain;
        };
      }
    }
  }
}

@include respond-up('large') {
  .your-manager-block {
    padding: 30px 40px 40px;

    &__label {
      padding: 3px 8px;
      font-size: 12px;
      margin-bottom: 21px;
    }

    &__name {
      font-size: 16px;
      margin-bottom: 20px;
    }

    &__point {
      font-size: 16px;

      &._number {
        margin-bottom: 8px;
      }

      &._email {
        margin-bottom: 28px;
      }
    }
  }
}

@include respond-down('medium') {
  .your-manager-block {
    padding: 20px 20px 40px;

    &__label {
      padding: 3px 6px;
      font-size: 11px;
    }

    &__name {
      font-size: 15px;
      margin-top: 14px;
    }

    &__points-wrap {
      margin-top: 10px;
      row-gap: 6px;
    }

    &__point {
      font-size: 15px;
    }

    &__button {
      &.button {
        margin-top: 20px;
        width: 100%;
      }
    }
  }
}
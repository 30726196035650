.identify-raw-data {
  &__container {

  }

  &__name {
    margin: 30px 0 10px;
    font-weight: bold;
  }

  &__pre {
    background-color: black;
    color: #81de74;
    font-family: monospace;
    padding: 20px;
  }
}

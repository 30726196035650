.stat-client-actions {
  &__headers {
    display: grid;
    grid-gap: var(--grid-gap);
    grid-template-columns: 90px 80px 200px 650px;
    padding: 5px;
  }

  &__title {
    margin-bottom: 10px;
  }

  &__form {
    padding: 20px 0;
  }

  &__fields {
    display: grid;
    grid-gap: var(--grid-gap);
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  }

  &__total {
    background-color: var(--color-gray-bg);
    color: var(--color-gray-text);
    font-size: 18px;
    margin-top: 20px;
    padding: 20px;
    text-align: center;
    font-weight: bold;
  }

  &__header-item {
    font-weight: bold;
    text-align: center;

    &._date {
    }

    &._time {
    }

    &._type {
    }
  }

  &__list {
  }

  &__item {
    display: grid;
    grid-gap: var(--grid-gap);
    grid-template-columns: 90px 80px 200px 650px;
    padding: 5px;

    &:nth-child(odd) {
      background-color: var(--color-gray-bg-70);
    }
  }

  &__item-value {
    &._date {
      font-size: 14px;
    }

    &._time {
      font-size: 14px;
      text-align: center;
    }

    &._type {
      text-align: center;

      &._view {
        color: var(--color-gray-text);
      }

      &._cart {
        color: var(--color-dark);
      }

      &._order {
        color: var(--greenish-color);
      }
    }

    &._product {
      a {
        color: var(--primary-color);
      }
    }
  }
}

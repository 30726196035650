.profile-page {

}

@include respond-up('large') {
  .profile-page {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-column-gap: var(--grid-gap);
    padding-bottom: 120px;

    &_manager {
      .order-filter-form {
        &__field {
          .form-field {
            flex: 0 0 22%;
          }
        }
      }
    }

    &__grid-container {
      display: grid;
      grid-gap: var(--grid-gap);
      grid-template-columns: repeat(10, 1fr);
    }

    &__reduce-content {
      grid-column: 1/10;
    }

    &__client-content-header-link,
    &__manager-content-header-link {
      display: none;
    }

    &__has-sidebar-content {
      display: grid;
      grid-template-columns: repeat(10, 1fr);
      grid-column-gap: var(--grid-gap);
    }

    &__menu {
      grid-column: 1/3;
    }

    &__main {
      grid-column: 3/13;
    }

    &__content {
      grid-column: 1/8;
    }

    &__sidebar {
      grid-column: 8/11;
    }

    &__sidebar-sticky {
      position: sticky;
      top: var(--grid-gap);
    }

    &__content-heading {
      display: none;
    }
  }
}

@include respond-down('medium') {
  .profile-page {
    padding-bottom: 60px;

    &_hide-heading-border {
      .profile-page {
        &__mobile-title-wrap {
          border-bottom: unset;
          margin-bottom: var(--grid-gap);
        }
      }
    }

    &__heading {
      display: none;
    }

    &__grid-container {
      display: grid;
      grid-gap: var(--grid-gap);
      grid-template-columns: repeat(4, 1fr);
    }

    &__reduce-content {
      grid-column: 1/5;
    }

    &__content-header-title, &__menu {
      display: none;
    }

    &__has-sidebar-content {
        display: flex;
        flex-direction: column;
    }

    &__content-heading {
      order: 5;
      padding-top: 40px;
    }

    &__content {
      order: 10;
    }

    &__sidebar {
      order: 15;
      margin-top: var(--grid-gap);
    }

    &_reverse {
      .profile-page__content {
        order: 15;
        margin-top: var(--grid-gap);
      }
      .profile-page__sidebar {
        order: 10;
        margin-top: 0;
      }
    }

    &__back-link {
      position: relative;
      display: flex;
      align-items: center;
      margin-bottom: var(--grid-gap);
    }

    &__back-icon {
      display: flex;
      align-items: center;
      margin-right: 5px;
    }

    &__back-text {
      font-size: 14px;
      line-height: 133%;
      color: var(--color-primary-dark);
      font-weight: 600;
    }

    &__mobile-title-wrap {
      background: white;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 15px var(--grid-spacer);
      border-bottom: 1px solid var(--color-gray-border);
    }

    &__mobile-title-icon {
      display: flex;
      align-items: center;
      width: 20px;
      height: 20px;

      svg {
        width: 100%;
        height: 100%;
        path {
          stroke: var(--color-primary-dark);
        }
      }
    }
  }
}

.cart-state-block {
  position: sticky;
  bottom: 0;
  background-color: white;
  width: 100%;
  box-shadow: 0px -2px 20px rgba(0, 0, 0, 0.1);
  transition: opacity var(--default-transition-time) var(--default-timing-function), transform var(--default-transition-time) var(--default-timing-function);
  transform: translate3d(0,0,0);
  z-index: 500;

  &._hide {
    opacity: 0;
    transform: translate3d(0,80px,0);
  }

  &__inner {
  }

  &__total-text {
    font-weight: 400;
    color: var(--color-gray-text);
  }

  &__count-wrapper {
    display: flex;
    font-weight: 600;
    color: var(--primary-color);
  }

  &__total-number {
  }

  &__total-wrapper {
    display: flex;
  }

  &__button-wrapper {
    display: flex;
  }
}

@include respond-up('large') {
  .cart-state-block {
    &__inner {
      height: 80px;
    }

    &__total-text {
      font-size: 16px;
      margin-right: 10px;
      margin-top: 4px;
      line-height: 150%;
    }

    &__count-wrapper {
      grid-column: 1/4;
      height: 100%;
      font-size: 18px;
      align-items: center;
      line-height: 133%;
    }

    &__total-number {
      font-size: 24px;
      color: var(--color-dark);
      font-weight: 700;
      line-height: 120%;
    }

    &__total-wrapper {
      grid-column: 4/8;
      height: 100%;
      align-items: center;
    }

    &__button-wrapper {
      grid-column: 10/13;
      align-items: center;
      justify-content: flex-end;
    }

    &__button-arrow, &__button-icon {
      display: none;
    }
  }
}

@include respond-down('medium') {
  .cart-state-block {
    &__inner.grid-block {
      height: 60px;
      grid-gap: 3px var(--grid-gap);
    }

     &__count-wrapper {
       grid-column: 1/4;
       font-size: 15px;
       align-items: flex-end;
       line-height: 130%;
     }

    &__total-wrapper {
      grid-column: 1/4;
      align-items: flex-start;
    }

    &__button-wrapper {
      grid-column: 4/5;
      grid-row: 1/3;
      padding: 10px 0;
    }

    &__total-text, &__total-number {
      font-size: 12px;
      font-weight: 400;
      line-height: 145%;
    }

    &__total-number {
      margin-left: 3px;
      color: var(--color-gray-text);
    }

    &__button.button {
      flex: 0 0 100%;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
    }

    &__button-text {
      display: none;
    }

    &__button-icon, &__button-arrow {
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 0 0 24px;
      height: 24px;
    }
  }
}
.avatar-download-form {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--color-dark);
    opacity: .6;
  }

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    width: 24px;
    height: 24px;
    background: {
      image: url('../images/svg/photo.svg');
      position: 50% 50%;
      repeat: no-repeat;
    };
  }

  &_has-image {
    &::before,
    &::after {
      opacity: 0;
      transition: opacity .4s var(--default-timing-function);
    }

    &:hover {
      &::before {
        opacity: .6;
      }

      &::after {
        opacity: 1;
      }
    }
  }
}
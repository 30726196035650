.index-request-form {
  //background: radial-gradient(156.12% 614.61% at 41.81% -23.19%, #1F1F1F 0.56%, #323232 44.86%, #000000 99.89%);
  background: linear-gradient(104.25deg, #1F1F1F -32.27%, #323232 22.31%, #000000 90.12%);
  position: relative;

  &:before {
    content: "";
    position: absolute;
    width: 778px;
    height: 778px;
    background: radial-gradient(50% 50% at 50% 50%, #FF7C1D 0%, rgba(255, 124, 29, 0) 100%);
  }

  &:after {
    content: "";
    position: absolute;
    background-image: url('../images/assets/bg-tyres.png');
    background-repeat: no-repeat;
    background-size: contain;
  }

  .form__field-wrapper-dark {
    position: relative;
  }

  ul.errors {
    position: absolute;
    bottom: -22px;
    left: 0;
  }

  &__link {
    color: var(--color-gray-border);

    a {
      color: var(--primary-color);
    }
  }

  &__wrapper {
    overflow: hidden;
  }
}

@include respond-up('large') {
  .index-request-form {
    height: 427px;

    &:before {
      right: -120px;
      top: -51px;
    }

    &:after {
      width: 911px;
      height: 646px;
      right: -220px;
      bottom: -60px;
    }

    &__success {
      .index-request-form__title {
        margin-top: 19px;
      }
    }

    &__button {
      display: flex;
      flex: 0 0 calc((100% - var(--grid-gap)) / 2);
    }

    &__link {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: center;
      text-align: right;
      flex: 0 0 calc((100% - var(--grid-gap)) / 2);
    }

    &__description {
      margin-top: 8px;
    }

    &__text-wrapper {
      grid-column: 1/4;
      margin-bottom: 10px;
    }

    &__fields-wrap {
      grid-column: 1/4;
      grid-row: 2;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-column-gap: var(--grid-gap);
    }

    &__bottom-wrap {
      grid-column: 2/4;
      display: flex;
      column-gap: var(--grid-gap);
    }

    &__form-inner {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-column-gap: var(--grid-gap);
      grid-row-gap: 30px;
    }

    &__form {
      grid-column: 1/8;
      margin-top: 60px;
    }
  }
}

@include respond-down('medium') {
  .index-request-form {
    height: 868px;

    &:before {
      right: -269px;
      bottom: -413px;
    }

    &:after {
      width: 552px;
      height: 392px;
      right: -162px;
      bottom: -32px;
    }

    &__form {
      grid-column: 1/5;
      margin-top: 40px;
    }

    &__description {
      margin-top: 16px;
    }

    &__fields-wrap {
      display: flex;
      flex-direction: column;
      row-gap: 20px;
      margin-top: 30px;
    }

    &__bottom-wrap {
      display: flex;
      flex-direction: column-reverse;
      row-gap: 10px;
      margin-top: 30px;
    }
  }
}
.order-list-header {
  &__item {
  }
}

@include respond-up('large') {
  .order-list-header {
    &_client {
      grid-template-columns: repeat(9,1fr);

      .order-list-header {
        &__item {
          &_number {
            padding-left: 20px;
            grid-column: 1/2;
          }
          &_date {
            grid-column: 2/3;
          }
          &_paid {
            grid-column: 4/5;
          }
          &_state {
            grid-column: 6/8;
          }
          &_summ {
            grid-column: 8/9;
          }
        }
      }
    }

    &_manager {
      grid-template-columns: repeat(10,1fr);

      .order-list-header {
        &__item {
          &_number {
            padding-left: 20px;
            grid-column: 1/2;
          }
          &_date {
            grid-column: 2/3;
          }
          &_client {
            grid-column: 3/5;
          }
          &_manager {
            grid-column: 5/7;
          }
          &_summ {
            grid-column: 9/10;
          }
          &_paid {
            grid-column: 7/8;
          }
          &_state {
            grid-column: 8/9;
          }
        }
      }
    }
  }
}

@include respond-down('medium') {
  .order-list-header {
    &__item {
      &_number {
        margin-right: 20px;
        flex: 0 0 40px;
        width: 40px;
      }

      &_date {
        flex: 0 0 64px;
        width: 64px;
      }

      &_summ {
        flex: 0 0 80px;
        width: 80px;
      }

      &_paid {
        flex: 0 0 100px;
        width: 100px;
      }

      &_state {
        flex: 0 0 117px;
        width: 117px;
      }

      &_client, &_manager {
        flex: 0 0 140px;
        width: 140px;
      }

      &_date,
      &_summ,
      &_paid,
      &_client, &_manager {
        margin-right: 30px;
      }
    }
  }
}
.catalog-avail-list {
  &__more-text-opened {
    display: none;
  }

  &__delivery {
    opacity: 0;
    position: absolute;
    left: 0;
    top: -18px;
    transition: opacity var(--default-transition-time) ease-in-out;
    pointer-events: none;
    z-index: 300;
  }

  &__delivery-text {
    padding: 4px 6px;
    background: var(--color-primary-white);
    color: var(--color-dark);
    font-weight: 400;
    font-size: 11px;
    line-height: 120%;
    border-radius: 3px;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      bottom: -5px;
      left: 50%;
      transform: translateX(-50%);
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 5px 5px 0 5px;
      border-color: var(--color-primary-white) transparent transparent transparent;
    }
  }

  &._opened {
    .catalog-avail-list {
      &__item {
        &:nth-child(n+4) {
          //opacity: 1;
          display: grid;
        }
      }

      &__more-text-opened {
        display: flex;
      }

      &__more-text-closed {
        display: none;
      }

      &__more-text {
        &:before {
          transform: rotate(-45deg) translate(0, -50%);
        }

        &:after {
          transform: rotate(45deg) translate(2px, -150%);
        }
      }
    }
  }

  &__list-wrapper {
    height: auto !important;
    //height: 102px;
    //transition: height .4s ease-in-out;
  }

  &__item {
    display: grid;
    grid-template-columns:  3fr 3fr 3fr 2fr 2fr 3fr 3fr;
    padding: 4px 0;
    grid-column-gap: var(--grid-gap);
    position: relative;

    &:nth-child(odd) {
      background-color: var(--color-gray-bg-70);
    }

    &:nth-child(n+4) {
      //opacity: 0;
      //transition: opacity .4s ease-in-out;
      display: none;
    }

    &:hover {
      .catalog-avail-list {
        &__delivery {
          opacity: 1;
        }
      }
    }
  }

  &__more-text {
    position: relative;

    &:before,
    &:after {
      content: "";
      position: absolute;
      background: var(--color-primary-dark);
      height: 2px;
      width: 7px;
      right: -12px;
      top: 50%;
      transition: transform .4s ease-in-out;
    }

    &:before {
      transform: rotate(135deg) translate(-2px, -50%);
    }

    &:after {
      transform: rotate(225deg) translate(2px, -50%);
    }
  }

  &__more {
    padding: 4px 0 4px 20px;
    color: var(--color-primary-dark);
    font-weight: 600 !important;
    display: flex;
  }

  &__storage {
    position: relative;
    grid-column: 1/3;
    display: flex;
    align-items: center;
    padding-left: 20px;
    color: var(--color-gray-text);
  }

  &__price-block,
  &__quantity {
    // это не по макету, но так было бы правильно:
    // штуки и рубли выравнивают по правому краю
    // justify-content: flex-end;
  }

  &__quantity {
    grid-column: 3/4;
    display: flex;
    align-items: center;
    color: var(--color-gray-text);
  }

  &__price-block,
  &__sell-price-block {
    display: flex;
    align-items: center;
  }

  &__price {
    grid-column: 4/5;
    color: var(--color-dark);
    font-weight: 700 !important;
    white-space: nowrap;
  }

  &__extra-block {
    position: relative;
    padding-left: 5px;
    padding-bottom: 2px;
    z-index: 100;
    display: flex;
    height: 100%;
    align-items: center;

    &:hover {
      .catalog-avail-list__extra {
        opacity: 1;
        pointer-events: all;
      }
    }
  }

  &__extra-icon {
    background: var(--color-gray-text);
    width: 10px;
    height: 10px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 1px;
  }

  &__extra {
    transform: translateY(-50%);
    top: 50%;
    left: calc(100% + 5px);
    opacity: 0;
    transition: opacity .4s ease-in-out;
    pointer-events: none;
    position: absolute;
  }

  &__extra-text {
    padding: 4px 6px;
    background: var(--color-primary-white);
    color: var(--color-dark);
    font-weight: 400;
    font-size: 11px;
    line-height: 120%;
    border-radius: 3px;
    position: relative;
    white-space: nowrap;

    &:before {
      content: '';
      position: absolute;
      left: -5px;
      top: 50%;
      transform: translateY(-50%);
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 5px 5px 5px 0;
      border-color: transparent var(--color-primary-white) transparent transparent;
    }
  }

  &__order-block {
    grid-column: 6/8;
  }
}

@include respond-up('large') {
  .catalog-avail-list {

  }
}

@include respond-down('medium') {
  .catalog-avail-list {
    &__storage {
      text-decoration: underline;
    }
  }
}
.catalog-filter-extend {

  &__accordion-list {
  }

  &__accordion-item {
    &._opened {
      .catalog-filter-extend {
        &__icon-line:nth-child(2) {
          height: 0;
          top: 50%;
        }
      }
    }
  }

  &__accordion-link {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--color-gray-bg-dark);
    border-radius: 2px;
  }

  &__accordion-text {
    font-weight: 700;
    line-height: 125%;
    color: var(--color-dark);
  }

  &__accordion-icon {
    position: relative;
    border: 1px solid var(--color-gray-border);
    border-radius: 100px;
  }

  &__icon-line {
    position: absolute;
    background-color: var(--color-dark);
    display: block;

    &:nth-child(1) {
      width: 12px;
      height: 2px;
      left: calc(50% - 6px);
      top: calc(50% - 1px);
    }

    &:nth-child(2) {
      width: 2px;
      height: 12px;
      left: calc(50% - 1px);
      top: calc(50% - 6px);
      transition: {
        property: height, top;
        duration: .2s;
        timing-function: linear;
      };
    }
  }

  &__accordion-block {
    overflow: hidden;
    height: 0;
    transition: {
      property: height;
      duration: var(--default-transition-time);
    };
  }

  &__accordion-block-inner {
    position: relative;
    padding: 20px;
  }

  &__ending-wrapper {
    display: flex;
    padding-top: 30px;
  }

  &__ending-fields {
    display: flex;
  }

  &__ending-field {

    &._checkbox {
      display: flex;
      align-items: flex-end;
      padding-bottom: 11px;
    }
  }

  &__button-wrapper {
    flex: 0 0 auto;
    display: flex;
    align-items: flex-end;
  }

  &__button {
  }

  &__filter-values-block {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
  }

  &__text-input {
  }

  &__filter-values-count {
    margin-left: var(--grid-gap);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 145%;
    white-space: nowrap;
    color: var(--color-gray-text);
  }

  .checkbox-list {
    &__chunks {
      display: flex;
    }

    &__range-wrap {
    }

    &__range-input {
    }

    &__group {
      &:nth-child(n + 2) {
        margin-top: var(--grid-gap);
      }
    }

    &__group-name {

    }

    &__group-values {
      display: flex;
      flex-wrap: wrap;
    }
  }
}

@include respond-up('large') {
  .catalog-filter-extend {
    margin-top: 40px;

    &__accordion-item {
      margin-bottom: 12px;
      background-color: var(--color-gray-bg);
    }

    &__accordion-link {
      padding: 10px 20px;
    }

    &__accordion-text {
      font-size: 16px;
    }

    &__accordion-icon {
      flex: 0 0 30px;
      width: 30px;
      height: 30px;
    }

    &__text-input {
      flex: 0 0 200px;
    }

    &__ending-fields {
      flex: 1 1 auto;
    }

    &__ending-field {
      flex: 0 1 288px;
      padding-right: 28px;
    }

    &__button {
      &:first-child {
        margin-right: 28px;
      }
    }

    .checkbox-list {
      &__range-wrap {
        display: flex;
        margin-bottom: 30px;
      }

      &__range-input {
        width: calc(var(--grid-column2) - 30px);

        &:not(:first-child) {
          margin-left: var(--grid-gap);
        }
      }

      &__chunk {
        flex: 0 0 232px;
      }

      &__group-value {
        flex: 0 0 20%;
      }
    }
  }
}

@include respond-down('medium') {
  .catalog-filter-extend {
    margin-top: 30px;

    &__accordion-item {
      margin-bottom: 4px;
      background-color: white;
    }

    &__accordion-link {
      padding: 9px var(--grid-spacer);
    }

    &__accordion-text {
      font-size: 15px;
    }

    &__accordion-icon {
      flex: 0 0 24px;
      width: 24px;
      height: 24px;
    }

    &__text-input {
      flex: 1 1 auto;
    }

    &__ending-wrapper {
      flex-direction: column;
    }

    &__ending-fields {
      flex-direction: column;
      padding: 0 var(--grid-spacer);
    }

    &__ending-field {
      &:nth-child(n + 2) {
        margin-top: 19px;
      }
    }

    &__button-wrapper {
      margin-top: 40px;
      flex-direction: column-reverse;
      padding: 0 var(--grid-spacer);
    }



    &__button {
      width: 100%;

      &:first-child {
        margin-top: 19px;
      }
    }

    .checkbox-list {
      &__range-wrap {
        display: grid;
        grid-gap: var(--grid-gap);
        grid-template-columns: 1fr 1fr;
        margin-bottom: 30px;
      }

      &__chunk {
        flex: 1 1 auto;
      }

      &__group-value {
        flex: 0 0 100%;
      }
    }
  }
}
.catalog-client-selector {

}

@include respond-up('large') {
  .catalog-client-selector {

  }
}


@include respond-down('medium') {
  .catalog-client-selector {
    margin-bottom: var(--grid-gap);

    .custom-select {
      &__iconable-field {
        width: 100%;
        padding: 12px;
        background-color: white;
        border-radius: 2px;
      }

      &__content-wrap.custom-select__content-wrap_openable {
        width: 100%;
      }
    }
  }
}

.about-me-block {
  background-color: white;

  &__picture {
    display: flex;
    border-radius: 100%;
    overflow: hidden;
    position: relative;
  }

  &__name {
    font-weight: 600;
    font-size: 18px;
    line-height: 145%;
  }

  &__info-container {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid var(--color-gray-border);
  }

  &__point {
    display: flex;
  }

  &__point-title {
    font-weight: 400;
    line-height: 150%;
    color: var(--color-gray-text);
  }

  &__point-value {
    font-weight: 400;
    line-height: 150%;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  &__item {
    position: relative;
    font-weight: 400;
    line-height: 150%;
    display: inline-block;
    padding-left: 20px;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      transform: translate3d(0, -50%, 0);
      width: 14px;
      height: 14px;
    }
    // TODO Миша бл*
    &._number {
      &::before {
        background: {
          image: url('../images/svg/number.svg');
          size: contain;
        };
      }
    }

    &._email {
      &::before {
        background: {
          image: url('../images/svg/mail.svg');
          size: contain;
        };
      }
    }
  }

  &__avatar {
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
  }
}

@include respond-up('large') {
  .about-me-block {
    padding: 40px var(--grid-spacer) 50px;
    grid-column: 10/13;

    &__picture {
      width: 80px;
      height: 80px;
    }

    &__name {
      margin-bottom: 20px;
    }

    &__info-container {
      margin-bottom: 30px;
    }

    &__point {
      margin-bottom: 8px;

      &:last-child {
        margin-bottom: 30px;
      }
    }

    &__point-title {
      font-size: 16px;
      margin-right: 6px;
    }

    &__point-value {
      font-size: 16px;
    }

    &__item {
      font-size: 16px;

      &._number {
        margin-bottom: 8px;
      }
    }

    &__avatar {
      height: 80px;
      width: 80px;
    }
  }
}

@include respond-down('medium') {
  .about-me-block {
    padding: 20px 20px 30px;

    &__logo-wrap {
      display: flex;
      column-gap: 20px;
      align-items: center;
    }

    &__picture,
    &__avatar {
      width: 60px;
      height: 60px;
    }

    &__avatar {
      flex: 0 0 60px;
    }

    &__info-container {
      margin-top: 30px;
      row-gap: 8px;
      padding-bottom: 20px;
    }

    &__point-title {
      font-size: 15px;
      margin-right: 10px;
    }

    &__point-value {
      font-size: 15px;
    }

    &__contacts-wrap {
      display: flex;
      flex-direction: column;
      margin-top: 20px;
      row-gap: 6px;
    }

    &__item {
      font-size: 15px;
    }
  }
}
.profile-menu {
  &__list {
    position: sticky;
    top: var(--grid-gap);
  }

  &__link {
    display: flex;
    align-items: center;
    transition: background-color var(--default-transition-time) var(--default-timing-function);

    &._active {
      background-color: var(--color-primary-white);

      .profile-menu {
        &__link-icon {
          svg {
            path {
              stroke: var(--primary-color);
            }
          }
        }

        &__link-name {
          color: black;
        }
      }
    }
  }

  &__link-icon {
    display: flex;

    svg {
      path {
        transition: stroke .4s ease-in-out;
      }
    }
  }

  &__link-name {
    transition: color .4s ease-in-out;
    font-weight: 600;
    line-height: 145%;
  }
}

@include respond-up('large') {
  .profile-menu {
    &__list {
      background-color: white;
    }

    &__link {
      padding: 23px;

      &:hover {
        background-color: var(--color-primary-white);

        .profile-menu {
          &__link-icon {
            svg {
              path {
                stroke: var(--primary-color);
              }
            }
          }

          &__link-name {
            color: black;
          }
        }
      }
    }

    &__link-icon {
      margin-right: 21px;
    }

    &__link-name {
      color: var(--color-gray-text);
      font-size: 18px;
    }
  }
}

@include respond-down('medium') {
  .profile-menu {
    &__list {
      display: flex;
      flex-direction: column;
      row-gap: 4px;
    }

    &__item {
      background-color: white;
      border-radius: 2px;
    }

    &__link {
      padding: 15px 20px;
    }

    &__link-icon {
      margin-right: 12px;

      svg path {
        stroke: var(--primary-color);
      }
    }

    &__link-name {
      color: var(--color-dark);
      font-size: 16px;
    }
  }
}
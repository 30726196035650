.chat-client-list {
  &__picture {
    display: flex;
    width: 30px;
    height: 30px;
    overflow: hidden;
  }

  &__no-image {
    border-radius: 100%;
    overflow: hidden;
    background-color: var(--primary-color);
    color: white;
    font-weight: bold;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__link {
    display: flex;
    align-items: center;
    position: relative;

    &._message {
      &::before {
        position: absolute;
        content: '';
        top: 50%;
        transform: translate3d(0, -50%, 0);
        border-radius: 100%;
        background-color: var(--primary-color);
      }
    }
  }

  &__item {
    position: relative;
    border-top: 1px solid var(--color-gray-border);
    transition: border var(--default-transition-time) var(--default-timing-function);

    &:first-child {
      border-top: 1px solid transparent;
    }

    &::before {
      transition: opacity var(--default-transition-time) var(--default-timing-function),
      background-color var(--default-transition-time) var(--default-timing-function);
      content: '';
      position: absolute;
      top: -1px;
      right: 0;
      bottom: 0;
      left: 0;
      opacity: 0;
      background-color: var(--color-gray-border);
    }
  }

  &__avatar-container {
    position: relative;
    margin-right: 10px;
    height: 30px;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;

    &._status {
      &::before {
        content: '';
        position: absolute;
        z-index: 20;
        border-radius: 100%;
        width: 8px;
        height: 8px;
        bottom: 0;
        right: 0;
        background-color: var(--greenish-color);
        border: 2px solid white;
      }
    }
  }

  &__avatar {
    object-fit: contain;
    object-position: center;
    border-radius: 100%;
    z-index: 1;
    height: 100%;
    width: 100%;
  }
}

@include respond-up('large') {
  .chat-client-list {
    height: 100%;
    overflow: auto;
    &__link {
      padding: 20px 30px;

      &._message {
        &::before {
          right: 20px;
          width: 10px;
          height: 10px;
        }
      }
    }

    &__list {
      overflow: auto;
      height: 100%;
    }

    &__item {
      &:hover {
        border-top: 1px solid transparent;

        &::before {
          opacity: 0.2;
        }
      }

      &:hover + li {
        border-top: 1px solid transparent;
      }
    }
  }
}

@include respond-down('medium') {
  .chat-client-list {
    &__link {
      padding: 14px var(--grid-spacer);

      &._message {
        &::before {
          right: var(--grid-spacer);
          width: 8px;
          height: 8px;
        }
      }
    }

    &__item {
      &:first-child {
        .chat-client-list__link {
          padding-top: 20px;
        }
      }
    }
  }
}
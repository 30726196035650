.error-page {
  position: relative;
  flex: 1 0 auto;

  &__back {
    z-index: 10;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background: linear-gradient(258.23deg, #2C2B2E 8.74%, #09080A 91.42%);
    overflow: hidden;
  }

  &__front {
    position: relative;
    z-index: 20;
  }

  &__inner {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__title {
    color: white;
  }

  &__subtitle.text {
    color: rgba(255, 255, 255, 0.5);
    margin: 10px 0 40px;
  }

  &__link {
    border: 2px solid rgba(255, 124, 29, 0.5);
    border-radius: 2px;
  }

  &__code {
    display: flex;
  }

  &__ellipse {
    position: absolute;
    border-radius: 1000px;
    background: #FFD9B7;
    opacity: 0.25;
    filter: blur(202.5px);
  }

  &__number {
    color: rgba(255, 255, 255, 0.2);
    font-weight: 800;
  }

  &__code-part {
    &_middle {
      margin: 0 17px;
    }
  }
}

@include respond-up('large') {
  .error-page {
    padding-top: 83px;

    &__front {
      padding: 80px 0 150px;
    }

    &__number {
      line-height: 242px;
      font-size: 320px;
    }

    &__code {
      margin-bottom: 102px;
    }

    &__ellipse {
      left: calc(50% - 808px);
      top: -337px;
      height: 1011px;
      width: 1011px;
      opacity: 0.25;
    }
  }
}

@include respond-down('medium') {
  .error-page {
    padding-top: 50px;

    &__title, &__subtitle {
      text-align: center;
    }

    &__front {
      padding: 50px 0 75px;
    }

    &__number {
      line-height: 114px;
      font-size: 150px;
    }

    &__code {
      margin-bottom: 51px;
    }

    &__ellipse {
      left: calc(50% - 404px);
      top: -166px;
      height: 505px;
      width: 505px;
    }
  }
}
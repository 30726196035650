.orders-content {
}

@include respond-down('medium') {
  .orders-content {
    &__tabs {
      overflow: scroll hidden;
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
      overflow: -moz-scrollbars-none; /* Firefox */

      &::-webkit-scrollbar {
        display: none;
      }

      section::-webkit-scrollbar {
        width: 0 !important
      }
    }

    &__tab-link {
      white-space: nowrap;
    }
  }
}
$treeHeaderHeight: 30px;
$treeNodePadding: 25px;
$tree-filter-head-bottom: 8px;
$tree-sub-left-padding: 11px;

$filter-head-bottom: 8px;
$form-element-height: 122px;
$sub-left-padding: 11px;

// Colors
$title-color: #282828;
$menu-bg-color: #1E2834;
$placeholder-color: #727272;

$dark-alert-color: #b50000;
$alert-color: #DD5B5B;
$light-alert-color: #ffdddd;
$form-error-color: $alert-color;

$black-color: #2A2A2A;
$gray-color: #929292;
$light-gray-color: #F9F9F9;

// Stems colors

$stem-color-1: #E5F6FF;
$stem-color-2: #BFFAED;
$stem-color-3: #D9D2E9;
$stem-color-4: #D9D3B7;
$stem-color-5: #CFE0E3;

$stem-color-6: #FFF3CC;
$stem-color-7: #E4F2BB;
$stem-color-8: #EAD1DC;
$stem-color-9: #FDE5CD;
$stem-color-10: #F4CCCB;

$stem-color-11: #f5dbff;
$stem-color-12: #b0affa;
$stem-color-13: #e9e0d6;
$stem-color-14: #d9b5c0;
$stem-color-15: #d5e3dd;
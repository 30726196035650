.client-competitor-form {
  padding: 28px var(--grid-spacer);
  position: relative;

  &__title {
    font-size: 24px;
    font-weight: 700;
    line-height: 120%;
    margin-bottom: 28px;
  }

  &__form {

  }
  &__input {
    display: flex;
    flex-direction: column;
  }

  &__button-wrapper {
    margin-top: 20px;
  }

  &__content {
    display: flex;
    justify-content: space-between;
  }

  &__description {
    flex: 0 0 400px;
    padding-left: var(--grid-gap);
  }

  &__description-text {
    color: var(--text-color-gray);
  }

  &__example {
    display: flex;
    margin-top: 15px;
  }

  input[type="file"] {
    margin-bottom: 20px;
  }

  a {
    margin-bottom: 4px;
    color: var(--primary-color);
    font-size: 14px;
  }

  br {
    display: none;
  }
}
.auto-order-requests {
  &__headers, &__item-main {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    background-color: white;
  }

  &__headers {
  }

  &__item-main {
    position: relative;
    padding: 16px 0;
  }

  &__item {
    &:nth-child(n+2) {
      border-top: 1px solid var(--color-gray-border);
    }
    &._opened {
      .auto-order-requests {
        &__open-icon svg path:last-child {
          transform: rotate(90deg);
        }

        &__item-main {
          border-bottom: 1px solid var(--color-gray-border);
        }
      }
    }
  }
  &__list {}

  &__header, &__value {
    &:first-child {
      padding-left: var(--grid-spacer);
    }

    &:last-child {
      padding-right: var(--grid-spacer);
    }

    &_id {
      //text-align: center;
      //grid-column: 1/2;
    }
  }

  &__header {
    background-color: rgba(202, 201, 206, 0.3);
    padding: 16px 0;
  }

  &__value {
    &_id {
      color: var(--color-gray-text);
    }

    &._red{
      color: var(--reddish-color);
    }
    &._gray{
      color: var(--color-gray-text);
    }
  }

  &__additional {
    padding-top: 20px;
  }

  &__additional-wrap {
    transition: height .4s;
    height: 0;
    overflow: hidden;
    background-color: rgba(241,241,244,.7);;
  }

  &__open-icon {
    position: absolute;
    right: var(--grid-spacer);
    top: 0;
    height: 100%;
    display: flex;
    align-items: center;

    svg path:last-child {
      transition: transform .4s;
      transform-origin: center;
    }
  }

  &__positions {
    margin-top: 30px;
  }

  &__external-link-wrap, &__history-wrap {
    padding: var(--grid-gap) 0 var(--grid-gap) var(--grid-spacer);
  }

  &__history {
    display: flex;
    align-items: center;
  }
  &__history-icon {
    display: flex;
    align-items: center;
    margin-right: 7px;
  }

  &__history-text {
  }
}

@include respond-up('large') {
  .auto-order-requests {

    &__external-link {
      &:nth-child(n + 2) {
        margin-left: var(--grid-gap);
      }
    }
  }
}

@include respond-up('medium') {
  .auto-order-requests {

  }
}

@include respond('medium') {
  .auto-order-requests {

  }
}

@include respond-down('medium') {
  .auto-order-requests {
    &__external-link-wrap {
      flex-direction: column;
    }
    &__external-link {
      &:nth-child(n + 2) {
        margin-top: var(--grid-gap);
      }
    }
  }
}

@include respond-down('small') {
  .auto-order-requests {
    padding-left: var(--grid-spacer);
    padding-right: var(--grid-spacer);
    margin: 0 calc(var(--grid-spacer) * -1);
    overflow: scroll hidden;

    &__headers, &__list {
      min-width: 948px;
    }
  }
}
.catalog-filter-short {
  border-bottom: 1px solid var(--color-gray-border);

  &__content {
    display: grid;
    width: 100%;
  }

  &__axis-button-wrap {
    display: flex;
  }

  &__axis-button {
    height: var(--default-input-height);
    flex: 0 0 100%;
  }

  &__second-axis-field {
    margin-top: var(--grid-gap);
  }

  &__button.button {
    flex: 0 0 100%;
  }

  &__button-wrap {
    display: flex;
  }

  &__field {
  }
}

@include respond-up('large') {
  .catalog-filter-short {
    &__content {
      grid-template-columns: repeat(30, 1fr);
      grid-gap: 20px;
      padding-bottom: 40px;
    }

    &__axis-button-wrap {
      grid-row: 2/3;
      grid-column: 25/31;
      align-items: flex-end;
    }

    &__button-wrap {
      grid-row: 5/6;
      display: flex;

      &_submit {grid-column: 25/31;}
      &_reset {grid-column: 19/25;}
    }

    &__field {
      .checkbox-list {
        display: flex;

        &__item:nth-child(n+2) {
          margin-left: 15px;
        }

        &__label {
          white-space: nowrap;
        }
      }

      &_hide_price, &_avail, &_inch, &_pickup {
        display: flex;
        justify-content: flex-end;
      }

      &_inch {
        align-items: center;
      }

// <general
      &_search, &_inch {grid-row: 1/2}
      &_search {
        grid-column: 1/17;
        display: flex;
        align-items: flex-end;
        .catalog-filter-short__text-field {flex: 0 0 100%;}
      }
      &_inch {grid-column: 26/31}

      &_hide_price, &_avail, &_pickup {grid-row: 4/5}
      &_hide_price {grid-column: 16/23}
      &_pickup {grid-column: 23/27}
      &_avail {grid-column: 27/31}

      &_producer, &_vendor_code, &_internal_id, &_city, &_from_count {grid-row: 3/4;}
    }

    &._manager {
      .catalog-filter-short {
        &__field {
          &_producer {grid-column: 1/7}
          &_vendor_code {grid-column: 7/13}
          &_internal_id {grid-column: 13/19}
          &_city {grid-column: 19/25}
          &_from_count {grid-column: 25/31}
          &_pcd {grid-column: 1/7}
          &_pcd {grid-row: 4/5}
        }
      }
    }

    &._user {
      .catalog-filter-short {
        &__field {
          &_producer {grid-column: 1/9}
          &_internal_id {grid-column: 9/17}

          &_vendor_code {grid-column: 17/25}
          &_from_count {grid-column: 25/31}
          &_pcd {grid-column: 1/9}
          &_pcd {grid-row: 4/5}
        }
      }
    }
// general>

// <tyres
    &._car_tyre, &._truck_tyre, &._moto_tyre {
      .catalog-filter-short__field {
        &_width, &_usa_width, &_height, &_usa_external_diameter, &_diameter {grid-row: 2/3}
        &_width, &_usa_external_diameter {grid-column: 1/9}
        &_height, &_usa_width {grid-column: 9/17}
        &_diameter {grid-column: 17/25}

        &_season, &_runflat, &_not_runflat, &_studded {grid-row: 4/5}
        &_season{grid-column: 1/11}
        &_studded {grid-column: 11/17}
      }
    }

    &._car_tyre {
      .catalog-filter-short__field {
        &_runflat, &_not_runflat, &_cargo_checkbox {grid-row: 5/6; display: flex; align-items: center}
        &_cargo_checkbox{grid-column: 1/5;}
        &_runflat {grid-column: 5/8}
        &_not_runflat {grid-column: 8/13}
      }
    }

    &._truck_tyre, &._moto_tyre {
      .catalog-filter-short__field {
        &_axis {
          grid-row: 5/6;
          grid-column: 1/19;
          display: flex;
          align-items: center;
        }
      }
    }
//  tyres>

//  <wheels
    &._car_wheel, &._truck_wheel {
      .catalog-filter-short__field {
        &_width, &_diameter, &_et, &_dia {grid-row: 2/3}
        &_width {grid-column: 1/7}
        &_diameter {grid-column: 7/13}
        &_dia {grid-column: 13/19}
        &_et {grid-column: 19/25}

        &_hide_price, &_avail, &_type, &_pickup {
          display: flex;
          align-items: center;
        }
      }
    }

    &._car_wheel {
      .catalog-filter-short__field {
        &_type {
          grid-row: 5/6;
          grid-column: 1/11
        }
      }
    }
//  wheels>

//  <eav
    &._eav {
      .catalog-filter-short {
        &__eav-fields {
          grid-column: 1/31;
        }

        &__button-wrap {
          grid-row: 4/5;
          align-items: flex-end;
        }

        &__field {
          &_hide_price, &_avail, &_pickup {
            align-items: center;
            //justify-content: flex-start;
          }
        }
      }

      &._manager {
        .catalog-filter-short {
          &__button-wrap {
            grid-row: 5/6;
          }

          &__field {
            &_price {grid-column: 1/7}
          }
        }

        .catalog-filter-short__field {
          //&_hide_price {grid-column: 7/14}
          //&_avail {grid-column: 15/19}
        }
      }

      &._user {
        .catalog-filter-short {
          &__field {
            &_price {grid-column: 23/31}
            &_hide_price {grid-column: 1/7}
            &_pickup {grid-column: 7/11}
            &_avail {grid-column: 11/15}
            &_from_count {grid-column: 16/23}
            &_vendor_code {grid-column: 9/16}
            &_hide_price, &_avail, &_pickup {justify-content: flex-start}
          }
        }
      }
    }
//  eav>

    &__eav-fields {
      display: grid;
      grid-gap: 20px;

      &_3 {
        grid-template-columns: repeat(4, 1fr);
      }

      &_5 {
        grid-template-columns: repeat(5, 1fr);
      }
    }
  }
}

@include respond-down('medium') {
  .catalog-filter-short {
    &__content {
      grid-template-columns: repeat(4, 1fr);
      grid-gap: 0 var(--grid-gap);
      padding: 0 var(--grid-spacer);
    }

    &__button-wrap, &__axis-button-wrap {grid-column: 1/5; margin-bottom: var(--grid-gap)}
    &__button-wrap {
      &_submit {
        grid-row-start: 77;
      }
      &_reset {
        grid-row-start: 78;
      }
    }

    &__axis-button-wrap {grid-row-start: 20}

    &__field {
      grid-column: 1/5;
      margin-bottom: var(--grid-gap);

      .checkbox-list__item:not(:last-child) {margin-bottom: 9px}

// Начальная область 1 - 20
      &_search {grid-row-start: 1}
      &_inch {grid-row-start: 2}
      &_width {grid-row-start: 3}
      &_height {grid-row-start: 4}
      &_diameter {grid-row-start: 5}
      &_dia {grid-row-start: 6}
      &_et {grid-row-start: 7}

// область производителя после оси 20 - 30
      &_pcd {grid-row-start: 23}
      &_producer {grid-row-start: 25}

      &_vendor_code, &_internal_id {grid-row-start: 30}


// зона чекбоксов 31 - 59
      &_season, &_cargo_checkbox, &_studded, &_runflat, &_not_runflat, &_avail, &_type, &_axis {margin-bottom: 0}

      &_season, &_cargo_checkbox {grid-column: 1/3}
      &_studded, &_runflat, &_not_runflat {grid-column: 3/5}

      &_season {grid-row: 32/35}
      &_cargo_checkbox {grid-row-start: 35}

      &_studded {grid-row: 32/34}

      &_runflat {grid-row-start: 34}
      &_not_runflat {grid-row-start: 35}

      &_axis, &_type {grid-row-start: 37}

// пост чекбоксная зона 60 - 65
      &_city {grid-row-start: 60}
      &_from_count {grid-row-start: 65}


// конечная часть 70 - ...
      &_avail {
        grid-row-start: 70;
        padding: 30px var(--grid-spacer) 0;
        border-top: 1px solid var(--color-gray-border);
        margin: calc(40px - var(--grid-gap)) calc(var(--grid-spacer) * -1) 0;
      }

      &_hide_price {grid-row-start: 75}
    }

    &._user {
      .catalog-filter-short {
        &__field {
          &_from_count {margin-top: var(--grid-gap)}
        }
      }
    }

    &._manager {
      .catalog-filter-short {
        &__field {
          &_vendor_code {grid-column: 1/3}
          &_internal_id {grid-column: 3/5}
          &_city {margin-top: var(--grid-gap)}
        }
      }
    }

    &__eav-fields {
      grid-column: 1/5;
    }
  }
}
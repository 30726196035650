body {
  font-size: var(--body-font-size);
  font-family: var(--font);
  line-height: var(--body-line-height);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input, button, form {
  font-family: var(--font);
}

@include respond-up('large') {
  body {
    min-width: 1280px;
  }
}
.auto-order-info {
  &__item {
    display: flex;
    border-bottom: 1px solid var(--color-gray-border);
  }

  &__name {
    font-weight: 400;
    line-height: 130%;
    color: var(--color-gray-text);
  }

  &__value {
    flex: 1 1 auto;
    font-weight: 400;
    line-height: 130%;
    color: var(--color-dark);
  }
}

@include respond-up('large') {
  .auto-order-info {
    &__item {
      padding: 12px 0;

      &:last-child {
        border-bottom: unset;
      }
    }

    &__name {
      flex: 0 0 317px;
      font-size: 15px;
    }

    &__value {
      font-size: 15px;
    }
  }
}

@include respond-up('medium') {
  .auto-order-info {

  }
}

@include respond('medium') {
  .auto-order-info {

  }
}

@include respond-down('medium') {
  .auto-order-info {
    &__item {
      padding: 10px var(--grid-spacer);
      flex-direction: column;
      row-gap: 6px;

      &:first-child {
        padding-top: 0;
      }
    }

    &__item-name {
      font-size: 14px;
    }

    &__item-value {
      font-size: 14px;
    }
  }
}

@include respond-down('small') {
  .auto-order-info {

  }
}
.about-purchaser-block {
  background: white;
  border-radius: 2px;

  &__inner-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }

  &__phone,
  &__email {
    color: var(--color-gray-text);
  }

  &__name {
    color: var(--color-dark);
  }

  &__contacts {
    display: flex;
  }
}

@include respond-up('large') {
  .about-purchaser-block {
    padding-bottom: 50px;

    &__container {
      padding: 0 var(--grid-spacer);
    }

    &__delete {
      margin-bottom: 3px;
    }

    &__delete-icon {
      display: none;
    }

    &__contacts {
      margin-top: 10px;
      align-items: center;
    }

    &__email {
      padding-left: 10px;
      margin-left: 10px;
      position: relative;

      &:before {
        position: absolute;
        content: "";
        width: 1px;
        height: 19px;
        background: var(--primary-color);
        top: 50%;
        transform: translateY(-50%);
        left: 0;
      }
    }

    &__name {
      margin-top: 30px;
    }

    &__back-link {
      padding: 30px;
      margin: -30px;
      color: var(--primary-color);

      svg {
        margin-right: 9px;
      }
    }

    &__top-wrapper {
      padding: 30px 0 0;
    }

    &__orders-header,
    &__order {
      display: flex;
      width: 100%;
      flex: 0 0 100%;
    }

    &__order-number {
      width: calc(var(--grid-column2) - 40px);
      padding: 17px var(--grid-gap) 15px 20px;
      margin-right: var(--grid-gap);
    }

    &__order-date {
      width: var(--grid-column2);
      padding: 17px var(--grid-gap) 15px 0;
      margin-right: var(--grid-gap);
    }

    &__order-sum {
      width: var(--grid-column2);
      padding: 17px var(--grid-gap) 15px 0;
      margin-right: var(--grid-gap);
    }

    &__order-status {
      width: var(--grid-column3);
      margin-right: 40px;
      padding: 17px 20px 15px 0;
    }

    &__orders {
      padding: 40px 0 0;
    }

    &__orders-header {
      background: var(--color-gray-bg);
    }

    &__mobile-title {
      display: none;
    }

    &__order {
      .about-purchaser-block {
        &__order-number,
        &__order-date,
        &__order-status {
          color: var(--color-gray-text);
        }

        &__order-sum {
          color: var(--color-dark);
        }
      }

      &:nth-child(odd) {
        .about-purchaser-block {
          &__order-number,
          &__order-date,
          &__order-sum,
          &__order-status {
            background: var(--color-gray-bg);
          }
        }
      }
    }
  }
}

@include respond-down('medium') {
  .about-purchaser-block {
    &__orders-header,
    &__back-link{
      display: none;
    }

    &__top-wrapper {
      padding: 20px var(--grid-spacer) 40px;
    }

    &__name-wrap {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
    }

    &__info-wrapper {
      width: 100%;
    }

    &__name {
      flex: 0 0 196px;
    }

    &__delete {
      &.button {
        display: none;
      }
    }

    &__delete-icon {
      width: 20px;
      height: 20px;
      display: flex;

      svg {
        width: 100%;
        height: 100%;

        path {
          stroke: var(--primary-color);
        }
      }
    }

    &__contacts {
      flex-direction: column;
      row-gap: 4px;
      position: relative;
      padding-left: 14px;
      margin-top: 20px;

      &:before {
        position: absolute;
        content: '';
        background: var(--primary-color);
        width: 1px;
        height: 100%;
        left: 0;
        top: 0;
      }
    }

    &__order {
      padding: 14px var(--grid-spacer) var(--grid-spacer);
      display: grid;
      grid-template-areas:
        'number number'
        'amount status';
      grid-row-gap: 10px;
      grid-column-gap: 60px;

      &:nth-child(even) {
        background: var(--color-gray-bg-dark);
      }
    }

    &__order-number {
      grid-area: number;
      &.text__small {
        color: var(--color-dark);
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 125%;
      }
    }

    &__order-sum {
      grid-area: amount;
      display: flex;
      flex-direction: column;
    }

    &__order-status {
      grid-area: status;
      display: flex;
      width: fit-content;
      height: fit-content;
      align-self: flex-end;
      justify-self: flex-end;
      padding: 3px 6px;
      border-radius: 2px;
      border: 1px solid var(--color-gray-border);
      color: var(--color-gray-text);
    }

    &__order-date {
      display: none;
    }
  }
}
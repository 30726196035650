.tech-conflict-list {
  &__list {

  }

  &__item {
    border-bottom: 1px solid var(--border-color);
  }

  &__header, &__link {
    display: grid;
    grid-template-columns: 90px 200px 1fr 200px ;
    padding: 15px 20px;
  }

  &__link {
    background-color: white;
    color: var(--text-color-gray);
    transition: {
      property: background-color;
      duration: .3s;
    };

    &:hover {
      background-color: #F3F3F3;
    }
  }

  &__id, &__header-text_id, &__date, &__header-text_date{
    display: flex;
    justify-content: center;
  }
}

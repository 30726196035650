.content-header {
  &__h1 {
    font-weight: 700;
    line-height: 135%;
    color: var(--color-dark);
    text-transform: uppercase;
  }


}

@include respond-up('large') {
  .content-header {
    padding: 60px 0 40px;

    &_big-padding {
      padding: 150px 0 40px;
    }
  }


}

@include respond-down('medium') {
  .content-header {
    padding: 40px 0 20px;

    &_big-padding {
      padding: 90px 0 20px;
    }

    &__profile-page {
      display: none;
    }
  }
}
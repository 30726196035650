.order-content {
  &__chat-wrapper {
    padding-top: 28px;
    height: 713px;
  }

  &__chat {
    height: 100%;
  }
}

.why-slider {
  background: radial-gradient(65.31% 260.09% at 70.31% 61.06%, #FF7C1D 2.56%, #FF7E06 30.85%, #FFB987 100%);

  &__navigation {
    display: flex;
  }

  &__mark {
    color: var(--primary-color);
    padding: 3px 7px;
    border-radius: 2px;
    background: var(--color-bg-light);
    display: inline-flex;
  }

  &__subtitle {
    color: var(--color-gray-text);
  }

  &__picture-slider {
    z-index: 100;
    position: relative;
  }

  &__wrapper {
    position: relative;
  }

  &__h2 {
    color: white;
  }
}

@include respond-up('large') {
  .why-slider {
    padding-top: 80px;

    &__navigation {
      top: -75px;
    }

    &__pagination {
      bottom: -40px;
    }

    &__pagination,
    &__navigation {
      left: calc(var(--grid-column1) + var(--grid-gap));
      height: 8px;
      position: absolute;
    }

    &__title-wrapper {
      width: calc(var(--grid-column6) - 120px);
    }

    &__title {
      margin-top: 20px;
      width: calc(var(--grid-column4) - 60px);
    }

    &__subtitle {
      margin-top: 20px;
    }

    &__picture {
      height: calc(650 / 805 * var(--grid-column7));
      display: flex;
      width: 100%;
      box-shadow: inset 0px -4px 39px rgba(0, 0, 0, 0.15);
      border-radius: 16px 16px 0px 0px;
      overflow: hidden;
    }

    &__image {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &__text-slider {
      position: absolute;
      width: var(--grid-column6);
      top: 50%;
      right: var(--grid-spacer);
      transform: translate3d(0, -50%, 0);
      z-index: 150;

      .why-slider {
        &__item {
          background: white;
          padding: 60px;
          height: 360px;
        }
      }
    }

    &__picture-slider {
      grid-column: 1/8;
    }

    &__wrapper {
      margin-top: 60px;
    }

    &__h2 {
      grid-column: 4/10;
    }
  }
}

@include respond-down('medium') {
  .why-slider {
    padding-top: 40px;
    padding-bottom: 60px;
    &__title-wrapper {
      width: 100%;
    }

    &__navigation {
      justify-content: flex-end;
    }

    &__pagination {
      display: flex;
      align-items: center;
    }

    &__title {
      margin-top: 20px;
      width: calc(var(--grid-column4) - 40px);
    }

    &__subtitle {
      margin-top: 10px;
    }

    &__picture-slider {
      display: none;
    }

    &__text-slider {
      margin-top: 40px;
      display: grid;
      grid-template-columns: repeat(2, var(--grid-column2));
      grid-row-gap: 20px;
      grid-column-gap: var(--grid-gap);

      .why-slider {
        &__item {
          background: white;
          padding: 30px 20px 60px;
          height: 100%;
          display: flex;
        }
      }
    }

    &__text-swiper {
      grid-column: 1/3;
      display: flex;
      width: 100%;
    }

    &__h2 {
      grid-column: 1/5;
    }
  }
}
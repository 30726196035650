.header {
  --top-header-height: 76px;
  --bottom-header-height: 58px;
  --message-height: 30px;
  height: calc(var(--top-header-height) + var(--bottom-header-height));
  position: relative;

  &__container {
    position: absolute;
    z-index: 1000;
    left: 0;
    width: 100%;
    top: 0;
  }

  &__top {
    background-color: var(--color-dark);
  }

  &__logo {
    //max-height: 36px;
    //width: auto;
    display: flex;

    svg {
      height: 100%;
      width: auto;
    }
  }

  &__top-inner {
    height: var(--top-header-height);
  }

  &__button-wrapper,
  &__panel,
  &__button,
  &__top-inner,
  &__contacts,
  &__contact {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__contact {
    height: var(--top-header-height);
    margin-left: calc(var(--grid-column1) - 20px);

    svg {
      margin-right: 8px;
    }
  }

  &__bottom {
    background-color: white;
    filter: drop-shadow(0px 1px 20px rgba(0, 0, 0, 0.1));
  }

  &__bottom-inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: var(--bottom-header-height);
  }

  &__menu-list {
    display: flex;
  }

  &__menu-item {
    &:not(:last-child) {
      margin-right: 30px;
    }
  }

  &__menu-link {
    text-transform: uppercase;
    height: var(--bottom-header-height);
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 800;
    transition: color var(--default-transition-time) var(--default-timing-function);

    &:hover {
      color: var(--color-primary-dark);
    }
  }

  &__button-wrapper {
    margin-left: 35px;
  }

  &__button {
    position: relative;
    margin-right: 10px;
    margin-left: 10px;
    height: var(--bottom-header-height);
    transition: color var(--default-transition-time) var(--default-timing-function);

    svg {
      path {
        transition: stroke var(--default-transition-time) var(--default-timing-function);
      }
    }

    &:hover {
      color: var(--primary-color);

      svg {
        path {
          stroke: var(--primary-color);
        }
      }
    }
  }

  &__panel {
    padding-right: 10px;
  }

  &__button-text {
    margin-left: 8px;
  }

  &__button-counter {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: calc(50% - 15px);
    right: -3px;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    color: white;
    font-size: 10px;
    font-weight: 600;
    letter-spacing: -0.04em;
    line-height: 145%;
    padding-top: 2px;
  }

  &__cart-counter {
    background-color: var(--greenish-color);
  }

  &__alert-counter {
    background-color: var(--reddish-color);
  }

  &_has-message {
    height: calc(var(--top-header-height) + var(--bottom-header-height) + var(--message-height));
  }

  &__message-block {
    height: var(--message-height);
  }

  &__message-wrap {
    position: absolute;
    left: 0;
    width: 100%;
    background-color: var(--primary-color);
  }

  &__message {
    height: var(--message-height);
    color: white;
    font-weight: 900;
    display: flex;
    align-items: center;
  }
}

//TODO: move to _text.scss
.text {
  &_white {
    color: white;
  }
}
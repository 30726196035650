.markup-info-form {
  background-color: white;
  padding: 30px var(--grid-spacer);
  margin-top: 20px;
  position: relative;

  &__fields {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: var(--grid-gap);
  }

  &__button-wrap {
    padding-top: 15px;
  }

  &__button {

  }
}

.cart-order-comment {
  background-color: white;

  &__labels {
  }

  &__title {
    color: black;
  }

  &__subtitle.text {
    color: var(--color-gray-text);
  }

  &__comment {
    border: 1px solid var(--color-gray-border);
    padding: 12px 14px;
    resize: none;

    &::placeholder {
      font-weight: 400;
      font-size: 15px;
      line-height: 130%;
      color: var(--color-gray-text);
    }
  }
}

@include respond-up('large') {
  .cart-order-comment {
    margin-top: 64px;
    display: grid;
    grid-template-columns: repeat(9, 1fr);
    grid-gap: var(--grid-gap);
    padding: 40px 0 50px;

    &__labels {
      grid-column: 1/4;
      padding-left: var(--grid-spacer);
    }

    &__comment {
      grid-column: 4/9;
      height: 145px;
    }

    &__title {
      margin-bottom: 10px;
    }
  }
}

@include respond-down('medium') {
  .cart-order-comment {
    margin-top: var(--grid-gap);
    padding: var(--grid-spacer);
    padding-bottom: 30px;

    &__title {
      margin-bottom: 6px;
    }

    &__labels {
      margin-bottom: 12px;
    }
  }
}

.cart-client-selector {
  background-color: white;
  display: flex;
  border-bottom: 1px solid var(--color-gray-border);

  &__select-wrap {
    display: flex;
  }

  &__select-label {
    font-weight: 400;
    line-height: 150%;
    color: black;
    flex: 0 0 auto;
  }

}

@include respond-up('large') {
  .cart-client-selector {
    padding: 27px var(--grid-spacer) 21px;
    justify-content: space-between;

    &__select-wrap {
      font-size: 16px;
      align-items: center;
    }

    &__select-label {
      font-size: 16px;
      margin-right: 8px;
    }

    .vue-field_select {
      flex: 0 0 261px;
      width: 261px;
    }
  }
}

@include respond-down('medium') {
  .cart-client-selector {
    flex-direction: column;
    padding: var(--grid-spacer) var(--grid-spacer) 30px var(--grid-spacer);

    &__select-wrap {
      margin-top: var(--grid-gap);
      font-size: 14px;
      display: flex;
      flex-direction: column;
    }

    &__select-label {
      font-size: 12px;
      font-weight: 400;
      line-height: 133%;
      color: var(--color-gray-text);
      margin-bottom: 3px;
    }
  }
}
.markup-client-filter {
  padding: 18px 40px 18px 30px;
  background-color: white;

  &__form {

  }

  &__fields {
    display: flex;
  }

  &__field {
    &_id {
      flex: 0 0 200px;
    }
    &_search {
      flex: 1 0 auto;
      margin-left: var(--grid-gap);
    }
  }
}

@include respond-up('large') {
  .markup-client-filter {

  }
}

@include respond-down('medium') {
  .markup-client-filter {

  }
}

.chat-widget {
  position: relative;
  margin-right: 10px;
  margin-left: 10px;
  height: var(--bottom-header-height);
  display: flex;
  align-items: center;
  justify-content: center;

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      path {
        stroke: var(--color-gray-text);
        transition: stroke var(--default-transition-time) var(--default-timing-function);
      }
    }
  }

  &__counter {
    background-color: var(--reddish-color);
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: calc(50% - 15px);
    right: -3px;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    color: white;
    font-size: 10px;
    font-weight: 600;
    letter-spacing: -0.04em;
    line-height: 145%;
    padding-top: 2px;
    pointer-events: none;
    opacity: 0;
    transition: opacity .4s;

    &._has-count {
      opacity: 1;
    }
  }

  &__list-wrap {
    right: 0;
    filter: drop-shadow(0 5px 10px rgba(0,0,0,.1));
    opacity: 0;
    pointer-events: none;
  }

  &__list {
    background-color: white;
  }

  &__item {
    position: relative;

    &:nth-child(n + 2) {
      border-top: 1px solid var(--border-color);
    }
  }

  &__link {
    padding: 10px 15px;
    display: flex;
    flex-direction: column;

    &:hover {
      .chat-widget {
        &__title {
          color: var(--primary-color);
        }
      }
    }
  }

  &__title {
    font-size: 16px;
    color: var(--color-dark);
    font-weight: bold;
    transition: {
      property: color;
      duration: var(--default-transition-time);
      timing-function: var(--default-timing-function);
    };
  }

  &__subtitle {
    background-color: var(--color-gray-bg);
    font-size: 12px;
    padding: 3px 8px;
    border-radius: 2px;
    font-weight: 400;
    line-height: 133%;
    margin-top: 5px;
  }

  &__empty {
    filter: drop-shadow(0 5px 10px rgba(0,0,0,.1));
    color: var(--color-gray-text);
    font-size: 15px;
    font-weight: bold;
    background-color: white;
    padding: 10px;
    text-align: center;
  }

  &:hover {
    .chat-widget {
      &__icon svg path {
        stroke: var(--primary-color);
      }

      &__list-wrap, &__empty {
        opacity: 1;
        pointer-events: auto;
      }
    }
  }
}

@include respond-up('large') {
  .chat-widget {
    &__list-wrap {
      position: absolute;
      top: calc(100% - 15px);
      right: 0;
      width: 280px;
      padding-top: 15px;
    }

    &__empty {
    }

    &__link {
      align-items: flex-start;
    }
  }
}

@include respond-down('medium') {
  .chat-widget {
    &__list-wrap {
      position: fixed;
      top: var(--mobile-header-height);
      right: 0;
      width: 100%
    }


    &__empty {
    }

    &__link {
      align-items: flex-end;
    }
  }
}
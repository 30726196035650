.identify-layout {
  background-color: var(--bg-color);

  &__header {

  }

  &__main {

  }

  &__content {
    padding: 20px 0;
  }

  &__back {
    font-weight: bold;
    padding: 10px;
    margin-top: 20px;
    font-size: 19px;
    display: inline-block;
  }
}

.one-c-order-page {
  &__info {
    padding: 20px 0;
  }

  &__block {
    background-color: white;
    &:nth-child(n + 2) {
      margin-top: var(--grid-gap);
    }
  }

  &__title {
    padding: 20px 0 15px;
  }

  &__actions {
    padding: 20px 0;
    display: flex;
    justify-content: space-between;
  }

  &__back-link {
    display: flex;
    align-items: center;
    padding: 10px 0;
    color: var(--primary-color);

    svg {
      margin-right: 6px;
    }
  }
}

@include respond-up('large') {
  .one-c-order-page {

  }
}

@include respond-down('medium') {
  .one-c-order-page {

  }
}
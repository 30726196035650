.identify-filter-form {
  background-color: white;
  border-radius: 5px;
  padding: 20px;

  &__fields {
    display: grid;
    grid-gap: 20px;
  }

  &_faileds {
    .identify-filter-form__fields {
      grid-template-areas:
            "category supplier external_id resolved postpone"
            "avail_to_resolve avail_to_resolve avail_to_resolve avail_to_resolve avail_to_resolve"
            "codes codes codes codes codes";
    }
  }

  &_conflict {
    .identify-filter-form__fields {
      grid-template-areas:
              "resolved postpone"
              "technologies technologies";
    }
  }

  &__field {
    &_product_type {grid-area: category;}
    &_supplier {grid-area: supplier;}
    &_external_id {grid-area: external_id;}
    &_resolved {grid-area: resolved;}
    &_postpone {grid-area: postpone;}
    &_avail_to_resolve {grid-area: avail_to_resolve;}
    &_technologies {grid-area: technologies;}
    &_code {grid-area: codes;}

    &_code, &_technologies {
      .checkbox-list {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -10px;

        li {
          display: flex;
          padding: 0 10px;
        }
      }
    }
  }
}

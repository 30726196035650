.notifications-settings-link {
  display: flex;
  align-items: center;
  height: 100%;

  &__icon {
    display: flex;
  }

  &__title {
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 133%;
    color: var(--color-dark);
    margin-left: 5px;
    transition: color var(--default-transition-time) var(--default-timing-function);
  }

  &:hover {
    .notifications-settings-link__title {
      color: var(--primary-color);
    }
  }
}

@include respond-up('large') {
  .notifications-settings-link {
    &__title {
    }


  }
}

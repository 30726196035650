.manager-filter-save-form {
  &__fields {
    width: 100%;
  }
}

@include respond-up('large') {
  .manager-filter-save-form {
    &__fields {
      padding: 28px var(--grid-spacer);
      background-color: white;
      display: flex;
      flex-wrap: wrap;

      .form-field {
        margin-right: 28px;
        flex: 1 1 auto;
      }
    }
  }
}

@include respond-down('medium') {
  .manager-filter-save-form {
    background: #fff;

    &__form {
      padding: var(--grid-spacer) var(--grid-spacer) 30px;
    }

    &__fields {
      display: grid;
      grid-template-columns: repeat(2, calc(50% - 3px));
      grid-row-gap: 16px;
      grid-column-gap: 6px;
    }
  }
}

.allow-editable-input {
  .errors {
    position: absolute;
  }

  &._disabled {
    input[type='text'] {
      pointer-events: none;
      color: var(--color-gray-text);
    }

    .allow-editable-input__link {
      opacity: 1;
      pointer-events: auto;
    }
  }

  &__input {
    position: relative;
  }

  &__link {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 0 10px;
    position: absolute;
    top: 0;
    right: 0;
    opacity: 0;
    pointer-events: none;
  }

  &__icon {
    display: flex;
  }
}

.vue-preloader {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.2);
  z-index: 1000;

  &__preloader {
    flex: 0 0 40px;
    width: 40px;
    height: 40px;
    background: {
      image: url('../images/svg/preloader.svg');
      size: contain;
      repeat: no-repeat;
    };
    animation: {
      name: rotate;
      duration: .6s;
      iteration-count: infinite;
      timing-function: linear;
    };
  }
}
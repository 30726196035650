h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  font-family: var(--h-font);
  font-size: var(--h-size);
  line-height: var(--l-height);
  font-weight: bold;
  color: var(--color-dark);
}

h1, .h1 {
  --l-height: 135%;
}

h2, .h2 {
}

h3, .h3 {
  --l-height: 120%;
}

h4, .h4 {
  --l-height: 120%;
}

h5, .h5 {
  --l-height: 120%;
}

h6, .h6 {
  --l-height: 120%;
}

@include respond-up('large') {
  h1, .h1 {
    --h-size: 46px;
  }

  h2, .h2 {
    --h-size: 36px;
    --l-height: 133%;
  }

  h3, .h3 {
    --h-size: 24px;
  }

  h4, .h4 {
    --h-size: 24px;
  }

  h5, .h5 {
    --h-size: 22px;
  }

  h6, .h6 {
    --h-size: 20px;
  }
}

@include respond-down('medium') {
  h1, .h1 {
    --h-size: 30px;
  }

  h2, .h2 {
    --h-size: 30px;
    --l-height: 125%;
  }

  h3, .h3 {
    --h-size: 23px;
  }

  h4, .h4 {
    --h-size: 23px;
  }
  h5, .h5 {
    --h-size: 21px;
  }

  h6, .h6 {
    --h-size: 19px;
  }
}
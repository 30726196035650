.endless-preloader {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 40px 0;

  &__preloader {
    flex: 0 0 40px;
    width: 40px;
    height: 40px;
    background: {
      image: url('../images/svg/preloader.svg');
      size: contain;
      repeat: no-repeat;
    };
    animation: {
      name: rotate;
      duration: .6s;
      iteration-count: infinite;
      timing-function: linear;
    };
  }
}

@include respond-up('large') {
  .endless-preloader {

  }
}

@include respond-up('medium') {
  .endless-preloader {

  }
}

@include respond('medium') {
  .endless-preloader {

  }
}

@include respond-down('medium') {
  .endless-preloader {

  }
}

@include respond-down('small') {
  .endless-preloader {

  }
}

@keyframes rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

.tabs {

  // черные и рыжие
  &_black, &_primary {
    .tabs {
      &__item {
        &:not(:last-child) {
          margin-right: 12px;
        }
      }

      &__link {
        font-size: 15px;
        padding: 8px 10px;
        border-radius: 2px;
        display: flex;
        align-items: center;
        background: var(--color-gray-bg);
        transition: background-color var(--default-transition-time) var(--default-timing-function), color var(--default-transition-time) var(--default-timing-function);
      }
    }
  }

  // рыжие
  &_primary {
    .tabs {
      &__item {
        &._active {
          .tabs {
            &__link {
              background-color: var(--color-primary-white);
              color: var(--color-dark);
            }
          }
        }
      }

      &__link {
        color: var(--color-gray-text);
      }
    }
  }

  //черные
  &_black {
    .tabs {
      &__item {
        &._active {
          .tabs {
            &__link {
              background-color: var(--color-dark);
              color: white;
            }
          }
        }
      }

      &__number {
        font-size: 14px;
        color: var(--color-gray-text);
        margin-left: 6px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  //белые с рыжей полоской
  &_main {
    .tabs {
      &__item {
        &:not(:last-child) {
          margin-right: 4px;
        }

        &._active {
          .tabs {
            &__link {
              background-color: white;
              color: var(--color-dark);
              border-color: var(--primary-color);

              svg {
                path {
                  stroke: var(--primary-color);
                }
              }
            }
          }
        }
      }

      &__link {
        padding: 14px 22px;
        border-radius: 2px;
        display: flex;
        align-items: center;
        background: var(--color-white-text-60);
        transition: background-color var(--default-transition-time) var(--default-timing-function), color var(--default-transition-time) var(--default-timing-function), border-color var(--default-transition-time) var(--default-timing-function);
        border-top: 4px solid var(--t);
        color: var(--color-gray-text);
        font-size: 16px;
        font-weight: 600;
        line-height: 19px;

        svg {
          margin-right: 6px;

          path {
            transition: stroke var(--default-transition-time) var(--default-timing-function);
          }
        }
      }
    }
  }

  // Малые (сохраненные фильтры)
  &_small {
    display: flex;
    .tabs {
      &__tab {
        &:nth-child(n + 2) {
          margin-left: 8px;
        }

        &._active {
          .tabs__tab-link {
            background-color: var(--color-primary-light);
            color: var(--color-dark);
          }
        }
      }

      &__tab-link {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 3px 8px;
        background-color: var(--color-gray-bg);
        color: var(--color-gray-text);
        border-radius: 2px;
        font-weight: 400;
        font-size: 12px;
        line-height: 130.5%;
        transition: {
          property: color, background-color;
          duration: var(--default-transition-time);
          timing-function: var(--default-timing-function);
        };
      }
    }
  }
}

@include respond-down('medium') {
  .tabs {
    &__link {
      white-space: nowrap;
    }
  }
}
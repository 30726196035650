.order-block {
  background-color: white;

  &__header {
    display: flex;
  }

  &__order-number {
    font-weight: 700;
    line-height: 120%;
    color: var(--color-dark);
    flex: 0 0 auto;
  }

  &__state {
    font-weight: 600;
    border-radius: 2px;
    color: var(--color-dark);

    &._yellow {
      background-color: var(--color-yellow-bg);
    }

    &._green {
      background: rgba(112, 165, 0, 0.3);
    }

    &._red {
      background: rgba(255, 65, 5, 0.3);
    }
  }

  &__state-form {
    flex: 0 0 auto;
  }
}

@include respond-up('large') {
  .order-block {
    padding: 30px 0 64px;
    grid-column: 1/10;

    &__back-link {
      padding: 30px;
      margin: -30px;
      color: var(--primary-color);

      svg {
        margin-right: 9px;
      }
    }

    &__header {
      padding: 30px 0 20px;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
    }

    &__order-number {
      font-size: 24px;
    }

    &__state {
      padding: 3px 8px;
    }

    &__info {
      padding-top: 60px;
    }

    &__state-form {
      flex: 0 0 100%;
    }
  }
}

@include respond-down('medium') {
  .order-block {
    padding-bottom: 40px;

    &__container {
      max-width: unset;
      padding: 0;
      margin: 0;
    }

    &__back-link {
      display: none;
    }

    &__header {
      padding: 20px 0 20px;
      flex-direction: column;
      row-gap: 6px;
    }

    &__order-number {
      font-size: 23px;
    }

    &__state {
      padding: 3px 6px;
      width: fit-content;
      font-size: 11px;
    }

    &__info {
      padding-top: 40px;
    }
  }
}
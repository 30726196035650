.hero-slider {
  position: relative;

  &__text-slider {
    z-index: 150;
    position: relative;
    width: 100%;
  }

  &__picture-slider {
    z-index: 50;

    .swiper {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
    }

    .hero-slider {
      &__item {
        background: var(--color-dark-backgorund);
      }
    }
  }

  &__pagination {
    height: 8px;
    display: flex;
    align-items: center;
  }

  &__picture {
    display: flex;
    width: 100%;
    height: 100%;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__title-wrapper {
    grid-column: 1/9;
    position: relative;
  }

  &__subtitle {
    font-weight: 600;
    color: white;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 4px;
      height: 100%;
      background: var(--primary-color);
    }
  }

  &__title {
    font-weight: 700;
    color: white;
  }
}

@include respond-up('large') {
  .hero-slider {
    padding-top: 19%;
    height: 100vh;

    &__title {
      font-size: 60px;
      line-height: 78px;
      width: var(--grid-column8);
    }

    &__subtitle {
      font-size: 20px;
      line-height: 32px;
      width: calc(var(--grid-column6) + var(--grid-gap));
      margin-top: 30px;
      padding-left: 24px;
    }

    &__picture-slider {
      .swiper {
        height: 100vh;
      }
    }

    &__buttons-wrap {
      display: none;
    }

    &__pagination {
      &_image {
        position: absolute;
        left: clamp(var(--grid-spacer), calc(50vw + var(--grid-spacer) - var(--max-row-width)/2), 100vw);
        bottom: 11%;
      }

      &_text {
        display: none;
      }
    }
  }
}

@include respond-down('medium') {
  .hero-slider {
    padding-top: 50px;
    display: flex;
    flex-direction: column;
    height: 100svh;

    &__text-slider {
      height: 100%;
      display: flex;
      align-items: center;
      pointer-events: none;
    }

    &__title {
      font-size: 32px;
      line-height: 125%;
      letter-spacing: -0.32px;
      width: var(--grid-column4);
    }

    &__subtitle {
      font-size: 17px;
      line-height: 150%;
      width: var(--grid-column4);
      margin-top: 20px;
      padding-left: 18px;
    }

    &__buttons-wrap {
      position: relative;
      display: flex;
      flex-direction: column;
      row-gap: 24px;
      padding: 40px var(--grid-spacer) 60px;
      z-index: 150;
      border-top: 1px solid rgba(255, 255, 255, 0.20);
    }

    &__picture-slider {
      .swiper {
        height: 100svh;
      }
    }

    &__registration {
      &.button {
        text-decoration: underline;
        margin-top: -16px;
        margin-bottom: -18px;

        &__link {
          color: var(--primary-color);
        }
      }
    }

    &__pagination {
      &_text {
        padding-left: clamp(var(--grid-spacer), calc(50vw + var(--grid-spacer) - var(--max-row-width)/2), 100vw);
        margin-top: 40px;
      }

      &_image {
        display: none;
      }
    }
  }
}
.tree-nodes {
  &__append {
    position: relative;
    width: 24px;
    height: 24px;
    color: #fff;
    border-radius: 5px;
    background-color: $light-gray-color;
    border: 1px solid #D0D0D0;
    top: -22px;
    left: -11px;
    z-index: 30;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      background-color: #fff;
    }
  }
}
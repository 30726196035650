.default-list-header {
  &__item {
    font-weight: 400;
    line-height: 145%;
    font-family: var(--font);
    display: flex;
  }
}

@include respond-up('large') {
  .default-list-header {
    padding: 16px 0;
    display: grid;
    grid-column-gap: var(--grid-gap);

    &__item {
      font-size: 13px;
    }
  }
}

@include respond-down('medium') {
  .default-list-header {
    display: flex;
    background: white;
    padding: 12px calc(var(--grid-spacer) + 30px + 20px) 12px var(--grid-spacer);
    justify-content: space-between;
  }
}
:root {
  --modal-bg: rgba(44, 43, 46, .7);
  --modal-content-radius: 2px;
  --modal-content-shadow: 0px 0px 25px rgba(0, 0, 0, 0.1);
  --modal-content-padding: 40px 40px 50px;
  --modal-content-background: #fff;
  --modal-closer-color: var(--color-gray-text);
  --modal-closer-size: 30px;
  --modal-preloader-size: 30px;
}

.modal__bg {
  position: fixed;
  height: 100%;
  width: 100%;
  background: white;
  background: var(--modal-bg);
  z-index: 10000;
  display: none;
  top: 0;
  left: 0;
  overflow-y: scroll;

  &.opened {
    display: block;
  }

 // ну че, это крестик внутри модалки, data-modal-class="_inner-closer" на ссылку брось сперва, братан
  &._inner-closer {
    .modal__closer {
        border: 1px solid var(--color-gray-border);
        top: var(--grid-spacer);
        right: var(--grid-spacer);
    }
    .modal__closer-inner {
      &:before,
      &:after {
        background: var(--color-gray-text);
      }
    }
  }
}

.modal__layout {
  display: none;
  position: relative;
  z-index: 11000;
  cursor: default;
  padding: 80px 0;
  margin: 0 auto;
  min-height: 100%;
  align-items: center;
  justify-content: center;

  &.opened {
    display: flex;
  }
}

.modal__container {
  border-radius: var(--modal-content-radius);
  background: var(--modal-content-background);
  box-shadow: var(--modal-content-shadow);
  position: relative;

  &._without-padding {
    padding: var(--grid-spacer) 0;
  }
}

.modal__content {
  img {
    max-width: 100%;
    height: auto;
  }
}

.modal__closer {
  position: absolute;
  width: 40px;
  height: 40px;
  border: 1px solid var(--color-gray-text);
  border-radius: 20px;

  justify-content: center;
  display: flex;
  align-items: center;

  font-size: var(--modal-closer-size);
  color: var(--modal-closer-color);

  text-decoration: none;
  cursor: pointer;

  //собственно крестик (нет, тупо символ не годится)
  &-inner {
    position: relative;
    width: 40px;
    height: 40px;
    display: block;

    &:before,
    &:after {
      position: absolute;
      content: "";
      top: 50%;
      left: 50%;
      background: var(--color-gray-bg);
      width: 2px;
      height: 20px;
    }

    &:before {
      transform: translate3d(-50%, -50%, 0) rotate(-45deg);
    }

    &:after {
      transform: translate3d(-50%, -50%, 0) rotate(45deg);
    }
  }
}

.modal__loader {
  display: block;
  position: fixed;
  z-index: 3000;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: var(--modal-bg);
  transition: 0.3s all;
  opacity: 0;
  visibility: hidden;
}

body.modal-loading {
  overflow: hidden;
  --primary-color-opacity: var(--color-primary-dark);
  --preloader-size: 100px;

  .modal__loader {
    opacity: 1;
    visibility: visible;

    &:before, &:after {
      content: '';
      position: fixed;
      width: var(--preloader-size);
      height: var(--preloader-size);
      left: 50%;
      top: 50%;
      margin-left: calc(var(--preloader-size) / -2);
      margin-top: calc(var(--preloader-size) / -2);
      background-color: #000;
      border-radius: calc(var(--preloader-size) / 2);
      transform: scale(0);
    }

    &:before {
      background-color: var(--primary-color-opacity, var(--primary-color));
      animation-name: pulse;
      animation-iteration-count: infinite;
      animation-duration: 1.4s;
      animation-delay: 0.4s;
    }

    &:after {
      background-color: var(--primary-color);
      animation-name: pulse;
      animation-iteration-count: infinite;
      animation-duration: 1.4s;
    }
  }
}


@include respond-up('large') {
  .modal__closer {
    top: 0;
    right: -68px;
  }

  .modal__container {
    padding: var(--modal-content-padding);
  }
}

@include respond-down('medium') {
  .modal__closer {
    bottom: calc((40px + var(--grid-gap)) * -1);
    right: calc(50% - 20px);
  }

  .modal__container {
    padding: 30px var(--grid-spacer) 40px;
  }
}

@keyframes pulse {
  0% {
    transform: scale(0);
  }

  50% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
}
.identify-info-table {
  &__list {

  }

  &__item {
    display: flex;
    padding: 10px 0;
    border-bottom: 1px solid var(--border-color-dark);
  }

  &__name {
    font-weight: bold;
    flex: 0 0 300px;
  }

  &__value {
  }
}

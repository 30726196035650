.order-item {
  position: relative;

  &__arrow {
    position: absolute;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    transition: {
      property: opacity;
      duration: var(--default-transition-time);
    };
  }

  &__value-wrapper {
    display: flex;
    justify-content: center;
  }

  &__value {
    display: flex;
    align-items: center;
    line-height: 145%;
    color: var(--color-gray-text);

    &_id {
      font-weight: 700;
    }

    &_sum {
      font-weight: 700;
      line-height: 145%;
      color: var(--color-dark);
    }

    &_client, &_manager {
      font-weight: 400;
      color: var(--color-gray-text);
    }

    &_date {
      font-weight: 400;
    }

    &_state {
      border-radius: 2px;
      color: var(--color-dark);
      text-align: center;

      &._yellow {
        background-color: var(--color-yellow-bg);
      }

      &._green {
        background: rgba(112, 165, 0, 0.3);
      }

      &._red {
        background: rgba(255, 65, 5, 0.3);
      }
    }

    &-wrapper {
      align-items: center;
    }
  }

  &__links-wrap {
    position: relative;
  }

  &__accordion-link {
    position: absolute;
    right: 64px;
    top: calc(50% - 15px);
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: opacity .4s ease-in-out;

    svg {
      width: 20px;
      height: 20px;

      path {
        transition: transform .4s ease-in-out;
        transform-origin: center;
      }
    }
  }

  &__accordion-block {
    overflow: hidden;
    height: 0;
    transition: height .4s ease-in-out;
  }

  &__link {
    transition: box-shadow .4s ease-in-out;
  }
}

@include respond-up('large') {
  .order-item {
    &:hover {
      .order-item {
        &__accordion-link {
          opacity: 1;
        }
      }
    }

    &__link {
      padding: 20px 0;
      position: relative;
      display: grid;
      grid-column-gap: var(--grid-gap);
    }

    &__accordion-link {
      opacity: 0;
    }

    &__arrow {
      opacity: 0;
      right: 30px;
      top: calc(50% - 10px);
    }

    &__value {
      font-size: 13px;

      &_id {
        padding-left: 20px;
      }

      &_sum {
        font-size: 15px;
      }

      &_state {
        font-weight: 600;
        padding: 3px 8px;
        font-size: 12px;
      }
    }
  }
}

@include respond-down('medium') {
  .order-item {
    &__link {
      padding: 14px calc(var(--grid-spacer) + 30px + 20px) 14px var(--grid-spacer);
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &__arrow {
      right: var(--grid-spacer);
      top: 50%;
      transform: translateY(-50%);
    }

    &__value {
      font-size: 12px;

      &_id {
        color: var(--primary-color);
        text-decoration: underline;
        margin-right: 20px;
        flex: 0 0 40px;
        width: 40px;
      }

      &_date {
        flex: 0 0 64px;
        width: 64px;
      }

      &_sum {
        font-size: 14px;
        flex: 0 0 80px;
        width: 80px;
      }

      &_paid {
        flex: 0 0 100px;
        width: 100px;
      }

      &_paid,
      &_sum,
      &_date,
      &_client, &_manager {
        margin-right: 30px;
      }

      &_state {
        padding: 3px 6px;
        font-size: 11px;
        font-weight: 400;
        line-height: 133%;
      }

      &_client, &_manager {
        flex: 0 0 140px;
        width: 140px;
      }
    }

    &__value-wrapper {
      flex: 0 0 117px;
      width: 117px;
    }
  }
}
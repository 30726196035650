.has-question-block {
  background-color: white;
  border-radius: 2px;

  &__description {
    margin-top: 2px;
  }

  &__title {
    font-style: normal;
    font-weight: 700;
    color: var(--color-dark);
  }

  &__button {
    &.button {
      width: 100%;
    }
  }
}

@include respond-up('large') {
  .has-question-block {
    padding: 30px 40px 40px;

    &__title {
      font-size: 24px;
      line-height: 140%;
    }

    &__button {
      &.button {
        margin-top: 30px;
      }
    }
  }
}

@include respond-down('medium') {
  .has-question-block {
    padding: 20px 20px 40px;

    &__title {
      font-size: 23px;
      line-height: 120%;
    }

    &__button {
      &.button {
        margin-top: 20px;
      }
    }
  }
}
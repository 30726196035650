.one-c-orders-page {
  &__headers {

  }
  &__headers, &__link {
    display: grid;
    grid-gap: var(--grid-gap);
    grid-template-columns: repeat(8, 1fr) 0.7fr 0.7fr 0.7fr;
  }

  &__header {

  }

  &__header, &__value {
    &:first-child {
      padding-left: var(--grid-spacer);
    }
    grid-row: 1/2;

    &_number {grid-column: 1/2}
    &_manager {grid-column: 2/4}
    &_state {grid-column: 4/6}
    &_ozon-state {grid-column: 6/8}
    &_ozon {grid-column: 8/10}
    &_marketplace {grid-column: 10/12}
  }

  &__list {

  }

  &__item {

    &:nth-child(n + 2) {
      border-top: 1px solid var(--border-color);
    }

  }

  &__link {
    background-color: white;
    padding: 16px 0;
  }

  &__value {

  }
}

@include respond-up('large') {
  .one-c-orders-page {

  }
}

@include respond-down('medium') {
  .one-c-orders-page {

  }
}
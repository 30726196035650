.order-state-form {
  &__form {
    display: flex;
    align-items: center;
  }

  &__fields {
    padding-top: 30px;
    width: 100%;
    display: flex;
  }
}

@include respond-up('large') {
  .order-state-form {
    &__fields {
      align-items: center;

      .form-field {
        &:nth-child(n + 2) {
          margin-left: var(--grid-gap);
        }
      }
    }
  }
}

@include respond-down('medium') {
  .order-state-form {
    &__form {
      flex-direction: column;
      row-gap: 4px;
      align-items: flex-start;
    }

    &__fields {
      flex-direction: column;
      .form-field {
        &:nth-child(n + 2) {
          margin-top: var(--grid-gap);
        }
      }
    }
  }
}
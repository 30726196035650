.catalog-order-block {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: var(--grid-gap);
  align-items: center;

  &__panel {
    display: flex;
    align-items: center;
    position: relative;

    &:hover {
      .catalog-order-block__button {
        opacity: 1;
      }
    }
  }

  &__button {
    padding: 0;
    background-color: transparent;
    color: gray;
    position: absolute;
    height: 100%;
    width: 27px;
    top: 0;
    opacity: 0;

    svg {
      width: 8px;
      height: 8px;

      path {
        transition: stroke var(--default-transition-time) var(--default-timing-function);
      }
    }

    &:hover {
      background-color: transparent;

      svg path {
        stroke: var(--primary-color);
      }
    }

    &_dec {
      left: 0;
    }

    &_inc {
      right: 0;
    }
  }

  &__input {
    width: 91px;
    background: white;
    border: 1px solid var(--color-gray-border);
    height: 26px;
    border-radius: 2px;
    font-weight: 400;
    font-size: 13px;
    line-height: 145%;
    text-align: center;
    color: var(--color-dark);
  }

  &__button-wrap {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;
  }

  &__action {
    width: 24px;
    height: 24px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: {
      repeat: no-repeat;
      position: center;
    };

    &_add {
    }

    &_already {
      border: 1.5px solid rgba(112, 165, 0, 0.3);
      border-radius: 3px;
      background-color: transparent;
      pointer-events: none;
    }

    &_remove {
      background-color: transparent;

      svg {
        width: 18px;
        height: 18px;
        path {
          transition: stroke var(--default-transition-time) var(--default-timing-function);
        }
      }

      &:hover {
        background-color: transparent;

        svg {
          path {
            stroke: var(--primary-color)
          }
        }
      }
    }
  }
}

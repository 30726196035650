.stat-graph {
  &__title {
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 10px;
  }

  &__form {
    display: flex;
    margin: 0 -10px 20px;
  }

  &__field {
    padding: 0 10px;

    &._date {
      flex: 0 0 170px;
      width: 170px;
    }
  }

  &__content {
    position: relative;
  }

  &__graph-wrap {
    display: flex;
    position: relative;
  }

  &__graph {
    flex: 1 1 auto;
  }

  &__legend-wrap {
    flex: 0 0 200px;
    height: 400px;
    overflow: auto;
    position: relative;
  }

  &__preloader {

  }

  &__empty {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 20px;
    color: var(--color-gray-text);
    background-color: var(--color-gray-bg-70);
  }
}
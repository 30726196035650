.notifications-modal {
  display: flex;

  &__icon {
    display: flex;
    height: max-content;
    padding-top: 2px;

    svg {
      width: 24px;
      height: 24px;
    }
  }

  &__main {
    margin-left: 19px;
    flex: 1 1 auto;
  }

  &__title {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 120%;
    color: var(--color-dark);
  }

  &__content {
    margin-top: 16px;
    color: var(--color-gray-text);
  }
}

@include respond-up('large') {
  .notifications-modal {
    width: var(--grid-column8);
    padding-right: calc((var(--grid-column) + var(--grid-gap)) - 40px);
  }
}

@include respond-down('medium') {
  .notifications-modal {

  }
}
.header-mobile {
  --mobile-header-height: 50px;
  height: var(--mobile-header-height);
  position: relative;

  &__menu-wrap {
    display: none;
  }

  &__container {
    padding: 0 var(--grid-spacer);
    background-color: white;
    position: fixed;
    z-index: 1000;
    left: 0;
    width: 100%;
    top: 0;
    box-shadow: 0 1px 15px 0 rgba(0, 0, 0, 0.10);
  }

  &__inner {
    display: flex;
    align-items: center;
    height: var(--mobile-header-height);
  }

  &__burger {
    flex: 0 0 auto;
    height: 100%;
    padding-right: 12px;
    display: flex;
    align-items: center;
  }

  &__logo-wrap {
    flex: 0 0 auto;
    height: 100%;
    display: flex;
    align-items: center;

    svg {
      flex: 0 0 96px;
      width: 96px;
      height: 18px;
    }
  }

  &__actions {
    display: flex;
    flex: 1 1 auto;
    justify-content: flex-end;
    margin: 0 -8px;
  }

  &__action {
    position: relative;
    display: flex;
    align-items: center;
    margin: 0 8px;

    .header__button {
      margin: 0;
    }
    svg {
      path[stroke] {
        stroke: var(--color-gray-text);
      }
    }

    &._active {
      svg {
        path[stroke] {
          stroke: var(--primary-color);
        }
      }
    }
  }

  &__profile {
    display: flex;
    align-items: center;
  }

  &__notify {
    display: flex;
    align-items: center;
  }
}

@include respond-up('large') {
  .header-mobile {

  }
}

@include respond-down('medium') {
  .header-mobile {

  }
}

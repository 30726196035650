.notifications-empty {
  background-color: #fff;
  border-radius: 2px;
  padding: 40px var(--grid-spacer) 50px;

  &__title {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 120%;
    color: var(--color-dark);
  }

  &__subtitle {
    color: var(--color-gray-text);
    margin-top: 9px;
  }

  .notify-settings {
    margin-top: 80px;
    width: max-content;
  }
}

.index-page {

}

@include respond-up('large') {
  .index-page {
    &__advantages {
      padding-top: 120px;
      padding-bottom: 120px;
    }

    &__form {
      margin-top: 160px;
    }
  }
}

@include respond-down('medium') {
  .index-page {
    &__advantages {
      padding-top: 40px;
      padding-bottom: 60px;
    }

    &__form {
      margin-top: 60px;
    }
  }
}

.cart-manager-form {
  margin-top: 64px;
  background-color: white;
  padding: 40px var(--grid-spacer) 50px;

  &__fields {

  }

  &__field {
    margin-top: var(--grid-gap);
  }
}

@include respond-up('large') {
  .cart-manager-form {

  }
}

@include respond-down('medium') {
  .cart-manager-form {

  }
}

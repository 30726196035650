.edit-requisites-form {
  &__item {
    position: relative;
  }

  &__visible {
    display: flex;

    textarea {
      font: var(--font);
      display: flex;
      resize: none;
      overflow: hidden;
    }

    input[type="text"], textarea {
      border: 1px solid var(--t);
      border-radius: 3px;
      font-weight: 400;
      transition: {
        property: border-color;
        duration: var(--default-transition-time);
      };
    }
  }

  &__name {
    input[type="text"] {
      color: var(--color-gray-text);
    }
  }

  &__value {
    flex: 1 1 auto;
    display: flex;
  }

  &__hide {
    display: flex;
    position: absolute;
    right: 0;
    top: calc(100% - 1px);
    background-color: white;
    border-radius: 3px;
    opacity: 0;
    pointer-events: none;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    transition: {
      property: opacity;
      duration: var(--default-timing-function);
    };
  }
}

@include respond-up('large') {
  .edit-requisites-form {
    &__list {
      margin-top: 28px;
    }

    &__item {
      &:nth-child(n + 2) {
        border-top: 1px solid var(--color-gray-border);
      }

      &:hover {
        z-index: 10;
        .edit-requisites-form {
          &__hide {
            opacity: 1;
            pointer-events: all;
          }
        }
      }
    }

    &__visible {
      textarea {
        height: 40px;
        line-height: 24px;
      }

      input[type="text"], textarea {
        padding: 9px 0;
        width: 100%;
        font-size: 16px;
        line-height: 24px;

        &:hover {
          border: 1px solid var(--color-gray-border);
        }
      }
    }

    &__name {
      flex: 0 0 45%;
      padding-right: var(--grid-gap);
    }

    &__view, &__edit {
      padding-right: var(--grid-gap);
    }

    &__hide {
      padding: 7px;
    }

    &__button-wrap {
      margin-top: 40px;
    }

    &__icon {
      display: none;
    }
  }
}

@include respond-down('medium') {
  .edit-requisites-form {
    &__title {
      padding: 0 var(--grid-spacer);
    }

    &__form {
      margin-top: 20px;
    }

    &__item {
      padding: 10px 0 12px;

      &:first-child {
        padding-top: 0;
      }

      &:nth-child(odd) {
        background: var(--color-gray-bg);
      }
    }

    &__visible {
      flex-direction: column;
    }

    &__name {
      input[type='text'] {
        height: fit-content;
        padding: 0 var(--grid-spacer);
        border-radius: unset;
        background: var(--t);
      }
    }

    &__value {
      textarea {
        padding: 5px var(--grid-spacer);
        border-radius: unset;
        background: var(--t);
      }
    }

    &__icon {
      position: absolute;
      top: 20px;
      right: var(--grid-spacer);
      svg {
        width: 24px;
        height: 24px;

        path {
          stroke: var(--primary-color);
        }
      }
    }

    &__button-wrap {
      margin-top: 30px;
      padding: 0 var(--grid-spacer);
    }

    &__button {
      width: 100%;
    }
  }
}
.temp-class {
  display: grid;
  grid-gap: 50px;
  grid-template-columns: repeat(2, 1fr);
}

.custom-select {
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  position: relative;

  &_type {
    &_multi {
      .custom-select__label {
        padding: 7px 12px;
      }
    }

    &_field-iconable {
      .custom-select {
        &__iconable-field {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }

        &__field-icon {
          display: flex;
          align-items: center;
        }

        &__wrapper {
          align-items: flex-end;
        }

        &__field-icon {
          margin-right: 4px;
        }

        &__field-name {
          color: var(--color-dark);
          font-size: 15px;
          line-height: 133%;
          font-weight: 600;
        }

        &__content-wrap_openable {
          left: unset;
          right: 0;
          width: 233px;
          min-width: unset;
        }

        &__arrow-icon {
          svg {
            path {
              fill: var(--color-dark);
            }
          }
        }
      }
    }
  }

  &._open {
    z-index: 500;

    .custom-select {
      &__arrow-icon {
        transform: rotate(-180deg);
      }

      &__content-wrap_openable, &__list {
        max-height: 300px;
      }
    }

    &.custom-select_type_filterable {
      .custom-select {
        &__list {
          max-height: 300px - 54px;
        }
      }
    }
  }

  &._disabled {
    pointer-events: none;
    .custom-select {
      &__field {
        background-color: var(--color-gray-bg);
      }
    }
  }


  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-content: flex-start;
    position: relative;
    flex: 0 0 100%;
  }

  &__field-name {
    font-size: 15px;
    line-height: 133%;
    color: var(--color-primary-dark);
  }

  &__field-wrapper {
    width: 100%;
    position: relative;
    z-index: 35;
  }

  &__arrow-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 10px;
    height: 7px;
    pointer-events: none;
    transition: {
      property: transform;
      duration: .2s;
    };

    &_absolute {
      position: absolute;
      top: calc(50% - 3px);
      right: 15px;
    }

    &_relative {
      margin-left: 6px;
    }
  }

  &__field {
    background-color: white;
    height: 44px;
    padding-left: 12px;
    padding-right: 34px;
    border: 1px solid var(--color-gray-border);
    border-radius: 2px;
    width: 100%;
    font-size: 15px;
    line-height: 135%;

    &:read-only {
      cursor: pointer;
      caret-color: var(--t);
    }
  }

  &__content-wrap {
    display: block;
    background-color: white;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.07);

    &_openable {
      max-height: 0;
      overflow: hidden;
      position: absolute;
      left: 0;
      min-width: 100%;
      top: 100%;
      z-index: 30;
    }
  }

  &__list {
    overflow: auto;
  }

  &__item {
    width: 100%;
    display: block;

    &._hidden {
      display: none;
    }

    &:hover {
      background-color: var(--color-grey-backgorund);
    }
  }

  &__input {
    display: none;

    &:disabled, &._disabled {
      + .custom-select__label > span.custom-select {
        &__choice-name {
          color: var(--color-gray-text);
        }
        &__choice-icon {
          background-color: var(--color-gray-bg);
          border-color: var(--color-gray-border);
        }
      }

      &:checked {
        + .custom-select__label > span.custom-select__choice-icon {
          background-color: var(--color-gray-border);
        }
      }
    }

    &:checked + .custom-select__label {
      background-color: var(--color-background);

      .custom-select {
        &__choice-icon {
          background-color: var(--primary-color);
          border-color: var(--primary-color);

          svg {
            opacity: 1;
          }
        }
      }
    }

    + .custom-select__label {
      cursor: pointer;
      display: flex;
      align-items: center;
      padding: 5px 12px;
      margin: 0;
      transition: {
        property: background-color;
        duration: .3s;
      };

      &_type-url{
        padding: 0;
      }

      &:hover {
        background-color: var(--color-background);
      }

      &::after, &::before {
        display: none;
      }
    }
  }

  &__label {
  }

  &__choice-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 19px;
    height: 19px;
    border: 1px solid var(--color-gray-border);
    border-radius: 3px;
    transition: background-color .2s, border-color .2s;
    transition-timing-function: var(--default-timing-function);

    svg {
      width: 11px;
      height: 11px;
      transition: opacity .2s var(--default-timing-function);
      opacity: 0;
    }
  }

  &__choice-name {
    color: var(--color-dark);
    font-size: 15px;
    line-height: 135%;
    margin-left: 6px;
  }

  &__filter {
    position: relative;
    padding: 0 12px;
    margin-top: 10px;
  }

  &__filter-label {
    height: 34px;
    width: 100%;
    border: 1px solid var(--color-gray-border);
    padding: 7px 32px 7px 8px;
  }

  &__filter-icon {
    display: flex;
    height: max-content;
    position: absolute;
    pointer-events: none;
    top: 50%;
    right: 20px;
    transform: translate3d(0, -50%, 0);
  }
}
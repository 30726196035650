.auto-order-block {
  background-color: white;

  &__empty {
    padding: var(--grid-gap) 0;
  }

  &__requests {
  }
}

@include respond-up('large') {
  .auto-order-block {

  }
}

@include respond-up('medium') {
  .auto-order-block {

    &__title {
      padding: 20px 0 15px;
    }

    &__requests {
      margin-top: 30px;
    }
  }
}

@include respond('medium') {
  .auto-order-block {

  }
}

@include respond-down('medium') {
  .auto-order-block {

  }
}

@include respond-down('small') {
  .auto-order-block {

    &__title {
      padding: 15px 0 10px;
    }

    &__requests {
      margin-top: 20px;
    }
  }
}
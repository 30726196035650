/*@font-face {*/
/*	font-family: 'Nunito Sans';*/
/*	src: url('NunitoSans-ExtraLight.woff2') format('woff2'),url('NunitoSans-ExtraLight.woff') format('woff');*/
/*	font-weight: 200;*/
/*	font-style: normal;*/
/*}*/
/*@font-face {*/
/*	font-family: 'Nunito Sans';*/
/*	src: url('NunitoSans-ExtraLightItalic.woff2') format('woff2'), url('NunitoSans-ExtraLightItalic.woff') format('woff');*/
/*	font-weight: 200;*/
/*	font-style: italic;*/
/*}*/
/*@font-face {*/
/*	font-family: 'Nunito Sans';*/
/*	src: url('NunitoSans-Light.woff2') format('woff2'), url('NunitoSans-Light.woff') format('woff');*/
/*	font-weight: 300;*/
/*	font-style: normal;*/
/*}*/
/*@font-face {*/
/*	font-family: 'Nunito Sans';*/
/*	src: url('NunitoSans-LightItalic.woff2') format('woff2'), url('NunitoSans-LightItalic.woff') format('woff');*/
/*	font-weight: 300;*/
/*	font-style: italic;*/
/*}*/
@font-face {
	font-family: 'Nunito Sans';
	src: url('NunitoSans-Regular.woff2') format('woff2'),
	url('./NunitoSans-Regular.ttf') format('truetype'),
	url('NunitoSans-Regular.woff') format('woff');
	font-weight: 400;
	font-style: normal;
}
/*@font-face {*/
/*	font-family: 'Nunito Sans';*/
/*	src: url('NunitoSans-Italic.woff2') format('woff2'), url('NunitoSans-Italic.woff') format('woff');*/
/*	font-weight: 400;*/
/*	font-style: italic;*/
/*}*/
@font-face {
	font-family: 'Nunito Sans';
	src: url('NunitoSans-SemiBold.woff2') format('woff2'),
	url('./NunitoSans-SemiBold.ttf') format('truetype'),
	url('NunitoSans-SemiBold.woff') format('woff');
	font-weight: 600;
	font-style: normal;
}
/*@font-face {*/
/*	font-family: 'Nunito Sans';*/
/*	src: url('NunitoSans-SemiBoldItalic.woff2') format('woff2'), url('NunitoSans-SemiBoldItalic.woff') format('woff');*/
/*	font-weight: 600;*/
/*	font-style: italic;*/
/*}*/
@font-face {
	font-family: 'Nunito Sans';
	src: url('NunitoSans-Bold.woff2') format('woff2'),
	url('./NunitoSans-Bold.ttf') format('truetype'),
	url('NunitoSans-Bold.woff') format('woff');
	font-weight: 700;
	font-style: normal;
}
/*@font-face {*/
/*	font-family: 'Nunito Sans';*/
/*	src: url('NunitoSans-BoldItalic.woff2') format('woff2'), url('NunitoSans-BoldItalic.woff') format('woff');*/
/*	font-weight: 700;*/
/*	font-style: italic;*/
/*}*/
@font-face {
	font-family: 'Nunito Sans';
	src: url('NunitoSans-ExtraBold.woff2') format('woff2'),
	url('./NunitoSans-ExtraBold.ttf') format('truetype'),
	url('NunitoSans-ExtraBold.woff') format('woff');
	font-weight: 800;
	font-style: normal;
}
/*@font-face {*/
/*	font-family: 'Nunito Sans';*/
/*	src: url('NunitoSans-ExtraBoldItalic.woff2') format('woff2'), url('NunitoSans-ExtraBoldItalic.woff') format('woff');*/
/*	font-weight: 800;*/
/*	font-style: italic;*/
/*}*/
/*@font-face {*/
/*	font-family: 'Nunito Sans';*/
/*	src: url('NunitoSans-Black.woff2') format('woff2'), url('NunitoSans-Black.woff') format('woff');*/
/*	font-weight: 900;*/
/*	font-style: normal;*/
/*}*/
/*@font-face {*/
/*	font-family: 'Nunito Sans';*/
/*	src: url('NunitoSans-BlackItalic.woff2') format('woff2'), url('NunitoSans-BlackItalic.woff') format('woff');*/
/*	font-weight: 900;*/
/*	font-style: italic;*/
/*}*/

.client-info-block {
  background-color: white;
  border-radius: 2px;

  &__info-container {
    display: flex;
    flex-direction: column;
  }

  &__logo {
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
  }

  &__picture {
    display: flex;
    overflow: hidden;
    border-radius: 100%;
    background-color: var(--color-gray-bg);
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__name {
    font-weight: 700;
    line-height: 120%;
    align-self: center;
  }

  &__point-title {
    font-weight: 400;
    line-height: 150%;
    color: var(--color-gray-text);
  }

  &__point {
    display: flex;
    align-items: center;
    margin-bottom: 8px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

@include respond-up('large') {
  .client-info-block {
    padding: 40px;

    &__title-wrap {
      display: none;
    }

    &__content-wrap {
      display: grid;
      grid-template-columns: repeat(7, 1fr);
      grid-gap: var(--grid-gap);
    }

    &__info-container {
      padding-left: 28px;
      grid-column: 4/8;
      position: relative;

      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: 1px;
        background-color: var(--color-gray-border);
      }
    }

    &__logo-container {
      display: grid;
      grid-template-columns: 80px 1fr;
      grid-column-gap: 16px;
      width: calc(var(--grid-column3) - 40px);
      grid-column: 1/4;
    }

    &__logo {
      height: 80px;
    }

    &__picture {
      width: 80px;
      height: 80px;
    }

    &__name {
      font-size: 20px;
    }

    &__point-title {
      font-size: 16px;
      margin-right: 8px;
    }
  }
}

@include respond-down('medium') {
  .client-info-block {
    &__title-wrap {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 20px;
      border-bottom: 1px solid var(--color-gray-border);
    }

    &__title {
      font-weight: 600;
      line-height: 145%;
    }

    &__icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 20px;
      height: 20px;

      svg {
        width: 100%;
        height: 100%;

        path {
          stroke: var(--color-primary-dark);
        }
      }
    }

    &__content-wrap {
      padding: 24px 20px 40px;
    }

    &__logo-container {
      display: flex;
      column-gap: 20px;
    }

    &__logo {
      height: 60px;
      flex: 0 0 60px;
    }

    &__picture {
      width: 60px;
      height: 60px;
    }

    &__name {
      font-size: 18px;
    }

    &__info-container {
      margin-top: 30px;
    }

    &__point-title {
      font-size: 15px;
      margin-right: 10px;
    }
  }
}

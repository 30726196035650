.about-client-block {
  background: white;
  border-radius: 2px;

  &__requisites-item {
    display: flex;
  }

  &__orders {
    display: flex;
    flex-direction: column;
  }

  &__order-wrap {
    border: 2px solid var(--color-gray-bg);
    border-radius: 2px;
    display: flex;

    &_done {
      background: var(--color-gray-bg);
    }
  }

  &__orders-wrapper {
    border-top: 1px solid var(--color-gray-border);
    display: flex;
  }

  &__contacts {
    display: flex;
    color: var(--color-gray-text);
  }

  &__info-wrap {
    display: flex;
  }

  &__info-wrapper {
    display: flex;
  }

  &__client-logo {
    overflow: hidden;
    background: var(--color-gray-bg);
    border-radius: 40px;
    display: flex;
  }

  &__chat-button {
    flex: 0 0 auto;
  }

  &__picture {
    display: flex;
    width: 100%;
    height: 100%;
    flex: 0 0 100%;
  }

  &__image {
    flex: 0 0 100%;
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
  }

  &__add-button.button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  &__button-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 24px;
    width: 24px;
    height: 24px;

    svg {
      width: 100%;
      height: 100%;

      path {
        stroke: var(--primary-color);
      }
    }
  }

  &__button-text {

  }
}

@include respond-up('large') {
  .about-client-block {
    padding-bottom: 40px;

    &__container {
      padding: 0 var(--grid-spacer);
    }

    &__requisites-item {
      padding: 7px 0;

      &:not(:last-child) {
        border-bottom: 1px solid var(--color-gray-border);
      }

      :first-child {
        flex: 0 0 45%;
        padding-right: 20px;
      }
    }

    &__requisites {
    }

    &__requisites-heading {
      margin-bottom: 28px;
    }

    &__requisites-wrapper {
      margin-top: 60px;
    }

    &__orders-icon {
      svg {
        width: 27px;
        height: 27px;
      }
    }

    &__orders-info {
      margin-top: 7px;
    }

    &__order-wrap {
      padding: 30px 40px;
      align-items: center;
      justify-content: space-between;
      flex: 0 0 calc(50% - 14px);
    }

    &__orders-wrapper {
      padding-top: 40px;
      margin-top: 30px;
      justify-content: space-between;
    }

    &__back-link {
      padding: 30px;
      margin: -30px;
      color: var(--primary-color);

      svg {
        margin-right: 9px;
      }
    }

    &__email {
      position: relative;
      padding-left: 10px;
      margin-left: 10px;

      &:before {
        position: absolute;
        content: '';
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        height: 19px;
        width: 1px;
        background: var(--primary-color);
      }
    }

    &__contacts {
      align-items: center;
      margin-top: 10px;
    }

    &__info {
      margin-left: 20px;
    }

    &__info-wrap {
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
    }

    &__info-wrapper {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-top: 40px;
    }

    &__client-logo {
      width: 80px;
      height: 80px;
      flex: 0 0 80px;
    }

    &__top-wrapper {
      padding: 30px 0 0;
    }

    &__add-requisite-form {
      margin-top: 50px;
    }

    &__add-button-wrapper {
      margin-top: 40px;
    }
  }
}

@include respond-down('medium') {
  .about-client-block {
    &__top-wrapper {
      padding: 20px var(--grid-spacer) 40px;
    }

    &__back-link {
      display: none;
    }

    &__client-logo {
      width: 40px;
      height: 40px;
      flex: 0 0 40px;
    }

    &__info-wrapper {
      flex-direction: column;
      row-gap: 30px;
    }

    &__info-wrap {
      column-gap: 20px;
    }

    &__info {
      display: flex;
      row-gap: 20px;
    }

    &__info,
    &__contacts {
      flex-direction: column;
    }

    &__contacts {
      row-gap: 4px;
      position: relative;
      padding-left: 14px;

      &:before {
        position: absolute;
        content: '';
        width: 1px;
        height: 100%;
        top: 0;
        left: 0;
        background: var(--primary-color);
      }
    }

    &__orders-wrapper {
      flex-direction: column;
      row-gap: 20px;
      padding: 30px var(--grid-spacer) 0;
    }

    &__order-wrap {
      padding: 20px;
      background: var(--color-gray-bg);
      justify-content: space-between;
    }

    &__orders-info {
      margin-top: 3px;
    }

    &__orders-icon {
      height: 100%;
      display: flex;
      align-self: center;
    }

    &__requisites-heading {
      padding: 0 var(--grid-spacer);
      margin-bottom: 20px;
    }

    &__requisites {
    }

    &__requisites-item {
      padding: 10px var(--grid-spacer);
      border-bottom: 1px solid var(--color-gray-border);
      flex-direction: column;
      row-gap: 6px;

      &:first-child {
        padding-top: 0;
      }
    }

    &__requisites-wrapper {
      margin-top: 40px;
    }

    &__edit-requisites-form,
    &__add-requisite-form {
      margin-top: 60px;
    }

    &__add-button-wrapper {
      padding: 30px var(--grid-spacer) 40px;
    }
  }
}

.quantity-by-prices {
  position: absolute;
  left: 6px;
  top: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 200;

  &:hover {
    .quantity-by-prices {
      &__icon svg circle {
        fill: var(--color-gray-text);
      }

      &__list-wrap {
        opacity: 1;
        pointer-events: auto;
      }
    }
  }

  &__icon {
    display: flex;
    cursor: pointer;

    svg {
      circle {
        transition: fill var(--default-transition-time);
      }
    }
  }

  &__list-wrap {
    position: absolute;
    opacity: 0;
    pointer-events: none;
    transition: opacity var(--default-transition-time);

    background-color: var(--color-primary-white);
    left: 16px;
    top: 0;
    border-radius: 3px;
    padding: 8px 10px;

    &::before {
      content: '';
      position: absolute;
      cursor: pointer;
      left: -10px;
      top: 0;
      border: 5px solid var(--t);
      border-right-color: var(--color-primary-white);
    }
  }

  &__list {

  }

  &__item {
    display: flex;
  }

  &__value {
    color: var(--color-dark);
    font-size: 11px;
    font-weight: 400;
    line-height: 145%;
    white-space: nowrap;
  }

  &__quantity {
    color: var(--color-dark);
    font-size: 11px;
    font-weight: 600;
    line-height: 120%;
    white-space: nowrap;
  }
}

@include respond-up('large') {
  .quantity-by-prices {

  }
}

@include respond-up('medium') {
  .quantity-by-prices {

  }
}

@include respond('medium') {
  .quantity-by-prices {

  }
}

@include respond-down('medium') {
  .quantity-by-prices {

  }
}

@include respond-down('small') {
  .quantity-by-prices {

  }
}
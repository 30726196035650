.client-list {
  &__headers, &__list {
    background-color: white;
  }

  &__item {
    border-top: 1px solid var(--color-gray-border);
    transition: {
      property: box-shadow, border-color;
      duration: var(--default-transition-time);
    };
  }

  &__item-wrapper {
    position: relative;
  }

  &__item-value {
    &_date {
      font-weight: 400;
      line-height: 145%;
      color: var(--color-gray-text);
    }

    &_name {
      font-weight: 700;
      line-height: 125%;
      color: var(--color-dark);
    }

    &_contacts {
      display: flex;
      align-items: center;
      font-weight: 400;
      line-height: 150%;
      color: var(--color-gray-text);
    }
  }

  &__arrow {
    position: absolute;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    transition: {
      property: opacity;
      duration: var(--default-transition-time);
    };
  }
}

@include respond-up('large') {
  .client-list {
    &__headers, &__item-wrapper {
      display: grid;
      grid-template-columns: repeat(9, var(--grid-column));
      grid-column-gap: var(--grid-gap);
      align-items: center;
      width: 100%;
    }

    &__header, &__item-value {
      &:nth-child(1) {
        grid-column: 1/2;
        padding-left: 20px;
      }

      &:nth-child(2) {
        grid-column: 2/5;
      }

      &:nth-child(3) {
        grid-column: 5/10;
      }
    }

    &__header {
      font-weight: 400;
      font-size: 13px;
      line-height: 145%;
      padding: 20px 0;
    }

    &__item {
      &:hover {
        border-top-color: transparent;
        box-shadow: 0 1px 20px rgba(0, 0, 0, 0.1);

        & + .client-list__item {
          border-top-color: transparent;
        }

        .client-list {
          &__arrow {
            opacity: 1;
          }
        }
      }
    }

    &__item-wrapper {
      padding: 24px 0;
    }

    &__item-value {
      &_date {
        font-size: 13px;
      }

      &_name {
        font-size: 16px;
      }

      &_contacts {
        font-size: 16px;
      }
    }

    &__email {
      &:nth-child(2) {
        position: relative;
        padding-left: 11px;
        margin-left: 10px;
        &::before {
          content: '';
          position: absolute;
          width: 1px;
          background-color: var(--primary-color);
          height: 100%;
          left: 0;
          top: 0;
        }
      }
    }

    &__arrow {
      opacity: 0;
      right: 40px;
      top: calc(50% - 10px);
    }
  }
}

@include respond-down('medium') {
  .client-list {
    &__headers {
      display: none;
    }

    &__item-wrapper {
      padding: 20px var(--grid-spacer) 30px;
      display: flex;
      flex-direction: column;
    }

    &__item {
      &:first-child {
        border-top: unset;
      }
    }

    &__item-value {
      &_date {
        font-size: 12px;
      }

      &_name {
        margin-top: 4px;
        font-size: 15px;
      }

      &_contacts {
        font-size: 15px;
        flex-direction: column;
        padding-left: 14px;
        position: relative;
        align-items: flex-start;
        margin-top: 20px;
        row-gap: 4px;

        &::before {
          content: '';
          position: absolute;
          width: 1px;
          background-color: var(--primary-color);
          height: 100%;
          left: 0;
          top: 0;
        }
      }
    }

    &__arrow {
      right: var(--grid-spacer);
      bottom: 30px;
    }
  }
}
$grid: (
  x-small: (
    from: 0,
    to: 350,
  ),
  small: (
    from: 0,
    to: 767,
  ),
  medium: (
    from: 768,
    to: 1279,
  ),
  large: (
    from: 1280,
  ),
  x-large: (
    from: 1921
  )
);

:root {
  --body-font-size: 16px;
  --body-line-height: 1.3;

  --font: 'Nunito Sans', "Arial", sans-serif;
  --h-font: 'Nunito Sans', "Arial", sans-serif;

  --t: transparent;

  --primary-color: #FF891D; //tangerine
  --alert-color: var(--reddish-color);
  --default-transition: all 0.4s;
  --default-timing-function: ease-in-out;
  --default-transition-time: .4s;

  --primary-color-opacity: rgba(1, 199, 103, .85);

  // Из макета
  --color-dark: #2C2B2E; // Темный основной
  --color-gray-text: #7D7984; // Серый текст
  --color-yellow-bg: #FFE8AE; // Жолтама светлый
  --color-white-text-20: rgba(255, 255, 255, 0.2); // Белый 0.2
  --color-some-grey-backgorund: #565656; // это Белый 0.2 на #2D2D2D потому что Белый 0.2 накладывается друг на друга
  --color-white-text-60: rgba(255, 255, 255, 0.6); // Белый текст 0.6
  --color-primary-dark: #FF7A00; // Основной темный – orange
  --color-primary-white: #FFE2C7; // Основной светлее – orange
  --color-gray-bg: #F1F1F4; // Серый фон - Серый подлога
  --color-gray-bg-dark: #E6E7EA; // Серый фон - Подлога темнее
  --color-gray-bg-70: rgba(241, 241, 244, 0.7); // Серый фон .7
  --color-gray-bg-80: rgba(241, 241, 244, 0.8); // Серый фон .8
  --color-gray-border: #CAC9CE; // Серый обводка
  --color-primary-light: #FFD0A4; // Основной светлый, Бледно-оранжевый обводка
  --color-primary-light-opacity: rgba(255, 137, 29, 0.4); // Основной светлый, Бледно-оранжевый обводка
  --color-grey-backgorund: #EAEAEA; //Подлога темнее
  --color-dark-backgorund: #030303; //темный такой фон
  --color-bg-light: rgba(255, 124, 29, 0.1); //какой-то светлый рыжий для фона

  --greenish-color: #70A500; //зеленец
  --reddish-color: #FF4105; //краснуля

  //  Временные
  --bg-color: #E5E5E5;
  --border-color: #E5E5E5;
  --border-color-dark: #b3b3b3;
  --text-color-gray: #666666;
  --color-orange: #FE701AFF;
}

.add-requisite-form {
  &__title {
    margin-bottom: 28px;
  }

  &__char {
    margin-bottom: 15px;

    textarea {
      height: 125px;
      resize: none;
    }
  }

  &__checkbox {
    margin-bottom: 10px;
  }

  &__button-wrap {
    margin-top: 30px;
  }
}

@include respond-up('large') {
  .add-requisite-form {
    width: 500px;
    &__fields {
    }
  }
}

@include respond-down('medium') {
  .add-requisite-form {
    //padding: 0 var(--grid-spacer) 30px;

    &__fields {
    }
  }
}
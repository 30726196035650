.ozon-transactions {

  &__headers, &__item {
    display: grid;
    grid-gap: var(--grid-gap);
    grid-template-columns: repeat(9, 1fr);
  }

  &__headers {

  }

  &__header, &__value {
    &:first-child {
      padding-left: var(--grid-spacer);
    }

    &:last-child {
      padding-right: var(--grid-spacer);
    }

    &_id {
      grid-column: 1/2;
    }

    &_number {
      grid-column: 2/4;
    }

    &_operation {
      grid-column: 4/6;

    }

    &_amount {
      grid-column: 6/8;
    }

    &_type {
      grid-column: 8/10;
    }
  }

  &__item {
    padding: 16px 0;

    &:nth-child(n + 1) {
      border-top: 1px solid var(--border-color);
    }
  }

  &__value {

  }
}

@include respond-up('large') {
  .ozon-transactions {

  }
}

@include respond-down('medium') {
  .ozon-transactions {

  }
}
.tree-node {
  padding-left: $treeNodePadding;
  position: relative;
  padding-bottom: 45px;

  &:after {
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    bottom: 0;
    width: 1px;
    background-color: #dadada;
  }

  &:before {
    position: absolute;
    content: '';
    left: 0;
    top: ($treeHeaderHeight/2);
    height: 1px;
    width: $treeNodePadding;
    background-color: #dadada;
  }

  &__append-before {
    position: absolute;
    width: 24px;
    height: 24px;
    color: #fff;
    border-radius: 5px;
    background-color: $light-gray-color;
    border: 1px solid #D0D0D0;
    top: -22px;
    left: -11px;
    z-index: 30;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      background-color: #fff;
    }
  }

  &__icon-wrap {
    line-height: 0.8;
    padding-top: 5px;
  }

  &__head {
    height: $treeHeaderHeight;
    margin-bottom: $tree-filter-head-bottom;

    display: flex;
    align-items: center;
    justify-content: flex-start;

    position: relative;

    &:before {
      content: '';
      position: absolute;
      left: $sub-left-padding;
      bottom: -$filter-head-bottom;
      width: 1px;
      background-color: #dadada;
      height: $filter-head-bottom;
    }
  }

  &__title {
    background: #E5F6FF;
    border-radius: 5px;
    font-weight: 500;
    padding: 2px 6px;
    font-size: 12px;
    color: #2A2A2A;

    height: $treeHeaderHeight;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  &__remove-branch {
    width: 22px;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__create-sub-branch {
    height: 40px;
    display: flex;
    align-items: flex-end;
    position: relative;

    &:before {
      position: absolute;
      content: '';
      top: 0;
      bottom: 0;
      left: $tree-sub-left-padding;
      width: 1px;
      background-color: #dadada;
    }
  }

  &__sub-branch-button {
    width: 24px;
    height: 24px;
    color: #fff;
    border-radius: 5px;
    background-color: $light-gray-color;
    border: 1px solid #D0D0D0;
    margin-left: 0;
    position: relative;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      background-color: #fff;
    }
  }

  .appender {
    width: max-content;
  }

  .append-condition {
    width: max-content;

    span {
      color: #658fdc;
    }
  }

  &__conditions {
    display: flex;

  }

  &__comment {
    appearance: none;
    border: 1px solid var(--color-gray-border);
    border-radius: 2px;
    box-shadow: none;
    font-family: var(--font);
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    margin: 0;
    padding: 14px;
    transition: border-color var(--default-timing-function) var(--default-transition-time);
    width: 100%;
    margin-left: var(--grid-gap);
  }
}
button, .button {
  margin: 0;
  text-align: center;
  display: inline-block;
  font-size: 15px;
  font-weight: 700;
  cursor: pointer;
  text-decoration: none;
  border-radius: 2px;
  line-height: 16px;
  color: white;
  padding: 16px 25px 18px;

  //primary on default
  border: none;
  background-color: var(--primary-color);
  transition: {
    property: color, background-color;
    duration: var(--default-transition-time);
    timing-function: var(--default-timing-function);
  };

  &:hover {
    background-color: var(--color-primary-dark);
    text-decoration: none;
  }

  &_expand, &_wide {
    width: 100%;
  }

  &_round {
    border-radius: 100px;
  }

  //просто типа ссылочка
  &__link {
    background: var(--t);
    color: white;
    font-weight: 600;

    &:hover {
      background: var(--t);
    }
  }

  //кнопочка обводочкой
  &__secondary {
    padding: 15px 25px;
    color: var(--primary-color);
    background: var(--t);
    border: 2px solid var(--color-primary-light-opacity);
    transition: border-color var(--default-transition-time) var(--default-timing-function);

    &.button__icon {

      svg {
        path {
          stroke: var(--primary-color);
        }
      }
    }

    &:hover {
      background: var(--t);
      border-color: var(--color-primary-dark);
    }
  }
}

button:disabled,
button[disabled=disabled],
.button._disabled {
  background-color: var(--color-grey-backgorund);
  color: var(--color-gray-text);
  border-color: var(--color-grey-backgorund);
  cursor: inherit;

  &:hover {
    background-color: var(--color-grey-backgorund);
    color: var(--color-gray-text);
    border-color: var(--color-grey-backgorund);
  }

  &.button__icon {
    svg {
      path {
        stroke: var(--color-gray-text);
      }
    }
  }

  &.button__cart {
    background-color: var(--color-gray-border);
  }
}

.button {
  &__icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 12px 19px;

    svg {
      margin-right: 8px;
      width: 25px;
      height: 24px;
    }

    &_right {
      svg {
        margin-left: 8px;
        margin-right: 0;
      }
    }
  }
}

.button {
  &__cart {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    padding: 0;
  }
}
.stat-page {
  padding-bottom: 150px;

  &__h1 {
    padding: 30px 0 40px;
  }

  &__contents,
  &__inner-content,
  &__links-block,
  &__links--block-list
  {}

  &__tabs {
    display: flex;

    &.tabs_black {
      margin-bottom: 20px;
    }
  }

  &__content {
    background-color: white;
    padding: var(--grid-gap);
  }

  &__links-block-item {
    position: relative;
    background-color: white;
    display: flex;
    transition: {
      property: box-shadow, border-color;
      duration: var(--default-transition-time);
    };

    &:nth-child(n + 2) {
      border-top: 1px solid var(--color-gray-border);
    }

    &:hover {
      z-index: 10;
      box-shadow: 0 1px 20px rgba(0, 0, 0, 0.1);
      border-top-color: transparent;

      + .stat-page__links-block-item {
        border-top-color: transparent;
      }
    }
  }

  &__links-block-link {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 18px 40px 18px 30px;
    flex: 1 1 auto;
  }

  &__tables {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: var(--grid-gap);

    &:nth-child(n + 2) {
      margin-top: var(--grid-gap);
      padding-top: var(--grid-gap);
      border-top: 1px solid var(--border-color);
    }
  }

  &__row {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: var(--grid-gap);

    &:nth-child(n + 2) {
      padding-top: var(--grid-gap);
      margin-top: var(--grid-gap);
      border-top: 1px solid var(--border-color);
    }
  }

  &__table {
  }

  &__graph {
    position: relative;

    &_start {
      grid-column: 1/3;
    }
    &_large {
      grid-column: 1/4;
      height: 500px;
    }
    &_end {
      grid-column: 2/4;
    }
  }

  &__large-block {
    grid-column: 1/4;
  }

  &__graph-wrap {
    grid-column: 1/4;
  }

  &__graph-full {
    grid-column: 1/4;
  }

  &__back-link {
    color: var(--primary-color);
  }

  &__back-link-icon {
    margin-right: 5px;
  }
}

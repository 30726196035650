.catalog-page {
  padding-bottom: 120px;

  &__top {
    display: flex;
  }

  &__tabs {

  }

  &__clients {

  }

  &__content {

  }
}

@include respond-up('large') {
  .catalog-page {
    &__top {
      justify-content: space-between;
      align-items: center;
    }
  }
}

@include respond-down('medium') {
  .catalog-page {
    &__top {
      flex-direction: column-reverse;
      padding: 0 var(--grid-spacer);
    }

    &__content, &__tabs {
      overflow: scroll;

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
}

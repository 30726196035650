.ufo-block {
  position: relative;

  &__back {
    position: relative;
    z-index: 10;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.1) 51.56%, rgba(255, 255, 255, 0) 82.29%);
    opacity: 0.3;
    border-radius: 300px;
  }

  &__front {
    position: absolute;
    z-index: 20;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
  }

  &__ufo {
    display: flex;
    justify-content: center;
  }

  &__ufo-light-wrap {
    position: absolute;
    width: 100%;
  }

  &__ufo-light {
    position: absolute;
    display: flex;
    justify-content: center;
    width: 100%;

    &_big {
      z-index: 30;
    }

    &_medium {
      z-index: 40;

    }

    &_small {
      z-index: 40;
    }
  }

  &__goods {
    position: absolute;
    overflow: hidden;
    top: 0;
    border-radius: 100%;

    &_far {
      z-index: 35;
    }
    &_close {
      z-index: 45;
    }
  }

  &__light-bottom {
    position: absolute;
    background: linear-gradient(180deg, #FFC896 -37.5%, rgba(255, 174, 100, 0) 86.6%);
    opacity: 0.4;
    filter: blur(1.5px);
    transform: matrix(1, 0, 0, -1, 0, 0);
    border-radius: 100%;
  }

  &__goods-item {
    position: absolute;
    top: 100%;

    &._raise-1 {
      animation: raise-1 6s cubic-bezier(0, 0.03, 0, 0.96) infinite;
      animation-delay: 4s;
    }
    &._raise-2 {
      animation: raise-2 6s cubic-bezier(0, 0.03, 0, 0.96) infinite;
      animation-delay: 2s;
    }
    &._raise-3 {
      animation: raise-3 6s cubic-bezier(0, 0.03, 0, 0.96) infinite;
    }
  }

  &__cow {
    position: absolute;
    top: 100%;
    animation: cow 40s cubic-bezier(0, 0.03, 0, 0.96) infinite;
  }

  &__powder {
    position: absolute;
    left: 50%;
    top: 100%;
    border-radius: 100px;
    background-color: rgba(255, 179, 66, 0.20);

    animation: {
      duration: 13s;
      timing-function: ease-out;
      iteration-count: infinite;
      name: powder;
    };

    @for $i from 1 through 15 {
      &:nth-child(#{$i}) {animation-delay: calc(#{$i} * 1s);}
    }

    &._left {
      animation-name: powder-left;
    }

    &._right {
      animation-name: powder-right;
    }

    &:nth-child(4n-5) {background-color: rgba(255, 222, 173, 0.3)}
    &:nth-child(3n-4) {background-color: rgba(255, 179, 66, 0.6)}

    &:nth-child(6n-8) {animation-duration: 8s;}
    &:nth-child(5n-7) {animation-duration: 20s;}
  }
}

@include respond-up('large') {
  .ufo-block {
    &__back {
      width: 268px;
      height: 268px;
    }

    &__ufo-light-wrap {
      top: 70px;
    }

    &__ufo {
      margin-top: -29px;
    }

    &__goods {
      width: 246px;
      height: 198px;
      left: calc(50% - 123px);
    }

    &__light-bottom {
      left: calc(50% - 115px);
      top: 166px;
      width: 230px;
      height: 32px;
    }

    &__goods-item {
      left: calc(50% - 35px);
    }

    &__cow {
      width: 70px;
      left: calc(50% - 35px);
    }

    &__powder {
      height: 15px;
      width: 15px;

      &._left {
        &:nth-child(4n-3) {left: calc(50% - 10px)}
        &:nth-child(3n-1) {left: calc(50% - 40px)}
      }

      &._right {
        &:nth-child(6n-5) {left: calc(50% + 20px)}
        &:nth-child(3n-1) {left: calc(50% + 70px)}
      }

      &:nth-child(3n+2) {width: 8px; height: 8px;}
      &:nth-child(5n-3) {width: 30px; height: 30px;}
    }
  }

  @keyframes raise-1 {
    0% { top: 100%; transform: scale(2) translateX(-60px); }
    100% { top: -20%; transform: scale(.2) translateX(0); }
  }
  @keyframes raise-2 {
    0% { top: 100%; transform: scale(1.4) translateX(120px); }
    100% { top: -20%; transform: scale(.2) translateX(0); }
  }
  @keyframes raise-3 {
    0% { top: 100%; transform: scale(1); }
    100% { top: -20%; transform: scale(.2); }
  }
  @keyframes powder {
    0% { top: 100%; transform: scale(1); }
    100% { top: -5%; transform: scale(.2); }
  }
  @keyframes powder-left {
    0% { top: 100%; transform: scale(1) translateX(-90px); }
    100% { top: -5%; transform: scale(.2) translateX(0); }
  }
  @keyframes powder-right {
    0% { top: 100%; transform: scale(1) translateX(90px); }
    100% { top: -5%; transform: scale(.2) translateX(0); }
  }
  @keyframes cow {
    0% { top: 120%; transform: scale(2) translateX(-30px) rotate(0); }
    75% { top: 120%; transform: scale(2) translateX(-30px) rotate(0); }
    100% { top: -20%; transform: scale(.2) translateX(0) rotate(700deg); }
  }
}

@include respond-down('medium') {
  .ufo-block {
    &__back {
      width: 134px;
      height: 134px;
    }

    &__ufo-light-wrap {
      top: 35px;
    }

    &__ufo {
      margin-top: -14px;

      svg {
        width: 123px;
        height: 61px;
      }
    }

    &__goods {
      width: 123px;
      height: 99px;
      left: calc(50% - 61px);
    }

    &__light-bottom {
      left: calc(50% - 57px);
      top: 83px;
      width: 115px;
      height: 16px;
    }

    &__goods-item {
      left: calc(50% - 17px);

      &._raise-1 {
        svg {
          width: 35px;
          height: 30px;
        }
      }
      &._raise-2 {
        svg {
          width: 30px;
          height: 35px;
        }
      }
      &._raise-3 {
        svg {
          width: 29px;
          height: 31px;
        }
      }
    }

    &__cow {
      width: 35px;
      left: calc(50% - 17px);
    }

    &__powder {
      height: 7px;
      width: 7px;

      &._left {
        &:nth-child(4n-3) {left: calc(50% - 5px)}
        &:nth-child(3n-1) {left: calc(50% - 20px)}
      }

      &._right {
        &:nth-child(6n-5) {left: calc(50% + 10px)}
        &:nth-child(3n-1) {left: calc(50% + 35px)}
      }

      &:nth-child(3n+2) {width: 4px; height: 4px;}
      &:nth-child(5n-3) {width: 15px; height: 15px;}
    }

    &__ufo-light {
      &_big svg {
        width: 114px;
        height: 99px;
      }

      &_medium svg {
        width: 94px;
        height: 110px;
      }

      &_small svg {
        width: 45px;
        height: 113px;
      }
    }

    @keyframes raise-1 {
      0% { top: 100%; transform: scale(2) translateX(-30px); }
      100% { top: -20%; transform: scale(.2) translateX(0); }
    }
    @keyframes raise-2 {
      0% { top: 100%; transform: scale(1.4) translateX(60px); }
      100% { top: -20%; transform: scale(.2) translateX(0); }
    }
    @keyframes raise-3 {
      0% { top: 100%; transform: scale(1); }
      100% { top: -20%; transform: scale(.2); }
    }
    @keyframes powder {
      0% { top: 100%; transform: scale(1); }
      100% { top: -5%; transform: scale(.2); }
    }
    @keyframes powder-left {
      0% { top: 100%; transform: scale(1) translateX(-45px); }
      100% { top: -5%; transform: scale(.2) translateX(0); }
    }
    @keyframes powder-right {
      0% { top: 100%; transform: scale(1) translateX(45px); }
      100% { top: -5%; transform: scale(.2) translateX(0); }
    }
    @keyframes cow {
      0% { top: 120%; transform: scale(2) translateX(-15px) rotate(0); }
      75% { top: 120%; transform: scale(2) translateX(-15px) rotate(0); }
      100% { top: -20%; transform: scale(.2) translateX(0) rotate(350deg); }
    }
  }
}
.order-accordion-checkout-item {
  &:nth-child(odd) {
    background: rgba(241, 241, 244, 0.7);
  }


  &__product-subtitle {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  &__product-subtitle-icon {
    display: flex;
    margin-left: 5px;
  }
}

@include respond-up('large') {
  .order-accordion-checkout-item {
    display: grid;
    grid-gap: var(--grid-gap);
    grid-template-columns: repeat(9,1fr);
    padding: 20px 0;

    &__column {
      &_name {
        display: flex;
        align-items: center;
        grid-column: 1/6;
        padding-left: 20px;
      }

      &_price {
        display: flex;
        padding: 8px 0;
      }
    }

    &__totals {
      grid-column: 6/10;
      display: flex;
      flex-direction: column;
    }

    &__product-title {
      margin-right: 10px;
    }

    &__quantity {
      flex: 0 0 calc(var(--grid-column2) + (var(--grid-gap) * 2));
      display: flex;
      justify-content: flex-end;
      padding-right: 10px;
    }

    &__amount {
      flex: 0 0 var(--grid-column2);
    }

    &__positions {

    }
  }
}

@include respond-down('medium') {
  .order-accordion-checkout-item {
    display: flex;
    justify-content: space-between;
    padding: 14px calc(var(--grid-spacer) + 30px + 20px) 14px var(--grid-spacer);

    &__column {
      &_name {
        display: flex;
        flex: 1 1 auto;
        flex-direction: column;
      }

      &_price {
        display: flex;
        justify-content: flex-end;
        padding: 5px 0;
      }
    }

    &__amount {
      flex: 0 0 112px;
    }

    &__quantity {
      margin-right: 10px;
      flex: 0 0 auto;
    }
  }
}

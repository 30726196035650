.identify-field {
  b {
    font-weight: bold;
    color: black;
  }
  &_radio {

  }

  &_model {

  }
}

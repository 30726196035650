.stat-search-item {
  display: flex;
  flex: 1 1 auto;

  &__date-wrap {
    flex: 0 0 150px;
    display: flex;
    flex-direction: column;
  }

  &__date {
    font-size: 12px;
    color: var(--color-gray-text);
  }

  &__time {
    font-size: 14px;
    color: var(--color-dark);
    font-weight: bold;
    margin-top: 10px;
  }

  &__client-wrap {
    flex: 0 0 350px;
    padding-right: 20px;
  }

  &__client-name {
    font-size: 14px;
    color: var(--color-gray-text);

  }

  &__client-organization {
    font-size: 14px;
    color: var(--color-dark);
  }

  &__list {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
  }

  &__list-item {
    flex: 1 1 auto;
    display: flex;
    padding: 3px 0;

    &:nth-child(n + 2) {
      border-top: 1px solid var(--border-color);
    }
  }

  &__item-name {
    flex: 0 0 100px;
    font-size: 12px;
    color: var(--color-gray-text);
  }

  &__item-value {
    flex: 1 1 auto;
    font-size: 12px;
    color: var(--color-dark);
    font-weight: bold;
    max-height: 30px;
    overflow: hidden;

    &:hover {
      max-height: unset;
    }
  }

  &__count-wrap {
    flex: 0 0 200px;
    display: flex;
    flex-direction: column;
  }

  &__count {
    font-weight: bold;
    color: var(--color-dark);
    font-size: 12px;
    text-align: center;
    margin-top: 15px;
  }

  &__type {
    color: var(--color-dark);
    font-size: 12px;
    text-align: center;
  }

  &__pagination-wrap {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: var(--grid-gap);
  }
}
.auto-order-history {

  &__title {
    margin-bottom: var(--grid-gap);
  }
  &__list {

  }

  &__item {
    display: flex;
    width: 500px;
    padding: 10px 0;

    &:nth-child(n + 2) {
      border-top: 1px solid var(--border-color);
    }
  }

  &__number {
    color: var(--color-dark);
    font-size: 14px;
    flex: 0 0 30px;
    font-weight: bold;
  }

  &__date {
    color: var(--color-dark);
    font-size: 14px;
    flex: 0 0 170px;
  }

  &__status {
    color: var(--color-dark);
    font-size: 14px;
    font-weight: bold;
    flex: 1 1 auto;
  }

  &__empty {
    color: var(--color-gray-text);
    font-weight: bold;
    font-size: 16px;
    width: 500px;
  }
}

@include respond-up('large') {
  .auto-order-history {

  }
}

@include respond-down('medium') {
  .auto-order-history {

  }
}
.identify-detail-page {
  &__info {
    padding: 30px 0;
  }

  &__forms {
    padding: 30px 0;

  }

  &__raw-data {
    padding: 30px 0;

  }
}

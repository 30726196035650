.chat-form {
  &__form {

  }

  &__field {
    position: relative;
    label {
      display: none;
    }

    &_text {
      .errors {
        position: absolute;
        top: 100%;
        right: 0;
      }
    }
  }
}

@include respond-up('large') {
  .chat-form {
    &__form {
      padding: 26px 0 80px;
      align-items: end;
      display: grid;
      grid-gap: var(--grid-gap);
      grid-template-columns: auto 1fr 170px;
    }

    &__field {
    }


    &__button {
      flex: 0 0 170px;
    }
  }
}

@include respond-down('medium') {
  .chat-form {
    &__form {
      padding: 20px 0 40px;
      flex-direction: column;
    }

    &__button {
      &.button {
        margin-top: 14px;
        width: 100%;
      }
    }
  }
}
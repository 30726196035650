.notifications-block {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 10000;
  //overflow: hidden;
  pointer-events: none;

  &__outer {
    box-shadow: 0 -4px 25px rgba(0, 0, 0, 0.1);
    background-color: white;
    width: 100%;
    transform: translateY(80px);
    opacity: 0;
    transition: {
      property: transform, opacity;
      duration: var(--default-transition-time);
      timing-function: var(--default-timing-function);
    };
  }

  &._show {
    .notifications-block {
      &__outer {
        transform: translateY(0);
        opacity: 1;
      }

      &__closer {
        pointer-events: all;
      }
    }
  }

  &__inner {
    position: relative;
  }

  &__list {
    width: 100%;
    position: relative;
    height: 80px;
  }

  &__item {
    padding: 0 42px;
    border-radius: 0 0 2px 2px;
    display: flex;
    align-items: center;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background-color: white;

    &._error {
      .notifications-block {
        &__text {
          color: var(--reddish-color);
        }
      }
    }

    &._success {
      .notifications-block {
        &__text {
          color: var(--greenish-color);
        }
      }
    }
  }

  &__icon {
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    margin-right: 20px;

    svg {
      width: 20px;
      height: 20px;
    }
  }

  &__text {
    flex: 1 1 auto;
    font-weight: 700;
    font-size: 16px;
    line-height: 125%;
  }

  &__closer {
    display: flex;
    align-items: center;
  }
}

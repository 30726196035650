.identify-failed-list {
  &__list {

  }

  &__item {
    border-bottom: 1px solid var(--border-color);
    position: relative;
  }


  &__header, &__link {
    display: grid;
    grid-template-columns: 90px 200px 1fr 200px 200px 100px;
    padding: 15px 20px;
  }

  &__link {
    background-color: white;
    color: var(--text-color-gray);
    transition: {
      property: background-color;
      duration: .3s;
    };

    &:hover {
      background-color: #F3F3F3;
    }
  }

  &__header {

  }

  &__id, &__count, &__header-text_id, &__header-text_count{
    display: flex;
    justify-content: center;
  }

  &__remove {
    position: absolute;
    right: 0;
    height: 100%;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;

    svg path[stroke]{
      transition: var(--default-transition-time) stroke;
    }

    &:hover {
      svg path[stroke] {
        stroke: var(--reddish-color);
      }
    }
  }
}

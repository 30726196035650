.notifications-tabs {
  &.tabs {
    &_main {
      .tabs {
        &__link {
          svg {
            margin-right: 0;

            path {
              stroke: var(--color-gray-text);
            }
          }
        }
      }
    }
  }

  &__icon-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    margin-right: 6px;

    svg {
      width: 20px;
      height: 20px;

      path {
        transition: stroke var(--default-timing-function) var(--default-transition-time);
      }
    }
  }
}

@include respond-down('medium') {
  .notifications-tabs {
    &__tab {
      &._staff {
        .notifications-tabs {
          &__icon-wrap {
            display: none;
          }
        }
      }
    }
  }
}
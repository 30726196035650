.sort-field {
  display: flex;
  align-items: center;
  cursor: pointer;

  &__inputs {
    display: flex;
    flex-direction: column;
  }

  &__label {
    font-weight: 600;
    font-size: 13px;
    line-height: 145%;
    color: var(--color-dark);
    margin-left: 6px;
  }

  &__input-wrap {

  }

  &__input {
    display: none;

    &:checked {
      +.sort-field__arrow {
        &_up {
          border-bottom-color: var(--color-dark);
        }

        &_down {
          border-top-color: var(--color-dark);
        }
      }
    }
  }

  &__arrow {
    border: 3.5px solid var(--t);

    &_up {
      border-bottom: 4px solid var(--color-gray-text);
      margin-bottom: 4px;
    }

    &_down {
      border-top: 4px solid var(--color-gray-text);
    }
  }
}

.catalog-auto-filter-extend {
  display: flex;

  &__info-wrap {
  }

  &__modification-name {
    font-weight: 700;
    line-height: 120%;
    color: var(--color-dark);
  }

  &__info-list {
  }

  &__info-item {
    margin-top: 10px;
  }

  &__info-name, &__info-value {
    font-weight: 400;
    line-height: 150%;
  }

  &__info-name {
    color: var(--color-gray-text);
  }

  &__info-value {
    color: var(--color-dark);
  }

  &__fields-wrap {
  }

  &__top {
  }

  &__middle {
    display: flex;
    padding-top: 20px;

    .checkbox-list {
      display: flex;

      .checkbox-list__item {
        &:not(:last-child) {
          margin-right: var(--grid-gap);
        }
      }
    }
  }

  &__bottom {
    display: flex;
  }

  &__bottom-fields {
    display: flex;
  }

  &__buttons {
    display: flex;
  }

  &__button {
  }

  &__main-field {
  }
}

@include respond-up('large') {
  .catalog-auto-filter-extend {
    padding-top: 40px;

    &__info-wrap {
      flex: 0 0 calc(var(--grid-column3) - 40px);
      margin-right: var(--grid-gap);
    }

    &__modification-name {
      font-size: 20px;
    }

    &__info-name, &__info-name {
      font-size: 16px;
    }

    &__fields-wrap {
      flex: 1 1 auto;
    }

    &__top {
      display: grid;
      grid-gap: 28px;
      grid-template-columns: repeat(4, 1fr);
    }


    &__middle {
      align-items: flex-end;

      .catalog-auto-filter-extend__field {
        &:not(:last-child) {
          margin-right: var(--grid-gap);
        }

        &._storage {
          flex: 0 0 230px;
        }
      }
    }

    &__bottom {
      align-items: center;
      justify-content: space-between;
      padding-top: 28px;
    }

    &__bottom-fields {
      align-items: center;

      .catalog-auto-filter-extend__field {
        &:not(:last-child) {
          margin-right: var(--grid-gap);
        }
      }
    }

    &__buttons {
      align-items: center;
    }

    &__button {
      &:nth-child(2) {
        margin-left: 28px;
      }
    }
  }
}

@include respond-down('medium') {
  .catalog-auto-filter-extend {
    display: flex;
    flex-direction: column;
    padding: 30px var(--grid-spacer);

    &__modification-name {
      font-size: 18px;
    }

    &__info-name, &__info-name {
      font-size: 15px;
    }

    &__top, &__middle, &__bottom, &__bottom-fields {
      display: flex;
      flex-direction: column;
    }

    &__top {
      margin-top: 30px;
    }

    &__field {
      margin-bottom: var(--grid-gap);
    }

    &__buttons {
      flex-direction: column-reverse;
    }

    &__bottom-fields {
      .catalog-auto-filter-extend__field {
        margin-bottom: 4px;
      }
    }

    &__button {
      width: 100%;
      margin-top: var(--grid-gap);
    }
  }
}

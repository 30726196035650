.modal-form {
  &__heading {
    margin-top: 20px;
  }

  &__description {
    margin-top: 10px;
  }

  &__heading-wrapper {
    text-align: center;
    margin-bottom: 28px;
  }

  &__form {

  }

  &__render {
    .form-field {
      margin-bottom: 20px;
    }
  }

  &__button-wrap {
    display: flex;
    justify-content: center;
  }

  &__button-wrap {
    padding-top: 8px;
  }

  &__buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
  }

  &__forgot,
  &__register {
    padding: 10px;
    margin: -10px;
  }

  &__forgot-title {
    color: var(--color-gray-text);
  }

  &__register-title {
    color: var(--primary-color);
  }

  &_purchaser {
    .modal-form__button {
      flex: 0 0 210px;
    }
  }

  &_login {
    &._full {
      margin: auto;
    }
    .modal-form {
      &__title {
        text-align: center;
      }
    }
  }
}

@include respond-up('large') {
  .modal-form {
    width: 368px;
  }
}

@include respond-down('medium') {
  .modal-form {
    min-width: 295px;

    &_login {
      &._full {
        padding: 50px 0;
      }
    }
  }
}
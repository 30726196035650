.notifications-report {
  padding-top: 25px;

  &__link-title {
    display: inline;
    border-bottom: 1px solid var(--t);
    transition: border-bottom-color .4s;
  }

  &__info-list {
    margin-bottom: 15px;
  }

  &__list {
    border-top: 1px solid var(--color-gray-border);
  }

  &__item {
    border-bottom: 1px solid var(--color-gray-border);
    padding: 20px 0;
  }

  &__product-link {
    display: flex;
    flex-direction: column;
    padding-left: 18px;

    &:hover {
      text-decoration: none;
    }
  }

  &__name {
    color: var(--color-dark);
    position: relative;
  }

  &__number {
    position: absolute;
    right: 100%;
    top: 0;
  }

  &__characters {
    display: flex;
    align-items: center;
  }

  &__characters-icon {
    margin-left: 5px;
    display: flex;
    align-items: center;
  }

  &__size-name {
    color: var(--color-gray-text);
  }


  &__additionals {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  &__additional {
    margin-top: 10px;

    &:not(:last-child) {
      margin-right: 10px;
    }
  }

  &__additional-name {
    font-weight: 400;
    font-size: 12px;
    line-height: 133%;
    color: var(--color-dark);
    padding: 3px 8px;
    border-radius: 2px;
    background: var(--color-gray-bg);
  }

  &__bottom {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__apply-filter {
    flex: 0 0 180px;
  }

  &__info-item {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: var(--grid-gap);
  }

  &__price {
    font-weight: bold;
    grid-column: 3/4;
    color: var(--color-dark);
  }

  &__storage {
    grid-column: 1/3;
  }
}

@include respond-up('large') {
  .notifications-report {
    &__item {
      display: grid;
      grid-gap: var(--grid-gap);
      grid-template-columns: repeat(7, 1fr);
    }

    &__name-block {
      grid-column: 1/5;
    }

    &__positions-wrap {
      grid-column: 5/8;
    }
  }
}

@include respond-down('medium') {
  .notifications-report {
    &__item {
      display: flex;
      flex-direction: column;
    }

    &__info-list {
      margin-top: var(--grid-gap);
    }
  }
}
.stat-client-sessions {
  &__list {
    width: 100%;
  }

  &__item {
    width: 100%;
    &:nth-child(n + 2) {
      border-top: 1px solid var(--border-color);
    }

    &._open {
      .stat-client-sessions__sessions {
        display: block;
      }
    }
  }

  &__link {
    width: 100%;
    display: flex;
    padding: 10px 0;
    transition: {
      property: background-color;
      duration: var(--default-transition-time);
    };
    &:hover {
      background-color: var(--color-gray-light);
    }
  }

  &__client-wrap {
    flex: 0 0 500px;
    display: grid;
    grid-template-columns: 100px 1fr;
  }

  &__client-id {
    font-weight: bold;
    font-size: 16px;
    color: var(--color-dark);
    grid-column: 1/2;
    grid-row: 1/3;
    display: flex;
    align-items: center;
  }

  &__client-name {
    font-size: 14px;
    color: var(--color-gray-text);
    grid-column: 2/3;
  }

  &__organization {
    font-size: 14px;
    color: var(--color-dark);
    grid-column: 2/3;
  }

  &__duration-wrap {
    flex: 0 0 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__duration-name {
    font-size: 12px;
    color: var(--color-gray-text);
  }

  &__duration-value {
    font-size: 14px;
    color: var(--color-dark);
    font-weight: bold;
  }

  &__sessions {
    display: none;
    padding: 0 30px;
  }

  &__session {
    display: flex;
    align-items: center;
    padding: 5px 0;
    border-top: 1px solid var(--border-color);
  }

  &__session-headings {
    display: grid;
    grid-template-columns: 200px 200px 200px;
  }

  &__session-heading {
    font-size: 14px;
    color: var(--color-dark);
    padding: 5px 0;
  }

  &__dates {
    display: flex;
    flex: 0 0 400px;
  }

  &__date-wrap {
    flex: 0 0 200px;
    flex-direction: column;
  }

  &__date-name {
    font-size: 12px;
    color: var(--color-gray-text);
  }

  &__date {
    font-size: 12px;
    color: var(--color-dark);
  }

  &__time {
    font-size: 14px;
    color: var(--color-dark);
    font-weight: bold;
    margin-top: 5px;
  }
}
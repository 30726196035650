.organization-form {
  background-color: white;
  border-radius: 2px;

  &__title {
    font-weight: 700;
    line-height: 120%;
  }

  &__form {
    position: relative;
  }
}

@include respond-up('large') {
  .organization-form {
    padding: 40px;

    &__title {
      font-size: 24px;
      margin-bottom: 28px;
    }

    &__fields {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 28px;
      margin-bottom: 40px;
    }

    &__field {
      &_first {
        &:last-child {
          grid-column: 1/3;
        }
      }
    }

    &__requisites {
      padding-top: 60px;
    }
  }
}

@include respond-down('medium') {
  .organization-form {
    padding: 20px 20px 40px;

    &__title {
      &_organisation {
        font-size: 23px;
      }

      &_requisites {
        font-size: 18px;
        margin-bottom: 20px;
      }
    }

    &__fields {
      margin-top: 18px;
      display: flex;
      flex-direction: column;
      row-gap: 16px;
    }

    &__button {
      &.button {
        width: 100%;
        margin-top: 16px;
      }
    }

    &__requisites {
      padding-top: 40px;
    }
  }
}
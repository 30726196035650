.tree-condition-set {
  width: max-content;

  &_multiple, &_root {
    padding: 25px 25px 20px 20px;
    border-radius: 3px;
    background-color: #F9F9F9;
    border: 1px solid #D8D8D8;

    .tree-condition-set_multiple {
      background-color: #F4FAFD;
      border: 1px solid #DEE7EC;

      .tree-condition-set_multiple {
        background-color: #F2F3F8;
        border: 1px solid #E0E4EF;

        .tree-condition-set_multiple {
          background-color: #FCFDF4;
          border: 1px solid #E6E6C1;

          .tree-condition-set_multiple {
            background-color: #F3F1DF;
            border: 1px solid #E1DFC8;
          }
        }
      }
    }

    .tree-condition-set {
      padding-left: 10px;
    }
  }

  &__conditions-wrapper {
    position: relative;
    width: max-content;
  }

  &__condition-logic {
    position: absolute;
    left: -40px;
    bottom: -14.5px;

    select {
      border: var(--vs-border-width) var(--vs-border-style) var(--vs-border-color);
      color: var(--vs-selected-color);
      width: 46px;
      height: 24px;
      //padding: 2px;
      //padding-left: 5px;
      font-size: 12px;
      background-color: #F9F9F9;
      background-position: right 4px top 50%;
    }
  }
}
.swiper-pagination-wrap {
  position: relative;

  &_white {
    .swiper-pagination-wrap__runner {
      background-color: white;
    }
  }

  &_gray  {
    .swiper-pagination-bullet {
      background-color: var(--color-gray-border) !important;
    }
  }

  &__runner {
    position: absolute;
    z-index: 20;
    border-radius: 100px;
    pointer-events: none;
    bottom: 0;
    left: 0;
    background-color: var(--primary-color);
    transition: {
      property: transform, width;
      duration: .4s;
      timing-function: var(--default-timing-function);
    };


  }

  .swiper-pagination {
    transition: .3s opacity;
    transform: translate3d(0, 0, 0);
    z-index: 10;
    display: flex;

    &.swiper-horizontal > .swiper-pagination-bullets, &.swiper-pagination-bullets.swiper-pagination-horizontal, &.swiper-pagination-custom, &.swiper-pagination-fraction {
      bottom: 0;
      width: 100%;

      .swiper-pagination-bullet {
        position: relative;
        display: inline-block;
        border-radius: 4px;
        background-color: white;
        opacity: .5;
        transition:
                background-color var(--default-transition-time) var(--default-timing-function),
                width var(--default-transition-time) var(--default-timing-function),
                opacity var(--default-transition-time) var(--default-timing-function);
      }
    }
  }
}

@include respond-up('large') {
  .swiper-pagination-wrap {
    &__runner {
      width: 32px;
      height: 8px;
    }
  }

  .swiper-pagination {
    &.swiper-horizontal > .swiper-pagination-bullets, &.swiper-pagination-bullets.swiper-pagination-horizontal, &.swiper-pagination-custom, &.swiper-pagination-fraction {
      left: 24px;
      .swiper-pagination-bullet {
        width: 8px;
        height: 8px;
        flex: 0 0 8px;
        margin: 0 16px 0 0;
      }
    }
  }
}

@include respond-down('medium') {
  .swiper-pagination-wrap {
    &__runner {
      width: 22px;
      height: 6px;
    }
  }

  .swiper-pagination {
    &.swiper-horizontal > .swiper-pagination-bullets, &.swiper-pagination-bullets.swiper-pagination-horizontal, &.swiper-pagination-custom, &.swiper-pagination-fraction {
      left: 16px;
      .swiper-pagination-bullet {
        width: 6px;
        height: 6px;
        flex: 0 0 6px;
        margin: 0 10px 0 0;
      }
    }
  }
}
.product-page {
  &._modal {

  }

  &._full {
    background-color: white;
  }

  &__left {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__right {
  }

  &__no-image-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    &::before {
      z-index: 10;
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: var(--color-gray-bg);
      opacity: .8;
      border-radius: 2px;
    }
  }

  &__image-link {
    display: flex;
  }

  &__no-image {
    z-index: 20;
  }

  &__value {
    display: flex;
    align-items: center;
  }

  &__value-icon {
    margin-left: 4px;
    display: flex;
    width: 20px;
    height: 20px;
  }

  &__title {
    font-weight: 700;
    line-height: 120%;
    background-color: var(--color-gray-bg);
  }

  &__item {
    display: flex;
    align-items: center;

    &:nth-child(even) {
      background-color: var(--color-gray-bg);
    }
  }

  &__name, &__value {
    flex: 0 0 50%;
  }

  &__name {
    color: var(--color-gray-text);
  }

  &__value {
    color: var(--color-dark);
  }

  &__image {
    object-fit: contain;
    width: 100%;
    height: 100%;
  }

  &__picture,
  &__producer-picture {
    display: flex;
  }

  &__producer-picture {
  }

  &__additional {
    display: flex;
    flex-wrap: wrap;
  }

  &__additional-item {
    background-color: var(--color-gray-bg);
    font-weight: 400;
    line-height: 133%;
  }
}

@include respond-up('large') {
  ._product-modal-page {
    .modal__content {
      width: var(--grid-column8);
    }
  }

  .product-page {
    display: grid;
    grid-column-gap: var(--grid-gap);

    &._modal {
      max-width: var(--grid-column8);
      grid-template-columns: repeat(8, 1fr);
      .product-page__bottom {
        grid-column: 1/9;
      }
    }

    &._full {
      padding-top: 40px;
      grid-template-columns: repeat(var(--grid-columns-number), 1fr);
      margin-bottom: 150px;
      padding-bottom: 40px;

      .product-page__bottom {
        grid-column: 1/13;
        padding-right: 40px;
      }
    }

    &__left {
      grid-column: 1/4;
      padding-left: 40px;
    }

    &__right {
      grid-column: 4/8;
    }

    &__bottom {
      padding-left: 40px;
    }

    &__no-image-wrapper {
      width: 289px;
      height: 376px;
    }

    &__no-image {
      width: 180px;
      height: 227px;
    }

    &__title {
      font-size: 20px;
      padding: 8px 20px;
    }

    &__item {
      padding: 8px 20px;
    }

    &__producer-picture {
      margin-top: 35px;
      width: 145px;
      height: 82px;
    }

    &__additional {
      margin: 40px -10px 0;
    }

    &__additional-item {
      font-size: 12px;
      margin: 10px;
      padding: 3px 8px;
    }
  }
}

@include respond-down('medium') {
  ._product-modal-page {
    .modal__content {
      width: 335px;
    }
  }

  .product-page {
    display: flex;
    flex-direction: column;

    &._modal {
    }

    &._full {
      margin-bottom: var(--grid-gap);
    }

    &__left {
      padding: var(--grid-spacer) 0;
    }

    &__right {
    }

    &__bottom {
      display: none;
    }

    &__title {
      font-size: 20px;
      padding: 7px var(--grid-spacer);
    }

    &__no-image-wrapper {
      width: 196px;
      height: 249px;
    }

    &__no-image {
      width: 180px;
      height: 227px;
    }

    &__item {
      padding: 6px var(--grid-spacer) 7px;
    }

    &__producer-picture {
      margin-top: var(--grid-gap);
      width: 107px;
      height: 60px;
    }

    &__additional {
      margin: 30px -2px 0;
      padding: 0 var(--grid-spacer);
    }

    &__additional-item {
      font-size: 11px;
      margin: 2px;
      padding: 3px 6px;
    }
  }
}
.editable-requisites-list {
  &__list {

  }

  &__item {
    display: flex;

    &:nth-child(odd) {
      background-color: var(--color-gray-bg);
    }
  }

  &__content {
    flex: 1 1 auto;
  }

  &__value, &__name {
    font-weight: 400;
    line-height: 150%;
  }

  &__name {
    color: var(--color-gray-text);
  }

  &__value {
    color: var(--color-dark);
  }

  &__actions {
    display: flex;
    align-items: flex-start;
    flex: 0 0 24px;
  }

  &__opener {
    flex: 0 0 24px;
    height: 24px;
    width: 24px;

    svg {
      width: 100%;
      height: 100%;

      path {
        stroke: var(--primary-color);
        transition: {
          property: stroke;
          duration: var(--default-transition-time);
        };
      }
    }

    &:hover {
      svg {
        path {
          stroke: var(--reddish-color);
        }
      }
    }
  }
}

@include respond-up('large') {
  .editable-requisites-list {
    &__item {
      display: flex;
      padding: 20px 30px;
    }

    &__actions {
      margin: 0 10px 0 15px;
    }

    &__content {
      display: grid;
      grid-gap: var(--grid-gap);
      grid-template-columns: calc(var(--grid-column1) - 40px) repeat(7, 1fr);
    }

    &__value, &__name {
      font-size: 16px;
    }

    &__name {
      grid-column: 1/4;
    }

    &__value {
      grid-column: 5/9;
    }
  }
}

@include respond-down('medium') {
  .editable-requisites-list {
    &__content {
      flex-direction: column;
    }

    &__value, &__name {
      font-size: 14px;
    }

    &__value {
      margin-top: 8px;
    }

    &__item {
      padding: 10px var(--grid-spacer) 12px;
    }

    &__actions {
      margin-left: 25px;
    }
  }
}

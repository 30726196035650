.tg-chain-link {
  background-color: white;
  padding: 40px var(--grid-spacer);


  &__link {
    width: 100%;
  }
}

@include respond-up('large') {
  .tg-chain-link {

  }
}

@include respond-up('medium') {
  .tg-chain-link {

  }
}

@include respond('medium') {
  .tg-chain-link {

  }
}

@include respond-down('medium') {
  .tg-chain-link {

  }
}

@include respond-down('small') {
  .tg-chain-link {

  }
}
.identify-merge-page {
  &__form {
    display: grid;
    background-color: white;
    border-radius: 6px;
    padding: 15px;
    grid-template-areas:
            'name name'
            'target source'
            'button button';

    .form-field {
      &.name {
        grid-area: name;
      }

      &.target {
        grid-area: target;
      }

      &.source {
        grid-area: source;
      }
    }
  }

  &__button-wrapper {
    display: flex;
    justify-content: center;
    grid-area: button;
  }

  &__list {
    background-color: white;
    border-radius: 6px;
    padding: 15px;
  }

  &__link {
    color: var(--color-orange);
    display: inline-block;
    padding: 5px 0;
    &:hover {
      text-decoration: underline;
    }
  }
}

.clients-content {
  &_client {
    .clients-content {
      &__button-wrapper {
        background-color: #fff;
      }

      &__button {
        display: flex;
        align-items: center;
        box-sizing: border-box;
        border-radius: 2px;
        justify-content: center;
      }

      &__button-text {
        font-weight: 700;
        line-height: 20px;
        color: var(--color-gray-text);
        transition: {
          duration: var(--default-transition-time);
          property: color;
        };
      }

      &__button-svg {
        display: flex;
        align-items: baseline;
        justify-content: center;

        svg {
          width: 100%;
          height: auto;

          path {
            transition: {
              duration: var(--default-transition-time);
              property: stroke;
            };
          }
        }
      }
    }
  }
}

@include respond-up('large') {
  .clients-content {
    &_client {
      .clients-content {
        &__button-wrapper {
          margin-bottom: 20px;
        }

        &__button {
          padding-top: 13px;
          padding-bottom: 13px;

          &:hover {
            .clients-content {
              &__button-text {
                color: var(--primary-color);
              }

              &__button-svg {
                path {
                  stroke: var(--primary-color);
                }
              }
            }
          }
        }

        &__button-text {
          font-size: 15px;
        }

        &__button-svg {
          margin-right: 5px;
          width: 24px;
          height: 24px;
        }
      }
    }
  }
}

@include respond-down('medium') {
  .clients-content {
    &_client {
        background: #fff;
      .clients-content {
        &__button-wrapper {
          padding: 20px var(--grid-spacer) 10px;
        }

        &__button {
          background: var(--color-gray-bg);
          padding: 13px 0;
        }

        &__button-text {
          font-size: 14px;
          color: var(--color-dark);
        }

        &__button-svg {
          margin-right: 5px;
          width: 20px;
          height: 20px;

          svg path {
            stroke: var(--color-dark);
          }
        }
      }
    }
  }
}
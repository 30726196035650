.file-download-input {
  width: 100%;
  height: 100%;

  input[type='file'] {
    position: absolute;
    z-index: 50;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
  }

  label {
    display: none;
  }

  input[type='checkbox'] {
    display: none;

    + label {
      display: none;
    }
  }
}
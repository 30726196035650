.markup-content {
  padding-top: 20px;

  &._extend {
    .markup-content__than-block {
      &::before {
        top: -50px;
        height: 201px;
      }
    }
  }

  &__inner {
    display: flex;
    align-items: center;
  }

  &__than-block {
    width: $form-element-height;
    position: relative;
    height: $form-element-height;

    $left: $sub-left-padding;
    $top: $form-element-height/2;

    &:before {
      content: '';
      position: absolute;
      background-color: #dadada;
      left: $left;
      top: -21px;
      height: $form-element-height + 20px;
      width: 1px;
    }

    &:after {
      content: '';
      position: absolute;
      background-color: #dadada;
      left: $left;
      top: $top;
      right: 0;
      height: 1px;
    }
  }

  &__than-text {
      background-color: #e5f6ff;
      font-size: 12px;
      font-weight: 500;
      border-radius: 5px;
      height: 24px;
      width: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      left: 0;
      top: 50%;
      margin-top: -12px;
      color: $black-color;
      z-index: 10;
  }

  &__main {
    height: 60px;
    padding: 14px;
    text-overflow: ellipsis;
    display: flex;
    align-items: center;
  }

  &__field {
    display: flex;
    flex-direction: column;
    margin-left: var(--grid-gap);

    label {
      font-size: 12px;
    }

    .markup-content__input {
      margin-left: 0;
    }
  }

  &__outer {
    border-radius: 5px;
    background-color: #E6F5F0;
  }

  &__extend {
    height: 60px;
    display: flex;
  }

  &__select {
    width: 210px;
  }

  &__main-title {
    color: var(--vs-search-input-color);
    font-size: var(--vs-font-size);
    line-height: var(--vs-line-height);
    margin-left: 20px;
  }

  &__input {
    margin-left: 20px;
    border: var(--vs-border-width) var(--vs-border-style) var(--vs-border-color);
    color: var(--vs-selected-color);
    font-size: var(--vs-font-size);
    line-height: var(--vs-line-height);
    width: 100px;
    padding: 5px;

    &_custom {
      width: 250px;
      &._error {
        border-color: var(--errors-color);
      }
    }

    &_checkbox {
      width: unset;
      margin-right: 10px;
    }
  }
}

@include respond-up('large') {
  .markup-content {

  }
}

@include respond-up('medium') {
  .markup-content {

  }
}

@include respond('medium') {
  .markup-content {

  }
}

@include respond-down('medium') {
  .markup-content {

  }
}

@include respond-down('small') {
  .markup-content {

  }
}
.identify-resolve-form {
  background-color: white;
  padding: 30px 20px;
  border-radius: 5px;

  &__title {
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 20px;
  }

  &__form {

  }

  &__fields {
    .form-field {
      //padding: 10px 0;

      > label {
        //color: black;
      }

      input {
        &:read-only {
          background-color: #f4f4f4;
          color: #585858;
        }
      }

      li {
        display: flex;

        input {
          margin: 0;
        }

        label {
          //padding-bottom: 6px;
          //padding-left: 5px;
          margin: 0;
          cursor: pointer;
        }
      }
    }
  }

  &__actions {

  }

  &__button {

  }
}

.chat-block {
  display: flex;
  flex-direction: column;
  background-color: white;

  &__top {
    display: flex;
    width: 100%;
    border-bottom: 1px solid var(--color-gray-border);
  }

  &__inner {
    display: flex;
  }

  &__avatar {
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    &._online {
      &::before {
        content: '';
        bottom: 0;
        right: -2px;
        position: absolute;
        background-color: var(--greenish-color);
        border: 2px solid white;
        border-radius: 100%;
        z-index: 100;
      }
    }

    &_no-image {
      color: white;
      background-color: var(--primary-color);
      flex: 0 0 40px;
      width: 40px;
      border-radius: 100px;
      font-weight: bold;
    }
  }

  &__call {
    svg {
      path {
        transition: stroke var(--default-transition-time) var(--default-timing-function);
      }
    }

    &:hover {
      svg {
        path {
          stroke: var(--primary-color);
        }
      }
    }
  }

  &__name-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &__picture {
    display: flex;
  }

  &__manager {
    font-weight: 400;
    line-height: 133%;
    background-color: var(--color-gray-bg);
    border-radius: 2px;
  }

  
  &__manager-mail {
    font-weight: 400;
    line-height: 133%;
    color: var(--text-color-gray);
    font-size: 14px;
    margin-top: 5px;
    transition: color 0.3s ease;

    &:hover{
     color: var(--primary-color)
    }
  }

  &__middle {
    flex: 1 1 auto;
  }

  &__bottom {
    border-top: 1px solid var(--color-gray-border);
  }

  &__messages-wrap {
    position: relative;
    height: 100%;
  }

  &__message-list {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column-reverse;
    overflow: auto;
  }

  &__date {
    font-weight: 400;
    line-height: 145%;
    color: var(--color-gray-text);
  }

  &__text {
    font-weight: 400;
    line-height: 150%;
  }

  &__text-wrapper {
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    position: relative;

    &::before {
      content: '';
      position: absolute;
    }
  }

  &__message-item {
    display: flex;

    &._left {
      .chat-block {
        &__text-wrapper {
          background-color: var(--color-gray-bg);
        }
      }
    }

    &._right {
      justify-content: flex-start;
      flex-direction: row-reverse;
    }
  }

  &__files {
    display: flex;
    margin-top: 10px;
  }

  &__file-item {
    &:nth-child(n + 2) {
      margin-left: 10px;
    }
  }

  &__file-link {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border: 1px solid var(--border-color);
    overflow: hidden;
    border-radius: 4px;
  }

  &__file-picture {
    width: 100%;
    height: 100%;
    flex: 0 0 100%;
  }

  &__file-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  &__file-pdf {
    width: 100%;
    height: 100%;
    background: {
      image: url('../images/svg/pdf.svg');
      position: center;
      size: 30px 30px;
      repeat: no-repeat;
    };
  }
}

@include respond-up('large') {
  .chat-block {
    &__top {
      padding: 30px var(--grid-spacer) 19px;
      justify-content: space-between;
      align-items: center;
    }

    &__inner {
      align-items: center;
    }

    &__avatar {
      margin-right: 20px;
      height: 40px;

      &._online {
        &::before {
          width: 10px;
          height: 10px;
        }
      }
    }

    &__full-name {
      font-weight: 600;
      font-size: 18px;
      line-height: 145%;
      margin-bottom: 5px;
    }

    &__picture {
      width: 40px;
      height: 40px;
    }

    &__manager {
      font-size: 12px;
      padding: 3px 8px;
      //max-width: 102px;
    }

    &__manager-mail {
      font-size: 12px;
    }

    &__message-list {
      padding: 40px 60px;
    }

    &__date {
      font-size: 13px;
    }

    &__text {
      font-size: 16px;
    }

    &__text-wrapper {
      padding: 16px 20px;
      max-width: 700px;

      &::before {
        top: 7px;
      }
    }

    &__message-item {
      flex: 0 0 100px;
      margin-bottom: 28px;

      &._left {
        .chat-block {
          &__text-wrapper {
            &::before {
              border: 16px solid transparent;
              border-right: 16px solid var(--color-gray-bg);
              left: -25px;
            }
          }
        }
      }

      &._right {
        .chat-block {
          &__text-wrapper {
            box-shadow: 0 0 20px rgba(0, 0, 0, 0.08);
            &::before {
              border: 16px solid transparent;
              border-left: 16px solid white;
              right: -25px;
            }
          }
          &__avatar {
            margin-left: 26px;
            margin-right: 0;
          }
        }
      }
    }

    &__back-link {
      display: none;
    }
  }
}

@include respond-down('medium') {
  .chat-block {
    &_manager {
      .chat-block {
        &__top {
          align-items: center;
        }

        &__inner {
          align-items: center;
        }

        &__avatar_header {
          margin-left: 10px;
        }

        &__call {
          svg path {
            stroke: var(--t);
            fill: var(--primary-color);
          }
        }
      }
    }

    &_client {
      .chat-block {
        &__top {
          align-items: flex-start;
        }

        &__inner {
          align-items: flex-start;
        }

        &__full-name {
          margin-bottom: 6px;
        }
      }
    }

    &__top {
      padding: 20px var(--grid-spacer);
      justify-content: space-between;

      .chat-block {
        &__picture {
          width: 34px;
          height: 34px;
        }
      }
    }

    &__avatar {
      margin-right: 10px;
      height: 34px;
      flex: 0 0 34px;

      &._online {
        &::before {
          width: 8px;
          height: 8px;
        }
      }
    }

    &__inner {
      width: 100%;
    }

    &__full-name {
      font-weight: 700;
      font-size: 16px;
      line-height: 125%;
    }

    &__call-wrapper {
      width: calc(var(--grid-column1) - var(--grid-spacer));
      margin-left: var(--grid-gap);
      display: flex;
      justify-content: flex-end;
    }

    &__call {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 20px;
      height: 20px;

      svg {
        width: 100%;
        height: 100%;
      }
    }

    &__picture {
      width: 30px;
      height: 30px;
    }

    &__manager {
      font-size: 11px;
      padding: 3px 6px;
      //max-width: 102px;
    }

    &__message-list {
      padding: 20px var(--grid-spacer) 40px;
    }

    &__date {
      font-size: 12px;
    }

    &__text {
      font-size: 15px;
    }

    &__text-wrapper {
      padding: 14px 14px 20px;
      max-width: 700px;

      &::before {
        top: 10px;
      }
    }

    &__message-item {
      margin-bottom: 30px;

      .chat-block {
        &__avatar {
          flex: 0 0 30px;
          height: 30px;
        }
      }

      &._left {
        .chat-block {
          &__text-wrapper {
            &::before {
              border: 6px solid transparent;
              border-right: 6px solid var(--color-gray-bg);
              left: -12px;
            }
          }
        }
      }

      &._right {
        .chat-block {
          &__text-wrapper {
            box-shadow: 0 0 15px rgba(0, 0, 0, 0.10);
            &::before {
              border: 6px solid transparent;
              border-left: 6px solid white;
              right: -12px;
            }
          }
          &__avatar {
            margin-left: 10px;
            margin-right: 0;
          }
        }
      }
    }
  }
}
.index-partners {
  .swiper {
    width: 100%;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .swiper-slide {
    height: calc((100% - 60px) / 3) !important;

    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__inner {
    background-color: white;
    border-radius: 2px;
  }

  &__description {
    margin-top: 20px;
    color: var(--color-gray-text);
  }

  &__slider-wrapper {
    overflow: hidden;
    position: relative;
  }

  &__slider {
    //row-gap: 30px;
  }

  &__pagination {
    height: 8px;
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .swiper-pagination {
    position: relative;
  }

  &__picture {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

@include respond-up('large') {
  .index-partners {
    margin-top: 120px;

    .swiper-wrapper {
      height: 357px;
    }

    &__slider-wrapper {
      grid-column: 5/13;
      padding: 30px 30px 100px;
    }

    &__text {
      padding: 60px var(--grid-column1) 150px 40px;
      grid-column: 1/5;
    }

    &__pagination {
      position: absolute;
      bottom: 76px;
      left: 0;
    }

    &__picture {
      width: 119px;
      height: 60px;
    }
  }
}

@include respond-down('medium') {
  .index-partners {
    margin-top: 40px;

    &__inner {
      padding: 30px 20px 60px;

      &.grid-block {
        grid-row-gap: 30px;
      }
    }

    &__text {
      grid-column: 1/5;
      grid-row: 1;
    }

    &__slider-wrapper {
      grid-column: 1/5;
      grid-row: 2;
    }

    .swiper-wrapper {
      height: 180px;
    }

    &__picture {
      width: 80px;
      height: 40px;
    }

    &__pagination {
      margin-top: 30px;
    }
  }
}
.cart-quantity-panel {
  display: flex;
  align-items: center;
  position: relative;

  &__button {
    padding: 0;
    background-color: transparent;
    color: gray;
    position: absolute;
    height: 100%;
    width: 27px;
    top: 0;

    svg {
      width: 8px;
      height: 8px;

      path {
        transition: stroke var(--default-transition-time) var(--default-timing-function);
      }
    }

    &:hover {
      background-color: transparent;

      svg path {
        stroke: var(--primary-color);
      }
    }

    &_dec {
      left: 0;
    }

    &_inc {
      right: 0;
    }
  }

  &__input {
    width: 91px;
    background: white;
    border: 1px solid var(--color-gray-border);
    height: 26px;
    border-radius: 2px;
    font-weight: 400;
    font-size: 13px;
    line-height: 145%;
    text-align: center;
    color: var(--color-dark);
  }
}

@include respond-up('large') {
  .cart-quantity-panel {
    &:hover {
      .cart-quantity-panel__button {
        opacity: 1;
      }
    }

    &__button {
      opacity: 0;
    }
  }
}

@include respond-down('medium') {
  .cart-quantity-panel {

  }
}
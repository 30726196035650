.cart-product {
  background-color: white;
  display: grid;
  padding: 20px 0;

  &._opacity {
    .cart-product {
      &__image-wrap, &__info-wrap {
        opacity: .3;
      }
    }
  }

  &__technologies {
    display: flex;
    flex-wrap: wrap;
  }

  &__technology {
    line-height: 133%;
    color: var(--color-dark);
    background: var(--color-gray-bg);
  }

  &__param-icon {
    margin-left: 5px;
    display: flex;
  }

  &__params {
    display: flex;
    align-items: center;
    margin-top: 3px;
  }

  &__params-string {
    color: var(--color-gray-text);
  }

  &__offers-item {
  }

  &__image-wrap {
    position: relative;
  }

  &__id {
    position: absolute;
    left: 0;
    top: 100%;
    color: var(--text-color-gray);
  }

  &__picture, &__no-image-wrapper {
    display: flex;
  }

  &__image, &__no-image {
    flex: 0 0 100%;
    height: 100%;
    object-position: center;
    object-fit: contain;
  }

  &__info-wrap {
  }

  &__offers-wrap {
  }

  &__total-wrap {
    margin-top: 20px;
  }

  &__total {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    background-color: var(--color-gray-bg);
    border-radius: 3px;
  }

  &__total-sum {
    font-style: normal;
    font-weight: 700;
    line-height: 145%;
  }
}

@include respond-up('large') {
  .cart-product {
    grid-template-columns: repeat(9, 1fr);
    grid-gap: var(--grid-gap);

    &__offers-wrap {
      grid-column: 4/10;
    }

    &__info-wrap {
      grid-column: 2/4;
    }

    &__image-wrap {
      grid-column: 1/2;
    }

    &__image-wrap, &__id {
      padding-left: 30px;
    }

    &__total-wrap {
      grid-column: 2/10;
      padding-right: var(--grid-spacer);
    }

    &__technologies {
      margin-top: 10px;
    }

    &__technology {
      font-size: 12px;
      padding: 3px 8px;
      margin-top: 10px;

      &:not(:last-child) {
        margin-right: 10px;
      }
    }

    &__picture, &__no-image-wrapper {
      height: 91px;
    }

    &__offers-item {
      &:not(:last-child) {
        margin-bottom: 8px;
      }
    }

    &__total {
      padding: 5px 50px;
    }

    &__total-sum {
      font-size: 18px;
      margin-left: 10px;
    }
  }
}

@include respond-down('medium') {
  .cart-product {
    grid-template-columns: 70px 1fr;

    &__image-wrap {
      display: flex;
      align-items: flex-start;
      padding: 0 var(--grid-gap);
    }

    &__id {
      padding-left: var(--grid-gap);
    }

    &__info-wrap {
      grid-column: 2/3;

    }

    &__offers-wrap {
      grid-column: 1/3;
      margin-top: 15px;
    }

    &__total-wrap {
      grid-column: 1/3;
      padding: 0 var(--grid-spacer) 10px;
    }

    &__title {
      text-decoration: underline;
    }

    &__technologies {
      margin-top: 6px;
    }

    &__technology {
      font-size: 11px;
      padding: 3px 6px;
      margin-top: 4px;

      &:not(:last-child) {
        margin-right: 4px;
      }
    }

    &__picture {
      height: 40px;
    }

    &__offers-list {
      border-top: 1px solid var(--color-gray-border);
    }

    &__offers-item {
      border-bottom: 1px solid var(--color-gray-border);
      padding: 10px var(--grid-spacer);
    }

    &__total-sum {
      font-size: 16px;
      margin-left: 7px;
    }

    &__total {
      padding: 6px var(--grid-spacer) 7px;
    }
  }
}
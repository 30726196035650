.chart-legend {
  display: flex;
  font-size: 12px;
  flex-direction: column;

  &__item {
    padding-bottom: 10px;
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;

    &._hidden {
      text-decoration: line-through;
    }
  }

  &__icon {
    display: block;
    width: 15px;
    height: 15px;
    border-radius: 2px;
    margin-right: 6px;
  }
}
.catalog-product {
  padding: 20px 0 50px;
  background-color: white;
  grid-template-columns: 2fr 3fr 3fr 3fr 3fr 3fr 3fr 3fr 3fr 2fr 2fr 3fr 3fr;
  display: grid;
  grid-gap: var(--grid-gap);
  position: relative;

  &__producer-picture,
  &__model-picture {
    display: flex;
    width: 100%;
    position: relative;
    padding-bottom: 114/90 * 100%;
  }

  &__image-link {
    display: flex;
    width: 100%;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: contain;
    position: absolute;
  }

  &__model-name {
    padding: 10px;
    margin: -10px;
    transition: color .4s ease-in-out;
    display: flex;

    &:hover {
      color: var(--color-primary-dark);
    }
  }

  &__additional-name {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 133%;
    color: var(--color-dark);
    padding: 3px 8px;
    border-radius: 2px;
    background: var(--color-gray-bg);
  }

  &__additional-item {
    margin-top: 10px;

    &:not(:last-child) {
      margin-right: 10px;
    }
  }

  &__additional-list {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  &__season {
    margin-left: 3px;
    display: flex;
  }

  &__sub-name {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &__size-name {
    padding-top: 3px;
    color: var(--color-gray-text);
  }

  &__id {
    &:not(:first-child) {
      padding-top: 23px;
    }

    color: var(--color-gray-text);
    position: absolute;
    left: 20px;
    bottom: 20px;

    &:before {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      bottom: 2px;
      width: 100%;
      height: 1px;
      background: var(--color-gray-text);
    }
  }

  &__producer-wrap {
    grid-column: 1/2;
    padding-left: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__product-wrap {
    grid-column: 2/6;
    display: grid;
    grid-gap: var(--grid-gap);
    grid-template-columns: repeat(4, 1fr);
  }

  &__rrc {
    grid-column: 6/7;
  }

  &__name-block,
  &__producer-wrap,
  &__rrc,
  &__internal-price {
    padding-top: 6px;
  }

  &__avail-list {
    grid-column: 7/14;
  }

  &__image-block {
    grid-column: 1/2;
  }

  &__name-block {
    grid-column: 2/5;
  }
}

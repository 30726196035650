.cart-page {

  &__products-item {
    &:not(:last-child) {
      border-bottom: 1px solid var(--color-gray-border);
    }
  }

  &__products {
    border-radius: 2px;
    //overflow: hidden;
  }

  &__cart-wrapper {
  }

  &__sidebar {
  }

  &__sidebar-sticky {
    position: sticky;
    top: var(--grid-gap);
  }

  &__empty {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  &__empty-text {
    font-weight: 700;
    line-height: 133%;
  }

  &__empty-button-wrapper {
    display: flex;
  }

  &__icon {
    margin-right: 7px;
  }

  &__empty-text-subtitle {
    font-weight: 600;
    line-height: 120%;
    color: var(--color-gray-text);
    margin-bottom: 40px;
    text-align: center;
  }

  &__empty-button {
    display: flex !important;
    align-items: center;
    justify-content: center;
    width: 180px;

    &:first-child {
      margin-right: 28px;
    }
  }

  &__result-block {
  }
}

@include respond-up('large') {
  .cart-page {
    padding-bottom: 130px;

    &__cart-wrapper {
      grid-column: 1/10;
    }

    &__sidebar {
      grid-column: 10/13;
      height: 100%;
    }

    &__empty-text-subtitle {
      font-size: 20px;
    }
  }
}

@include respond-down('medium') {
  .cart-page {
    padding-bottom: var(--grid-gap);

    &__cart-wrapper {
      grid-column: 1/5;
    }

    &__sidebar {
      grid-column: 1/5;
    }

    &__empty {
      padding: 40px 0 20px;
    }

    &__empty-text-subtitle {
      font-size: 18px;
    }

    &__empty-button-wrapper {
      flex-direction: column;
      width: 100%;
    }

    &__empty-button.button {
      width: 100%;
      background-color: var(--primary-color);

      &:nth-child(n + 2) {
        margin-top: 12px;
      }

      &:hover {
        background-color: var(--primary-color);
      }
    }

    &__empty-button-text {
      color: white;
    }

    &__icon {
      path[stroke], circle[stroke] {
        stroke: white;
      }
    }
  }
}
.notifications-page {
  &__top {
    display: flex;
  }

  &__settings-wrapper {
    display: flex;
  }

  &__read-mark {
    color: var(--primary-color);
  }
}

@include respond-up('large') {
  .notifications-page {
    padding-bottom: 120px;

    &__top {
      justify-content: space-between;
    }

    &_sidebared {
      &.notifications-page_not-empty {
        .notifications-page__sidebar {
          padding-top: 52px;
        }
      }

      .notifications-page {
        &__content {
          grid-column: 1/10;
        }

        &__sidebar {
          grid-column: 10/13;
        }

        &__sidebar-sticky {
          position: sticky;
          top: var(--grid-gap);
        }
      }
    }

    &__settings-wrapper {
      align-items: center;
    }

    &__read-mark {
      margin-right: var(--grid-gap);
    }
  }
}

@include respond-down('medium') {
  .notifications-page {
    padding-bottom: 60px;

    &__top {
      flex-direction: column-reverse;
      row-gap: 20px;
    }

    &__tabs {
      overflow: scroll hidden;
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
      overflow: -moz-scrollbars-none; /* Firefox */

      &::-webkit-scrollbar {
        display: none;
      }

      section::-webkit-scrollbar {
        width: 0 !important
      }
    }

    &_sidebared {
      .notifications-page {
        &__content {
          grid-column: 1/5;
          grid-row: 1;
        }

        &__sidebar {
          grid-column: 1/5;
          grid-row: 2;
        }
      }
    }

    &__settings-wrapper {
      flex-direction: column-reverse;
    }

    &__read-mark {
      margin-top: var(--grid-gap);
      text-align: right;
    }
  }
}
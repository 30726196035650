.tree-condition {
  display: flex;
  align-items: center;
  width: max-content;

  &__select {
    width: 210px;
  }

  &__value-block {
    width: 300px;

    .vs__selected {
      background-color: #E5F6FF;
      color: #658fdc;
      font-weight: 500;border: none;
    }

    .vs__deselect {
      fill: #658fdc;
    }

    .vs__dropdown-option--selected {
      background-color: var(--color-gray-bg);

      &.vs__dropdown-option--highlight {
        background: var(--vs-dropdown-option--active-bg);
      }
    }

    input[type=search] {
      min-width: 75px;
    }

    &.vs--searchable {
      position: relative;
    }
  }

  &__value-input {
    border: var(--vs-border-width) var(--vs-border-style) var(--vs-border-color);
    color: var(--vs-selected-color);
    font-size: var(--vs-font-size);
    line-height: var(--vs-line-height);
    width: 300px;
    padding: 5px;
  }

  &__actions {
    display: flex;
    align-items: center;
    padding-left: 7px;
  }

  &__split-branch,
  &__remove-branch {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: 25px;
  }

  &__split-icon {
    width: 17px;
    height: 15px;
  }

  &__search-wrap {
    position: relative;
  }

  .lds-ring {
    display: inline-block;
    position: absolute;
    top: 50%;
    right: 1px;
    transform: translate3d(0, -50%, 0);
    width: 30px;
    height: 30px;
    background-color: #fff;
  }
  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 24px;
    height: 24px;
    margin: 3px;
    border: 3px solid var(--primary-color);
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: var(--primary-color) transparent transparent transparent;
  }
  .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.identify-external-repeats {
  &__list {
  }

  &__item {
    border-bottom: 1px solid var(--border-color);

  }

  &__header, &__link {
    display: grid;
    grid-template-columns: 230px 1fr 90px;
    padding: 15px 20px;
  }

  &__header {
    align-items: end;
  }

  &__link {
    background-color: white;
    color: var(--text-color-gray);
    transition: {
      property: background-color;
      duration: .3s;
    };

    &:hover {
      background-color: #F3F3F3;
    }
  }

  &__supplier {
  }

  &__product {
  }

  &__count {
    text-align: center;
  }

  &__form-list {
    display: flex;
    padding: 0 20px;
    width: 100%;
    overflow: auto;
  }

  &__form {
    padding: 0 20px;
  }

  &__form-inner {
    padding: 15px;
    background-color: white;
    border-radius: 6px;
    margin-top: 60px;
    margin-right: 20px;
    flex: 0 0 245px;
  }

  &__product-name {
    font-size: 18px;
    font-weight: bold;
    margin-top: 30px;
  }
}

.layout {
  display: flex;
  justify-content: space-between;
  min-height: 100vh;
  flex-direction: column;
  background-color: var(--color-gray-bg);

  &__wrapper {
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
  }

  &__main {
    flex: 1 0 auto;

    &_flex {
      display: flex;
      flex-direction: column;
    }
  }
}

@include respond-up('large') {
  .layout {
    &__header-mobile {
      display: none;
    }
  }
}

@include respond-down('medium') {
  .layout {
    &__header {
      display: none;
    }
  }
}
.catalog-category-tabs {
  &__list {
    display: flex;
  }

  &__link {
    white-space: nowrap;
  }
}

@include respond-up('large') {
  .catalog-category-tabs {

  }
}

@include respond-down('medium') {
  .catalog-category-tabs {
    width: max-content;
  }
}

.order-info {
  &__item {
    display: flex;
    border-bottom: 1px solid var(--color-gray-border);
  }

  &__item-name {
    font-weight: 400;
    line-height: 130%;
    color: var(--color-gray-text);
  }

  &__item-value {
    flex: 1 1 auto;
    font-weight: 400;
    line-height: 130%;
    color: var(--color-dark);
  }

  &__bottom {
    display: flex;
  }

  &__links {
    display: flex;
  }

  &__link {
    display: flex;
    align-items: center;
  }

  &__link-icon {
    display: flex;
    align-items: center;
  }

  &__link-text {
    font-weight: 600;
    line-height: 133%;
    color: var(--color-dark);
  }

  &__button {
    &._hidden {
      transition: opacity .5s;
      opacity: 0;
      pointer-events: none;
      display: none;
    }
  }
}

@include respond-up('large') {
  .order-info {
    &__item {
      padding: 12px 0;

      &:last-child {
        border-bottom: unset;
      }
    }

    &__item-name {
      flex: 0 0 317px;
      font-size: 15px;
    }

    &__item-value {
      font-size: 15px;
    }

    &__bottom {
      margin-top: 40px;
      justify-content: space-between;
      align-items: center;
    }

    &__links {
      align-items: center;
    }

    &__link {
      &:nth-child(2) {
        padding-left: 20px;
        margin-left: 20px;
        position: relative;

        &::before {
          content: '';
          position: absolute;
          height: 22px;
          width: 1px;
          left: 0;
          top: 0;
          background-color: #C4C4C4;
        }
      }
    }

    &__link-icon {
      margin-right: 7px;
    }

    &__link-text {
      font-size: 15px;
    }
  }
}

@include respond-down('medium') {
  .order-info {
    &__item {
      padding: 10px var(--grid-spacer);
      flex-direction: column;
      row-gap: 6px;

      &:first-child {
        padding-top: 0;
      }
    }

    &__item-name {
      font-size: 14px;
    }

    &__item-value {
      font-size: 14px;
    }

    &__bottom {
      margin-top: 20px;
      flex-direction: column;
      row-gap: 20px;
      padding: 0 var(--grid-spacer);
    }

    &__button {
      &.button {
        width: 100%;
      }
    }

    &__links {
      flex-direction: column;
      row-gap: 10px;
    }

    &__link-icon {
      margin-right: 4px;
    }

    &__link-text {
      font-size: 14px;
    }
  }
}
.index-advantages {
  &__picture {
    display: flex;
    position: relative;
    overflow: hidden;
    width: 100%;
    padding-bottom: calc(469 / 563 * 100%);
    border-radius: 2px;
  }

  &__image {
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__description {
    margin-top: 20px;
  }

  &__info-wrapper {
    display: grid;
  }

  &__h3 {
    color: var(--primary-color);
  }

  &__main {
    margin-top: 6px;
    color: var(--color-gray-text);
  }
}

@include respond-up('large') {
  .index-advantages {
    &__picture-wrapper {
      grid-column: 1/6;
    }

    &__text-wrapper {
      grid-column: 7/12;
    }

    &__info-wrapper {
      margin-top: 40px;
      grid-template-columns: repeat(2, var(--grid-column2));
      grid-column-gap: calc(60 / 28 * var(--grid-gap));
      grid-row-gap: 40px;
    }
  }
}

@include respond-down('medium') {
  .index-advantages {
    &.grid-block {
      grid-row-gap: 40px;
    }

    &__picture-wrapper,
    &__text-wrapper {
      grid-column: 1/5;
    }

    &__text-wrapper {
      grid-row: 1;
    }

    &__picture-wrapper {
      grid-row: 2;
    }

    &__info-wrapper {
      margin-top: 30px;
      grid-template-columns: repeat(2, var(--grid-column2));
      grid-column-gap: var(--grid-gap);
      grid-row-gap: 20px;
    }
  }
}
.cart-widget {
//  TODO transfer
}

.cart-widget-count {
  display: flex;

  &__count {
    background-color: var(--color-gray-text);
    flex: 0 0 15px;
    height: 15px;
    width: 15px;
    border-radius: 20px;
    color: white;
    font-size: 10px;
    font-weight: 600;
    line-height: 145%;
    letter-spacing: -0.4px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
}

.order-history-modal {
  width: calc(var(--grid-column6) - 80px);

  &__inner {
    max-width: calc(var(--grid-column5) - 40px);
  }

  &__list {
    margin-top: 20px;
  }

  &__item {
    display: flex;
    align-items: center;
    height: 48px;

    &:nth-child(even) {
      background-color: var(--color-gray-bg);
    }

    &:first-child {
      .order-history-modal__item-icon::before {
        display: none;
      }
    }

    &:last-child {
      .order-history-modal__item-icon::after {
        display: none;
      }
    }

    &._done {
      .order-history-modal {
        &__circle {
          border-color: var(--primary-color);
          background-color: var(--t);
        }

        &__item-icon {
          &::before {
            background-color: var(--primary-color);
          }

          &::after {
            background-color: var(--primary-color);
          }
        }
      }
    }

    &._done-last {
      .order-history-modal {
        &__item-icon {
          &::after {
            background-color: var(--color-gray-border);
          }
        }
      }
    }
  }

  &__circle {
    border: 3px solid var(--color-gray-border);
    background-color: var(--color-gray-border);
    border-radius: 100%;
    width: 10px;
    height: 10px;
  }

  &__item-icon {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: max-content;
    height: 100%;
    flex: 0 0 calc(var(--grid-column1) - 40px);

    &::before,
    &::after {
      content: '';
      position: absolute;
      left: 50%;
      transform: translate3d(-50%, 0, 0);
      width: 2px;
      height: calc(50% - 5px);
      background-color: var(--color-gray-border);
    }

    &::before {
      top: 0;
    }

    &::after {
      bottom: 0;
    }
  }

  &__item-date,
  &__item-name {
    padding: 15px 0 14px;
    flex: 0 0 var(--grid-column2);
    margin-left: var(--grid-gap);
  }

  &__description {
    margin-top: 20px;
  }
}

@include respond-up('large') {
  .order-history-modal {

  }
}

@include respond-up('medium') {
  .order-history-modal {

  }
}

@include respond('medium') {
  .order-history-modal {

  }
}

@include respond-down('medium') {
  .order-history-modal {

  }
}

@include respond-down('small') {
  .order-history-modal {

  }
}
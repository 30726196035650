.unloading-content {
  &__list {
    background-color: white;
    border-radius: 2px;
  }

  &__wrapper {
    display: grid;
    align-content: center;

    &:not(:last-child) {
      box-shadow: 0 1px 0 #CAC9CE;
    }
  }

  &__name {
    display: flex;
  }

  &__refresh {
    display: flex;
  }

  &__url {
    display: flex;

    svg {
      width: 20px;
      height: 20px;
    }
  }

  &__form {
    margin-top: var(--grid-gap);
    background-color: white;
  }
}



@include respond-up('large') {
  .unloading-content {

    &__name {
      grid-column: 1/4;
      transition: color 0.4s ease-in-out;
      margin-left: 30px;
    }

    &__refresh {
      grid-column: 4/6;
      align-items: center;
    }

    &__url {
      grid-column: 7/7;
      justify-self: end;
      align-items: center;
      margin-right: 30px;

      svg {
        path {
          transition: stroke 0.4s ease-in-out;
        }
      }
    }

    &__wrapper {
      grid-column-gap: var(--grid-gap);
      grid-template-columns: repeat(7, 1fr);
      height: 60px;
      align-content: center;

      &:hover {
        .unloading-content {
          &__name {
            color: var(--color-primary-dark);
          }

          &__url {
            svg {
              path {
                stroke: var(--color-primary-dark);
              }
            }
          }
        }
      }
    }
  }
}

@include respond-down('medium') {
  .unloading-content {
    &__wrapper {
      grid-template-columns: auto 1fr;
      padding: 16px var(--grid-spacer) 14px;
    }

    &__url {
      grid-column: 1/2;
      grid-row: 1/3;
      margin-right: 12px;
      svg {
        path {
          stroke: var(--primary-color);
        }
      }
    }

    &__name {
      grid-column: 2/3;
      grid-row: 1/2;
    }

    &__refresh {
      grid-column: 2/3;
      grid-row: 2/3;
    }
  }
}

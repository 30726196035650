.catalog-list-header {
  display: grid;
  grid-gap: var(--grid-gap);
  padding: 16px 0;
  border-bottom: 1px solid var(--color-gray-border);
  align-items: center;
  background-color: white;
  position: relative;
  grid-template-columns: 2fr 3fr 3fr 3fr 3fr 3fr 3fr 3fr 3fr 2fr 2fr 3fr 3fr;

  &__label {
    font-weight: 600;
    font-size: 13px;
    line-height: 145%;
    color: var(--color-dark);
    margin-left: 6px;
  }

  .incoming, .wholesale, .internal-price {
    display: none;
  }

  .producer {
    grid-column: 1/3;
    padding-left: 20px;
  }

  .name {
    grid-column: 3/6;
  }

  .rrc {
    grid-column: 6/7;
  }

  .storage {
    grid-column: 7/9;
  }

  .quantity {
    grid-column: 9/10;
  }

  .price {
    grid-column: 10/11;
  }

  .sell-price {
    grid-column: 11/12;
  }

  .order {
    grid-column: 12/14;
  }
}

@include respond-up('large') {
  .catalog-list-header {

  }
}

@include respond-down('medium') {
  .catalog-list-header {

  }
}
.one-c-offers {
  &__headers {
    background-color: hsla(252,5%,80%,.3);
  }

  &__headers, &__item {
    display: grid;
    grid-gap: var(--grid-gap);
    grid-template-columns: repeat(9, 1fr);
  }

  &__header, &__value {
    &:first-child {
      padding-left: var(--grid-spacer);
    }
    &:last-child {
      padding-right: var(--grid-spacer);
    }

    &_number {grid-column: 1/2}
    &_storage {grid-column: 2/5}
    &_quantity {grid-column: 5/6}
    &_incoming {grid-column: 7/8}
    &_wholesale {grid-column: 9/10}

    &_quantity, &_incoming, &_wholesale {
      text-align: center;
      justify-content: center;
    }
  }

  &__header {

  }

  &__list {

  }

  &__item {
    padding: 16px 0;
  }

  &__value {

  }
}

@include respond-up('large') {
  .one-c-offers {

  }
}


@include respond-down('medium') {
  .one-c-offers {

  }
}

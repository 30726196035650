.manager-filter-saves {
  &__list {
    background-color: white;
  }

  &__item {
    border-top: 1px solid var(--border-color);
    display: flex;
    padding: 15px var(--grid-gap);
    position: relative;
  }

  &__client-id {
    font-weight: bold;
    font-size: 14px;
    color: var(--color-gray-text);
    flex: 0 0 50px;
  }

  &__client-wrap {
    flex: 0 0 200px;
    display: flex;
    flex-direction: column;
  }

  &__client-name {
    font-size: 14px;
    color: var(--color-gray-text);
  }

  &__organization {
    font-size: 14px;
    color: var(--color-dark);
  }

  &__data {
    flex: 1 1 auto;
    padding-left: var(--grid-gap);
  }

  &__data-item {
    display: flex;
    padding: 5px 0;
    &:nth-child(n+2) {
      border-top: 1px solid var(--border-color);
    }
  }

  &__data-name {
    font-size: 14px;
    color: var(--color-gray-text);
    flex: 0 0 140px;
  }

  &__data-value {
    font-size: 14px;
    color: var(--color-dark);
    font-weight: bold;
  }

  &__events {
    flex: 0 0 200px;
    padding-left: var(--grid-gap);
    font-size: 12px;
    color: var(--color-gray-text);

    &:nth-child(n + 2) {
      padding-top: 5px;
    }
  }

  &__archive-wrap {
    display: flex;
    align-items: center;
    margin-top: 5px;
  }

  &__archive {
    flex: 0 0 auto;
    color: white;
    font-weight: bold;
    background-color: var(--primary-color);
    border-radius: 6px;
    padding: 2px 3px;
    //position: absolute;
    //left: var(--grid-gap);
    //bottom: 15px;
    font-size: 12px;
  }

  &__archive-date {
    font-size: 12px;
    color: var(--primary-color);
    padding-left: 5px;
  }

  &__headers {
    display: flex;
    align-items: center;
    background-color: var(--color-gray-bg);
    padding: 10px var(--grid-gap);
  }

  &__header {
    font-size: 14px;
    font-weight: bold;
    color: var(--color-dark);
    &_client {
      flex: 0 0 250px;
    }

    &_filter {
      flex: 1 1 auto;
      padding-left: var(--grid-gap);
    }

    &_events {
      flex: 0 0 200px;
      padding-left: var(--grid-gap);
    }
  }
}

@include respond-up('large') {
  .manager-filter-saves {

  }
}

@include respond-down('medium') {
  .manager-filter-saves {

  }
}

.catalog-filter-panel {
  display: flex;



  &._param {
    .catalog-filter-panel {
      &__opener-wrap {
        //flex: 0 0 calc(var(--grid-column5) - 40px);
        flex: 0 0 auto;
      }
    }
  }

  &__opener-wrap {
  }

  &__opener {
    display: flex;
    align-items: center;

    &._extend {
      .catalog-filter-panel {
        &__opener-line {
          &:nth-child(2) {
            height: 0;
            top: 50%;
          }
        }
      }
    }
  }

  &__opener-circle {
    position: relative;
    flex: 0 0 34px;
    height: 34px;
    width: 34px;
    border-radius: 100px;
    background-color: var(--primary-color);
    margin-right: 16px;
  }

  &__opener-line {
    position: absolute;
    background-color: white;

    &:nth-child(1) {
      width: 14px;
      height: 2px;
      top: calc(50% - 1px);
      left: calc(50% - 7px);
    }

    &:nth-child(2) {
      width: 2px;
      height: 14px;
      top: calc(50% - 7px);
      left: calc(50% - 1px);
      transition: {
        property: height, top;
        duration: .2s;
        timing-function: linear;
      };
    }
  }

  &__opener-text {
    color: var(--color-dark);
    font-weight: 600;
    font-size: 15px;
    line-height: 133%;
  }

  &__total {
    position: relative;
    flex: 0 0 auto;
    font-weight: 700;
    font-size: 16px;
    line-height: 125%;
    color: var(--primary-color);
  }
}

@include respond-up('large') {
  .catalog-filter-panel {
    align-items: center;
    justify-content: space-between;
    margin-top: 37px;

    &__opener-wrap {
      margin-right: var(--grid-gap);
    }

    &._auto {
      .catalog-filter-panel {
        &__opener-wrap {
          flex: 0 0 calc(var(--grid-column3) - 40px);
        }
      }
    }
  }
}

@include respond-down('medium') {
  .catalog-filter-panel {
    flex-direction: column-reverse;

    &__total {
      display: none;
    }

    &__opener-wrap {
      padding: 30px var(--grid-spacer) 0;
      margin: 30px 0 0;
      display: flex;
      justify-content: center;
      border-top: 1px solid var(--color-gray-border);
    }

    &._auto {
      .catalog-filter-panel {
        &__opener-wrap {
          display: none;
        }
      }
    }
  }
}
:root {
  --default-input-height: 44px;
  --errors-color: var(--alert-color);
}

.button, button {
  padding: 0;
  margin: 0;
  text-align: center;
  transition: var(--default-transition);
  display: inline-block;
  border: none;
  background: var(--primary-color);
  font-size: 16px;
  cursor: pointer;

  &.expand, &.wide {
    width: 100%;
  }

  &.height {
    height: var(--default-input-height);
  }
}

form {
  $inputs: 'input[type="text"], input[type="search"], input[type="password"]';

  .hint {
    font-size: 12px;
    color: #9f9f9f;
  }

  label {
    font-size: 12px;
    color: var(--color-gray-text);
    margin-bottom: 6px;
    display: block;
  }

  ul.errors {
    margin-top: 5px;
    list-style: none;
    color: var(--errors-color);
    font-weight: 400;
    font-size: 12px;
    line-height: 133%;

    li {
      &:not(:last-child) {
        margin-bottom: 2px;
      }
    }
  }

  .form-field {
    margin-bottom: 10px;
  }

  #{$inputs}, textarea, select {
    border: 1px solid var(--color-gray-border);
    transition: border-color var(--default-timing-function) var(--default-transition-time);
    border-radius: 2px;
    box-shadow: none;
    margin: 0;
    padding: 0 14px;
    width: 100%;
    display: block;
    appearance: none;
    font-family: var(--font);

    //styleName: Инпуты;
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0 30px white inset !important;
    }

    &::placeholder {
      color: var(--color-gray-text);
      font-size: 15px;
      font-family: var(--font);
      font-weight: 400;
      line-height: 20px;
    }

    &.invalid {
      border-color: var(--reddish-color);
    }
  }

  #{$inputs}, select {
    height: var(--default-input-height);
  }

  textarea {
    padding: 5px 14px;
  }


  input[type="checkbox"] + label {
    &::before {
      border-radius: 3px;
    }

    &::after {
      height: 19px;
      width: 19px;
      left: 0;
      top: 0;
      object-fit: contain;
      background: {
        position: center;
        size: 11px 9px;
        repeat: no-repeat;
        image: url('../images/svg/checkbox.svg');
      }
    }
  }

  input[type="checkbox"], input[type="radio"] {
    display: none;

    + label {
      position: relative;
      display: flex;
      align-items: center;
      cursor: pointer;
      margin: 5px 0;
      padding-left: 25px;
      line-height: 128%;
      font-weight: 400;
      font-size: 15px;
      color: var(--color-dark);

      &::before {
        content: '';
        position: absolute;
        width: 17px;
        height: 17px;
        background-color: white;
        left: 0;
        top: 0;
        border: 1px solid var(--color-gray-border);
        transition: {
          property: background-color, border-color;
          duration: .3s;
        };
      }

      &::after {
        content: '';
        position: absolute;
        opacity: 0;
        transition: {
          property: opacity;
          duration: .3s;
        };
      }
    }

    &:checked {
      + label {
        &::before {
          background-color: var(--primary-color);
          border-color: var(--primary-color);
        }

        &::after {
          opacity: 1;
        }
      }
    }

    &:disabled {
      + label {
        opacity: .7;
        color: var(--color-gray-text);
        &::before {
          background-color: var(--color-gray-bg);
        }
      }
      &:checked {
        + label {
          &::before {
            background-color: var(--color-gray-border);
            border-color: var(--color-gray-border);
          }
        }
      }
    }
  }


  input[type="radio"] + label {
    &::before {
      border-radius: 100px;
    }

    &::after {
      background-color: white;
      border-radius: 100px;
      height: 9px;
      left: 5px;
      top: 5px;
      width: 9px;
    }
  }

  input[type="checkbox"].checkbox-switch {
    + label {
      padding-left: 40px;

      &::before {
        width: 32px;
        height: 18px;
        border-radius: 8px;
      }

      &::after {
        opacity: 1;
        width: 14px;
        height: 14px;
        top: 3px;
        left: 3px;
        border-radius: 50px;
        background: {
          image: none;
          color: var(--color-gray-border);
        };
        transition: {
          property: background-color, transform;
        };
      }
    }

    &:checked {
      + label {
        &::before {
          border-color: var(--color-gray-border);
          background-color: white;
        }
        &::after {
          background-color: var(--primary-color);
          transform: translateX(14px);
        }
      }
    }
  }

  input[type='date'], input[type='datetime-local'] {
    position: relative;
    height: var(--default-input-height);
    border: 1px solid var(--color-gray-border);
    border-radius: 2px;
    box-shadow: none;
    display: block;
    font-family: var(--font);
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    margin: 0;
    padding: 0 14px;
    transition: border-color var(--default-timing-function) var(--default-transition-time);
    width: 100%;

    &::-webkit-calendar-picker-indicator {
      opacity: 0;
      height: 100%;
      width: 30px;
    }

    &::after {
      content: '';
      position: absolute;
      pointer-events: none;
      right: 18px;
      top: 10px;
      background-image: url('../images/svg/calendar.svg');
      background: {
        repeat: no-repeat;
        size: contain;
        position: center;
      };
      width: 24px;
      height: 24px;
    }
  }

  .checkbox-list {
    &__icon {
      margin-left: 6px;
      display: flex;
    }
  }
}

.form {
  &__field-wrapper {
  }

  &__field {

  }

  &__field-wrapper-dark {
    $inputs: 'input[type="text"], input[type="search"], input[type="password"]';

    #{$inputs}, textarea {
      border-color: var(--color-some-grey-backgorund);
      background-color: var(--color-some-grey-backgorund);
      color: var(--color-gray-border);

      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus,
      &:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 30px #555555 inset !important;
        -webkit-text-fill-color: var(--color-gray-border);
      }
    }
  }
}
.order-filter-form {
  &_client {
  }

  &_manager {
  }
}

@include respond-up('large') {
  .order-filter-form {
    &__field {
      padding: 28px var(--grid-spacer);
      background-color: white;
      display: flex;
      flex-wrap: wrap;

      .form-field {
        margin-right: 28px;
      }
    }
  }
}

@include respond-down('medium') {
  .order-filter-form {
    background: #fff;

    &__form {
      padding: var(--grid-spacer) var(--grid-spacer) 30px;
    }

    &__field {
      display: grid;
      grid-template-columns: repeat(2, calc(50% - 3px));
      grid-row-gap: 16px;
      grid-column-gap: 6px;

      .form-field {
        margin-bottom: 0;

        input {
          font-size: 14px;
        }

        &.state {
          grid-row: 1;
        }

        &.paid_state {
          grid-row: 2;
        }

        &.state,
        &.paid_state {
          grid-column: 1/3;
        }

        &.date_from {
          grid-column: 1/2;
        }

        &.date_to {
          grid-column: 2/3;
        }

        &.date_from,
        &.date_to {
          grid-row: 3;

          input[type=date] {
            &:after {
              width: 20px;
              height: 20px;
            }
          }
        }
      }
    }
  }
}
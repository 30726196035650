.auto-order-products {
  &__headers, &__item {
    display: grid;
    grid-template-columns: repeat(9, 1fr);
    grid-gap: var(--grid-gap);
  }

  &__header, &__value {
    &:first-child {
      padding-left: var(--grid-spacer);
    }

    &:last-child {
      padding-right: var(--grid-spacer);
    }

    &_product {
      grid-column: 1/5;
    }

    &:nth-child(n+2) {
      text-align: center;
      justify-content: center;
    }
  }

  &__headers {
    background-color: hsla(252,5%,80%,.3);
    padding: 16px 0;
  }

  &__header {

  }

  &__list {

  }

  &__item {
    padding: 10px 0;
    &:nth-child(n+2) {
      border-top: 1px solid var(--color-gray-border);
    }
  }

  &__header {

  }

  &__value {
    display: flex;
    align-items: center;
  }
}

@include respond-up('large') {
  .auto-order-products {

  }
}

@include respond-up('medium') {
  .auto-order-products {

  }
}

@include respond('medium') {
  .auto-order-products {

  }
}

@include respond-down('medium') {
  .auto-order-products {

  }
}

@include respond-down('small') {
  .auto-order-products {

  }
}
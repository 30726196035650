.order-checkout {
  &__item {
    display: flex;
    border-bottom: 1px solid var(--color-gray-border);
  }

  &__column {
    line-height: 145%;

    &_storage, &_price, &_quantity {
      font-weight: 400;
      color: var(--color-gray-text);
    }

    &_amount {
      font-weight: 700;
      color: var(--color-dark);
    }

    &_price {
      position: relative;
      display: flex;
      align-items: center;
    }
  }

  &__additional-prices {
    margin-left: 10px;
    position: relative;
  }

  &__product-title {
    font-weight: 700;
    line-height: 125%;
    color: var(--color-dark);
  }

  &__product-subtitle {
    display: flex;
    align-items: center;
  }

  &__product-id {
    color: var(--color-gray-text);
    margin-top: 6px;
  }

  &__product-subtitle-text {
    font-weight: 400;
    line-height: 145%;
    color: var(--color-gray-text);
  }

  &__product-subtitle-icon {
    display: flex;
    align-items: center;
  }

  &__positions {
    flex: 1 1 auto;
  }

  &__position {
    display: flex;
  }

  &__total-wrap {
    background-color: rgba(241, 241, 244, 0.7);
    display: flex;
    align-items: center;
    border-radius: 4px;
  }

  &__print-link {
    display: flex;
    align-items: center;
  }

  &__print-icon {
    display: flex;
    align-items: center;
  }

  &__print-text {
    font-weight: 600;
    line-height: 133%;
    color: var(--color-gray-text);
  }

  &__total {
    display: flex;
    align-items: center;
  }

  &__total-count {
    font-weight: 600;
    line-height: 145%;
    color: var(--color-dark);
  }

  &__total-price {
    font-weight: 700;
    line-height: 133%;
    color: var(--primary-color);
  }
}

@include respond-up('large') {
  .order-checkout {
    &__header {
      background-color: rgba(202, 201, 206, 0.3);
      padding: 17px var(--grid-spacer) 15px;
      display: flex;
    }

    &__header-text {
      font-weight: 400;
      font-size: 13px;
      line-height: 145%;
      color: var(--color-dark);
    }

    &__header-text, &__column {
      &_name {
        flex: 0 0 calc(var(--grid-column4) - 40px);
      }
      &_storage {
        flex: 0 0 calc(var(--grid-column2) + var(--grid-gap));
      }
      &_price, &_quantity, &_amount{
        flex: 0 0 calc(var(--grid-column1) + var(--grid-gap));
      }
    }

    &__item {
      padding: 24px 0 20px;
    }

    &__column {
      font-size: 13px;
    }

    &__product-title {
      font-size: 16px;
      margin-bottom: 3px;
    }

    &__product-subtitle-text, &__product-id {
      font-size: 13px;
    }

    &__product-subtitle-icon {
      margin-left: 5px;
    }

    &__total-wrap {
      padding: 21px 30px 20px 21px;
      margin-top: 20px;
      justify-content: flex-end;
    }

    &__print-icon {
      margin-right: 8px;
    }

    &__print-text {
      font-size: 15px;
    }

    &__total-count {
      font-size: 15px;
    }

    &__total-price {
      font-size: 20px;
      margin-left: 10px;
    }
  }
}

@include respond-down('medium') {
  .order-checkout {
    &__header {
      display: none;
    }

    &__container {
      max-width: unset;
      padding: 0;
      margin: 0;
    }

    &__item {
      padding: 20px var(--grid-spacer) 30px;
      flex-direction: column;

      &:first-child {
        border-top: 1px solid var(--color-gray-border);
      }
    }

    &__product-title {
      font-size: 15px;
      margin-bottom: 4px;
      text-decoration: underline;
    }

    &__product-subtitle-text, &__product-id {
      font-size: 12px;
      padding-left: 20px;
    }

    &__product-subtitle-icon {
      margin-left: 6px;
    }

    &__positions {
      margin-top: 20px;
      display: flex;
      flex-direction: column;
      row-gap: 10px;
    }

    &__position {
      display: grid;
      grid-column-gap: 5px;
      grid-template-areas:
        "storage quantity amount"
        "item-price item-price item-price";
    }

    &__column {
      font-size: 12px;

      &_storage {
        grid-area: storage;
      }

      &_quantity {
        grid-area: quantity;
        text-align: end;
      }

      &_amount {
        grid-area: amount;
        text-align: end;
      }

      &_price {
        grid-area: item-price;
      }
    }

    &__total-wrap {
      background: unset;
      padding: 0 var(--grid-spacer);
      margin-top: 20px;
    }

    &__total {
      padding: 8px 15px;
      background-color: rgba(241, 241, 244, 0.7);
      width: 100%;
      justify-content: center;
      border-radius: 4px;
    }

    &__print-icon {
      margin-right: 8px;
    }

    &__print-text {
      font-size: 15px;
    }

    &__total-count {
      font-size: 14px;
      line-height: 133%;
    }

    &__total-price {
      font-size: 16px;
      margin-left: 4px;
      line-height: 145%;
    }
  }
}
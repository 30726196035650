.mobile-menu {
  opacity: 0;
  pointer-events: none;

  z-index: 10000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;

  display: flex;
  flex-direction: column;

  &._active {
    opacity: 1;
    pointer-events: all;
  }

  &__top {
    background-color: white;
    box-shadow: 0 1px 15px 0 rgba(0, 0, 0, 0.10);
    width: 100%;
    flex: 0 0 auto;
  }

  &__top-inner {
    display: flex;
    align-items: center;
    height: 50px;
    justify-content: space-between;
  }

  &__closer {
    display: flex;
    align-items: center;
  }

  &__content {
    width: 100%;
    flex: 1 1 auto;
    overflow: auto;
  }

  &__footer {
    width: 100%;
    border-top: 1px solid var(--color-gray-border);
    flex: 0 0 auto;
    background-color: white;
  }

  &__footer-inner {
    padding: 30px 0 40px;
    display: flex;
    flex-direction: column;
  }

  &__address-wrapper {
    display: flex;
    align-items: flex-start;
  }

  &__address-img {
    margin-right: 12px;
    display: flex;
  }

  &__address {
    font-size: 15px;
    color: var(--color-gray-text);
  }

  &__phone {
    margin-bottom: 18px;
  }

  &__profile {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 19px var(--grid-spacer) 18px;
    background-color: var(--color-gray-bg);
  }

  &__logout {
    display: flex;
    align-items: center;
  }

  &__item {
    &:nth-child(n + 2) {
      margin-top: 5px;
    }
  }

  &__item-link {
    background-color: var(--color-gray-bg-dark);

    display: flex;
    align-items: center;
    padding: 0 var(--grid-spacer);
    height: 50px;
    font-style: normal;
  }

  &__item-text {
    font-size: 16px;
    line-height: 145%;

    &_main {
      font-weight: 700;
      line-height: 145%;
      text-transform: uppercase;
    }

    &_action {
      font-weight: 600;
    }
  }

  &__item-icon {
    display: flex;
    align-items: center;
    margin-right: 8px;

    svg {
      path[stroke] {
        stroke: var(--color-gray-text);
      }
    }
  }

  &__item-counter {


    margin-left: 7px;
  }

  &__childs {
    padding: 11px 0;
  }

  &__child {

  }

  &__child-link {
    display: flex;
    padding: 9px var(--grid-spacer) 9px 52px;
    font-size: 16px;
    font-weight: 600;
    line-height: 150%;
  }
}

@include respond-up('large') {
  .mobile-menu {
  }
}

@include respond-down('medium') {
  .mobile-menu {

  }
}

.text {
  color: var(--color-dark);
  font-family: var(--font);

  &__subheading {
    //styleName: Подзаголовок;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
  }

  &__card-heading {
    //styleName: Заголовок на карточке;
    font-weight: 700;
  }

  &__paragraph {
    //styleName: Важный абзац;
    font-weight: 600;
  }

  &__main {
    //styleName: Основной текст;
    font-weight: 400;
  }

  &__medium {
    //styleName: Кнопочный medium;
    font-size: 15px;
    font-weight: 600;
    line-height: 20px;
  }

  &__small {
    //styleName: Мелкая подпись;
    font-weight: 400;

    &_bold {
      font-weight: 700;
    }
  }

  &_grey {
    color: var(--color-gray-text);
  }

  &_white {
    color: white;
  }

  &_bg-grey {
    color: var(--color-grey-backgorund);
  }
}

@include respond-up('large') {
  .text {
    &__card-heading {
      //styleName: Заголовок на карточке;
      font-size: 16px;
      line-height: 20px;
    }

    &__small {
      //styleName: Мелкая подпись;
      font-size: 13px;
      line-height: 19px;
    }

    &__paragraph {
      //styleName: Важный абзац;
      font-size: 18px;
      line-height: 26px;
    }

    &__main {
      //styleName: Основной текст;
      font-size: 16px;
      line-height: 24px;
    }
  }
}

@include respond-down('medium') {
  .text {
    &__card-heading {
      //styleName: Заголовок на карточке;
      font-size: 15px;
      line-height: 125%;
    }

    &__small {
      //styleName: Мелкая подпись;
      font-size: 12px;
      line-height: 145%;
    }

    &__paragraph {
      //styleName: Важный абзац;
      font-size: 17px;
      line-height: 145%;
    }

    &__main {
      //styleName: Основной текст;
      font-size: 15px;
      line-height: 150%;
    }
  }
}
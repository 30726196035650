.cart-position {
  display: flex;

  &__wrapper {
  }

  &__blocked {
    display: flex;
    align-items: center;
  }

  &__blocked-text {
    font-weight: 400;
    font-size: 12px;
    line-height: 133%;
    margin-left: 7px;
    color: var(--color-gray-text);
  }

  &__remove-wrap {
    flex: 0 0 var(--grid-column1);
    display: flex;
    justify-content: center;
  }

  &__remove-icon {
    padding: 5px;
    margin: -5px;
    display: flex;
  }

  &__price-number,
  &__price-measure {
    color: var(--color-gray-text);
    flex: 0 0 auto;
  }


  &__price-block {
    display: flex;
    align-items: center;
    position: relative;
  }

  &__additional_prices {
    position: absolute;
  }

  &__price-input {
    border: 1px solid var(--color-gray-border);
    border-radius: 2px;
    width: 64px;
    flex: 0 0 64px;
    padding: 3px 6px;
  }

  &__price-measure {
    line-height: 145%;
    margin-left: 6px;
  }

  &__panel {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  &__total {
    font-weight: 700 !important;
    color: var(--color-dark);
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  &__storage {
  }

  &__prices-wrap {
  }
}

@include respond-up('large') {
  .cart-position {
    &:hover {
      .cart-position {
        &__remove-icon {
          opacity: 1;
        }
      }
    }

    &__remove-icon {
      opacity: 0;
      transition: opacity .4s ease-in-out;

      svg {
        path {
          transition: stroke .4s ease-in-out;
        }
      }

      &:hover {
        svg {
          path {
            stroke: var(--color-primary-dark);
          }
        }
      }
    }

    &__wrapper {
      display: flex;
      flex: 1 1 auto;
    }

    &__storage {
      flex: 0 0 var(--grid-column2);
    }

    &__prices-wrap {
      display: flex;
      justify-content: space-between;
      flex: 0 0 100%;
    }

    &__panel {
      flex: 0 0 91px;
    }

    &__total {
      flex: 0 0 75px;
    }

    &__price-block {
      justify-content: flex-end;
      flex: 0 0 75px;
    }

    &__storage {
      color: var(--color-gray-text);
    }

    &__additional_prices {
      right: -14px;
      bottom: 8px;
    }
  }
}

@include respond-down('medium') {
  .cart-position {
    position: relative;

    &__remove-wrap {
      position: absolute;
      bottom: 0;
      right: 0;
    }

    &__wrapper {
      flex: 0 0 100%;
      display: flex;
    }

    &__storage {
      grid-area: storage;
      color: var(--color-dark);
    }

    &__panel {
      grid-area: panel;
    }

    &__total {
      grid-area: total;
    }

    &__price-block {
      grid-area: price;
      margin-top: 15px;
    }

    &__prices-wrap {
      flex: 0 0 100%;
      display: grid;
      grid-gap: 0 var(--grid-gap);
      grid-template-columns: 1fr 91px 75px;
      grid-template-areas:
        "storage panel total"
        "price price price";
    }

    &__additional_prices {
      left: -14px;
      bottom: 5px;
    }
  }
}
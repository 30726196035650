.order-list {
  &__list {
    background-color: white;
  }

  &__empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__empty-subtitle {
    color: var(--color-gray-text);
  }

  &__item {
    border-top: 1px solid var(--color-gray-border);
    transition: {
      property: box-shadow, border-color;
      duration: var(--default-transition-time);
    };

    &._opened {
      .order-item {
        &__accordion-link {
          svg {
            path {
              &:last-child {
                transform: rotate(90deg);
              }
            }
          }
        }
      }
    }
  }
}

@include respond-up('large') {
  .order-list {
    &__header {
    }

    &__empty {
      padding: 113px 0 247px;
    }

    &__empty-subtitle {
      margin-top: 10px;
    }

    &__item {
      &:first-child {
        border-top: 1px solid transparent;
      }

      &._opened {
        &:hover {
          box-shadow: unset;

          & + .order-list__item {
            border-top-color: var(--color-gray-border);
          }
        }

        .order-item {
          &__link {
            box-shadow: 0 1px 7px 0 rgba(0, 0, 0, 0.10);
          }
        }
      }

      &:hover {
        border-top-color: transparent;
        box-shadow: 0 1px 20px rgba(0, 0, 0, 0.1);

        & + .order-list__item {
          border-top-color: transparent;
        }

        .order-item {
          &__arrow {
            opacity: 1;
          }
        }
      }
    }

    &_client {
      .order-item {
        &__link {
          grid-template-columns: repeat(9,1fr);
        }

        &__value {
          &_id {
            grid-column: 1/2;
          }
          &_date {
            grid-column: 2/3;
          }
          &_paid {
            grid-column: 4/5;
          }
          &_sum {
            grid-column: 8/9;
          }
        }

        &__value-wrapper {
          grid-column: 6/8;
        }
      }
    }

    &_manager {
      .order-item {
        &__link {
          grid-template-columns: repeat(10,1fr);
        }
        &__value {
          &_id { grid-column: 1/2; }
          &_date { grid-column: 2/3; }
          &_client { grid-column: 3/5; }
          &_manager { grid-column: 5/7; }
          &_sum { grid-column: 9/10; }
          &_paid { grid-column: 7/8; }
          &-wrapper { grid-column: 8/9; }
        }
      }
    }
  }
}

@include respond-down('medium') {
  .order-list {
    margin: 20px calc(-1 * var(--grid-spacer)) 0;
    padding: 0 var(--grid-spacer);

    overflow: scroll hidden;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    overflow: -moz-scrollbars-none; /* Firefox */
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    &::-webkit-scrollbar {
      display: none;
    }

    section::-webkit-scrollbar {
      width: 0 !important
    }

    &_client {
      .order-list {
        &__headers,
        &__item {
          min-width: 585px;
        }
      }
    }

    &_manager {
      .order-list {
        &__headers,
        &__item {
          min-width: 948px;
        }
      }
    }

    &__list {
    }

    &__item {
      background: white;
    }

    &__empty {
      padding: 80px var(--grid-spacer) 120px;
      text-align: center;
    }
  }
}
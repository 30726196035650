.markup-item {
  position: relative;

  &._picking {
    .markup-item__inner {
      position: absolute;
      z-index: 200;
      box-shadow: 0 0 10px #CAC9CE;
      opacity: .8;
    }
  }

  &._picking-after {
    box-shadow: 0 -3px 1px var(--primary-color);
    z-index: 100;
  }

  &._picking-before {
    box-shadow: 0 3px 1px var(--primary-color);
    z-index: 100;
  }

  &__inner {
    display: flex;
    background-color: white;
    align-items: center;
    padding: 18px 40px 18px 30px;
    position: relative;
    transition: {
      property: box-shadow, opacity;
      duration: var(--default-transition-time);
    };
  }

  &__sorter {
    position: absolute;
    top: 0;
    height: 100%;
    left: 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
  }

  &:nth-child(n + 2) {
    border-top: 1px solid var(--color-gray-border);
  }

  &__first-block {
    flex: 0 0 70px;
    padding-right: var(--grid-gap);
  }

  &__status {
    color: var(--reddish-color);
  }

  &__id {
    color: var(--color-gray-text);
    font-size: 14px;
  }

  &__provider {
    font-size: 16px;
    line-height: 150%;
    color: var(--color-dark);
    font-weight: bold;
  }

  &__category {
    font-size: 15px;
    line-height: 150%;
    color: var(--color-gray-text);
    font-weight: bold;
  }

  &__provider-block {
    flex: 0 0 200px;
    padding-right: var(--grid-gap);
  }

  &__name {
    flex: 1 1 auto;
    padding-right: var(--grid-gap);
  }

  &__dates {
    display: flex;
    flex-direction: column;
    flex: 0 0 auto;
    padding-right: var(--grid-gap);
  }

  &__date {
    display: flex;
    align-items: center;
    font-size: 12px;
    color: var(--color-gray-text);

    &:nth-child(n + 2) {
      margin-top: 10px;
    }
  }

  &__date-name {
    flex: 0 0 auto;
    padding-right: 4px;
  }

  &__date-value {
    flex:  0 0 auto;
  }

  &__actions {
    display: flex;
    align-items: center;
  }

  &__action {
    display: flex;
    align-items: center;
    margin-left: 10px;

    svg path, svg rect {
      transition: {
        property: stroke;
        duration: var(--default-transition-time);
      };
    }

    &:hover {
      svg path, svg rect{
        stroke: var(--primary-color);
      }
    }
  }
}


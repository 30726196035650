.change-password-form {
  background-color: white;
  border-radius: 2px;
  position: relative;

  &__title {
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    color: var(--color-dark);
  }

  &__info {
    display: flex;
    flex-direction: column;
  }

  &__description {
    color: var(--color-gray-text);
  }

  &__forgot-link {
    display: flex;
  }

  &__forgot-title {
    color: var(--color-primary-dark);
  }
}

@include respond-up('large') {
  .change-password-form {
    padding: 40px var(--grid-spacer) 50px;

    &__title {
      font-size: 24px;
    }

    &__main {
      margin-top: 40px;
      display: grid;
      grid-template-columns: repeat(7, 1fr);
      grid-gap: var(--grid-gap);
    }

    &__fields {
      grid-column: 1/5;
    }

    &__info {
      grid-column: 5/8;
      align-items: flex-end;
      justify-content: space-between;
    }

    &__forgot-wrap {
      padding-left: calc(var(--grid-column2) - 40px);
      margin-bottom: 10px;
    }

    &__forgot-link {
      padding: 10px;
      margin: 0 -10px;
    }

    .form {
      &__field {
        display: flex;
        align-items: center;
        justify-content: space-between;

        label {
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 150%;
          color: var(--color-gray-text);
        }

        input[type=text],
        input[type=password] {
          width: calc(var(--grid-column2) + var(--grid-gap));
        }

        &:not(:nth-child(2)):not(:last-child) {
          margin-bottom: 28px;
        }

        &:nth-child(2) {
          margin-bottom: 8px;
        }
      }
    }
  }
}

@include respond-down('medium') {
  .change-password-form {
    padding: 20px 20px 40px;

    &__title {
      font-size: 23px;
    }

    &__main {
      margin-top: 18px;
    }

    &__fields-wrap {
      display: flex;
      flex-direction: column;
      row-gap: 16px;
    }

    &__forgot-link {
      padding: 20px;
      margin: 0 -20px;
    }

    &__info {
      margin-top: 20px;
      row-gap: 30px;
    }
  }
}

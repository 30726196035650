.notify-settings-page {
  &__title {
    color: var(--color-dark);
    margin-left: 5px;
    transition: color var(--default-transition-time) var(--default-timing-function);
  }

  &__grid {
  }

  &__label, &__form-name, &__point {
    grid-column: 1/4;
  }

  &__fields {
    .vue-field_switch {
    }

    .checkbox-list__item {
      margin-bottom: 12px;
    }
  }

  &__button {
    grid-column: 4/6;
    padding-left: 0;
    padding-right: 0;
  }

  &__label {
  }

  &__text-input {
    flex: 0 0 329px;
    width: 100%;
  }

  &__form {
    padding: 30px 0;

    &_order, &_product {
      border-top: 1px solid var(--color-gray-border);
    }
  }

  &__form-name {
    font-weight: 700;
    line-height: 120%;
  }

  &__point {
    display: flex;
  }

  &__point-number {
    border: 1px solid rgba(255, 124, 29, 0.5);
    color: var(--primary-color);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    width: 24px;
    height: 24px;
    flex: 0 0 24px;
  }

  &__point-text {
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
  }

  &__tabs {
    margin-bottom: 20px;
  }
}


@include respond-up('large') {
  .notify-settings-page {
    width: calc(var(--grid-column8) - var(--grid-spacer) * 2);

    &__grid {
      display: grid;
      grid-template-columns: var(--reduce-column) repeat(6, 1fr) var(--reduce-column);
      grid-gap: var(--grid-gap);
      margin-bottom: 24px;
    }


    &__form-name {
      font-size: 20px;
      margin-bottom: 32px;
    }

    &__fields {
      grid-column: 4/8;

      .vue-field_switch {
        margin-bottom: 27px;
      }
    }
  }
}

@include respond-down('medium') {
  .notify-settings-page {
    &__grid {
      display: flex;
      flex-direction: column;
    }

    &__label {
      margin-bottom: var(--grid-gap);
    }

    &__form-name {
      font-size: 18px;
      margin-bottom: 20px;
    }

    &__fields {
      .vue-field_switch {
        margin-bottom: var(--grid-gap);
      }

      .checkbox-list, .vue-field_select {
        margin-bottom: 30px;
      }
    }

    &__point {
      margin-bottom: var(--grid-gap);
    }
  }
}
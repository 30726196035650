.identify-form-list {
  padding-top: 40px;

  &__title {
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 20px;
  }

  &__items {
    display: grid;
    grid-gap: 40px;
    grid-template-columns: 1fr 1fr;
  }

  &__item {

  }
}

.personal-data-form {
  background-color: white;
  border-radius: 2px;

  &__form {
    position: relative;
    display: flex;
    flex-direction: column;
  }

  &__title {
    font-weight: 700;
    line-height: 120%;
  }

  &__button {
    display: flex;
  }
}

@include respond-up('large') {
  .personal-data-form {
    padding: 40px var(--grid-spacer);

    &__title {
      font-size: 24px;
      margin-bottom: 28px;
    }

    &__fields {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 28px;
    }

    &__last-fields-wrapper {
      margin-top: 28px;
      display: grid;
      grid-template-columns: 65% 1fr;
      grid-gap: 28px;
    }

    &__button {
      grid-column: 2/3;
      justify-content: flex-end;
      align-self: flex-end;
    }
  }
}

@include respond-down('medium') {
  .personal-data-form {
    padding: 20px 20px 40px;

    &__title {
      font-size: 23px;
    }

    &__form {
      margin-top: 18px;
    }

    &__fields,
    &__last-fields-wrapper {
      display: flex;
      flex-direction: column;
      row-gap: 16px;
    }

    &__last-fields-wrapper {
      margin-top: 16px;
    }

    &__button {
      .button {
        width: 100%;
        background: var(--color-gray-bg-dark);
        color: var(--color-gray-text);
      }
    }
  }
}
.images-field {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 30px;
    height: 100%;

    &:hover {
      svg {
        path {
          fill: var(--primary-color);
        }
      }
    }

    svg {
      height: 100%;
      width: 100%;
      path {
        transition: fill .4s;
        fill: var(--color-gray-text);
      }
    }
  }

  &__input {
    display: none;
  }



  &__list {
    display: flex;
  }

  &__errors {
    li {
      white-space: nowrap;
    }
  }

  &__item {
    display: flex;
    flex: 0 0 60px;
    width: 60px;
    padding: 10px 5px;
    position: relative;
  }

  &__link {
    display: flex;
    flex: 0 0 50px;
    width: 50px;
    height: 50px;
    border-radius: 5px;
    border: 1px solid var(--border-color);
    overflow: hidden;
  }

  &__image {
    flex: 0 0 100%;
    width: 100%;
    height: 100%;

    &_img {
      object-fit: cover;
      object-position: center;
    }

    &_pdf {
      background: {
        image: url('../images/svg/pdf.svg');
        position: center;
        size: 40px 40px;
        repeat: no-repeat;
      };
    }
  }

  &__closer {
    position: absolute;
    border-radius: 20px;
    background-color: white;
    border: 1px solid var(--primary-color);
    height: 16px;
    right: -1px;
    top: 3px;
    width: 16px;
    transition: {
      property: background-color, border-color;
      duration: .3s;
    };

    &:hover {
      background-color: var(--primary-color);

      &::before, &:after {
        background-color: white;
      }
    }

    &::before, &::after {
      content: '';
      position: absolute;
      width: 8px;
      height: 1px;
      transform-origin: center;
      background-color: var(--primary-color);
      top: 7px;
      left: 3px;
      transition: {
        property: background-color;
        duration: .3s;
      };
    }

    &::before {
      transform: rotate(45deg);
    }

    &::after {
      transform: rotate(-45deg);
    }
  }
}

@include respond-up('large') {
  .images-field {
    height: var(--default-input-height);

    &__list-wrap {
      position: absolute;
      top: calc(100% + 6px);
      background-color: white;
      left: -5px;
      padding: 0 5px;
    }
  }
}

@include respond-up('medium') {
  .images-field {

  }
}

@include respond('medium') {
  .images-field {
  }
}

@include respond-down('medium') {
  .images-field {

  }
}

@include respond-down('small') {
  .images-field {
    flex-direction: column;
    padding-bottom: 10px;
  }
}
.uikit-page {
  &__list {
    margin-top: 10px;
    margin-bottom: 30px;
  }

  &__element-wrapper {
    grid-column: span 6;
    margin-top: 20px;
    margin-bottom: 20px;

    &_wide {
      grid-column: span 12;
    }
  }

  &__description {
    color: white;
    background: var(--color-dark);
    padding: 16px;
    margin-top: 10px;
  }
}
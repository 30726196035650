.stat-filter-item {
  display: flex;
  width: 100%;

  &__info-wrap {
    flex: 0 0 400px;
  }

  &__info-item {
    display: flex;
    padding: 5px 0;
    &:nth-child(n + 2) {
      border-top: 1px solid var(--border-color);
    }
  }

  &__info-name {
    flex: 0 0 80px;
    color: var(--color-gray-text);
    font-size: 14px;
  }

  &__info-value {
    flex: 1 1 auto;
    color: var(--color-dark);
    font-size: 14px;
    font-weight: bold;
  }

  &__info-line {
    flex: 0 0 100%;
    color: var(--color-dark);
    font-size: 14px;
  }

  &__filter-data {
    flex: 1 1 auto;
    padding-left: var(--grid-gap);
    margin-left: var(--grid-gap);
    border-left: 1px solid var(--border-color);
  }

  &__data-item {
    display: flex;
    padding: 5px 0;
    &:nth-child(n + 2) {
      border-top: 1px solid var(--border-color);
    }
  }

  &__data-name {
    flex: 0 0 100px;
    color: var(--color-gray-text);
    font-size: 14px;
  }

  &__data-value {
    flex: 1 1 auto;
    color: var(--color-dark);
    font-size: 14px;
    font-weight: bold;
  }

  &__count-wrap {
    flex: 0 0 100px;
    margin-right: var(--grid-gap);
    text-align: center;
    padding-right: var(--grid-gap);
    border-right: 1px solid var(--border-color);
  }

  &__count-label {
    color: var(--color-gray-text);
    font-size: 14px;
  }

  &__count {
    color: var(--color-dark);
    font-size: 20px;
    font-weight: bold;
    padding: 10px;
  }
}

.catalog-filter {
  background-color: white;
  margin-bottom: 40px;
  position: relative;

  &__top {
    display: flex;
    margin-bottom: 30px;
    justify-content: space-between;
  }

  &__tabs-wrapper {
    display: flex;
  }
}

@include respond-up('large') {
  .catalog-filter {
    padding: 40px var(--grid-spacer);

  }
}

@include respond-down('medium') {
  .catalog-filter {
    margin: 0 var(--grid-spacer) 30px;
    padding: 30px 0;

    &__top {
      padding: 0 var(--grid-spacer);
      //display: none;
    }
  }
}
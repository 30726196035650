.catalog-filter-collection {
  flex: 0 0 auto;
  display: flex;

  &__buttons-wrap {
    display: flex;
  }

  &__link {
    flex: 0 0 auto;
    display: flex;
    align-items: center;

    &:nth-child(n + 2) {
      position: relative;
    }

    &:hover {
      .catalog-filter-collection {
        &__link-list {
          opacity: 1;
          pointer-events: visible;
        }
      }
    }
  }

  &__link-icon {
    display: flex;
    align-items: center;
    margin-right: 5px;
  }

  &__link-text {
    font-weight: 600;
    font-size: 15px;
    line-height: 133%;
    color: var(--color-dark);
  }

  &__link-list {
    position: absolute;
    top: 100%;
    left: 20px;
    right: 2px;
    opacity: 0;
    pointer-events: none;
    display: flex;
    flex-direction: column;
    z-index: 100;
    box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
    transition: opacity var(--default-transition-time) var(--default-timing-function);
  }

  &__link-item {
    padding: 10px 15px;
    font-weight: 600;
    font-size: 15px;
    line-height: 133%;
    color: var(--color-dark);
    transition: color var(--default-transition-time) var(--default-timing-function);
    background-color: white;

    &:hover {
      color: var(--primary-color);
    }

    &:nth-child(n + 2) {
      border-top: 1px solid var(--border-color);
    }
  }

  &__modal-form {
    max-width: 368px;
  }

  &__title {
    font-weight: 700;
    font-size: 24px;
    line-height: 120%;
    text-align: center;
    color: var(--color-dark);
    margin-bottom: 10px;
  }

  &__subtitle {
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    text-align: center;
    color: var(--color-gray-text);
    margin-bottom: 20px;
  }

  &__input {

  }

  button {
    &.catalog-filter-collection__button {
      width: 100%;
      margin-top: 20px;
    }
  }

  &__tabs {
    justify-content: center;
    margin: 10px 0 30px;
  }

  &__empty {
    line-height: 44px;
    text-align: center;
    color: var(--color-gray-text);
    font-weight: bold;
  }

  &__comment, &__delete-at {
    margin-top: var(--grid-gap);
  }

  &__comment {
    resize: none;
  }
}

@include respond-up('large') {
  .catalog-filter-collection {
    justify-content: flex-end;

    &__buttons-wrap {
      justify-content: flex-end;
    }

    &__link {
      &:nth-child(n + 2) {
        padding-left: 20px;
        margin-left: 20px;

        &::before {
          content: '';
          position: absolute;
          height: 22px;
          width: 1px;
          top: calc(50% - 11px);
          left: 0;
          background-color: var(--color-gray-border);
        }
      }
    }
  }
}

@include respond-down('medium') {
  .catalog-filter-collection {
    padding: 0 var(--grid-spacer);
    margin-top: 20px;

    &__buttons-wrap {
      flex-direction: column;
    }

    &__link {
      &:nth-child(n + 2) {
        margin-top: 10px;
      }
    }
  }
}
.catalog-auto-filter {
  &__top {
    display: flex;
  }

  &__fields {
    display: flex;
  }

  &__field {
  }

  &__button-wrapper {
  }

  &__button {
  }
}
@include respond-up('large') {
  .catalog-auto-filter {
    &__top {
      align-items: flex-end;
    }

    &__fields {
      align-items: center;
      flex: 1 1 auto;
    }

    &__field {
      flex: 1 1 auto;
      margin-right: 28px;
    }

    &__button-wrapper {
      flex: 0 0 auto;
    }

    &__button {
      width: 200px;
    }
  }
}

@include respond-down('medium') {
  .catalog-auto-filter {
    &__top {
      flex-direction: column;
      padding: 0 var(--grid-spacer);
    }

    &__fields {
      flex-direction: column;
    }

    &__field {
      margin-bottom: var(--grid-gap);
    }

    &__button-wrapper {
      padding-top: calc(30px - var(--grid-gap));
    }

    &__button {
      width: 100%;
    }
  }
}
.success-form {
  padding-top: 20px;
  padding-bottom: 50px;

  &._inline {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 10;
    opacity: 0;
    pointer-events: none;
    transition: opacity var(--default-transition-time) var(--default-timing-function);
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0;
    background-color: white;
  }

  &._need-remove {
    .success-form__circle {
      &_big {
        transform: scale(.5);
      }

      &_middle {
        transform: scale(.5);
      }

      &_small {
        transform: scale(1.5);
      }
    }
  }

  &__icon-wrap {
    display: flex;
    justify-content: center;
  }

  &__circle-wrap {
    height: 60px;
    width: 60px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 100;
  }

  &__circle {
    position: absolute;
    border-radius: 100px;
    transition: {
      property: transform;
      timing-function: ease-in-out;
    };
    &_big {
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      opacity: .3;
      background-color: rgba(255, 124, 29, 0.5);
      transition-delay: .1s;
      transition-duration: .5s;
    }

    &_middle {
      left: 4px;
      top: 4px;
      width: 52px;
      height: 52px;
      background-color: white;
      transition-duration: .5s;
      transition-delay: .2s;
    }

    &_small {
      left: 10px;
      top: 10px;
      width: 40px;
      height: 40px;
      background-color: rgba(255, 137, 29, 0.8);
      transition-duration: .5s;
    }
  }

  &__heading {
    margin-top: 20px;
  }

  &__title {

  }

  &__description {
    margin-top: 10px;
  }

  &__heading {
    text-align: center;
  }

  &__link {
    margin-top: 20px;
    padding: 10px;
    display: flex;
    justify-content: center;

    &:hover {
      .success-form__arrow {
        transform: translateX(5px);
      }
    }

    svg {
      width: 100%;
      height: auto;
      vertical-align: middle;
    }
  }

  &__link-text {
    color: var(--color-primary-dark);
  }

  &__arrow {
    margin-left: 5px;
    width: 30px;
    height: 20px;
    transition: transform .4s ease-in-out;
  }
}

form.success .success-form._inline {
  opacity: 1;
  pointer-events: all;

  &._need-remove {
    .success-form__circle {
      &_big {
        transform: scale(1);
      }

      &_middle {
        transform: scale(1);
      }

      &_small {
        transform: scale(1);
      }
    }
  }
}


@include respond-up('large') {
  .success-form {
    width: 368px;
  }
}

@include respond-down('medium') {
  .success-form {

  }
}
.profile-content {
  display: grid;
  grid-template-columns: 1fr;

  &__telegram {
    margin-top: var(--grid-gap);
  }
}

@include respond-up('large') {
  .profile-content {
    grid-gap: 28px;
  }
}

@include respond-down('medium') {
  .profile-content {
    grid-gap: 20px;
  }
}

.additional-prices {
  z-index: 200;
  display: flex;
  align-items: flex-end;

  &__icon {
    cursor: pointer;
    display: flex;
    align-items: flex-end;

    svg {
      circle {
        transition: {
          property: fill;
          duration: var(--default-transition-time);
        };
      }
    }
  }

  &__prices-block {
    opacity: 0;
    pointer-events: none;
    position: absolute;
    transition: {
      property: opacity;
      duration: var(--default-transition-time);
    };
    background-color: var(--color-primary-white);
    left: 16px;
    bottom: -10px;
    border-radius: 3px;
    padding: 8px 10px;

    &::before {
      content: '';
      position: absolute;
      cursor: pointer;
      left: -10px;
      bottom: 10px;
      border: 5px solid var(--t);
      border-right-color: var(--color-primary-white);
    }
  }

  &__item {
    &:nth-child(n + 2) {
      margin-top: 6px;
      padding-top: 6px;
      border-top: 1px solid rgba(44, 43, 46, 0.1);
    }
  }
  &__name {
    color: var(--color-dark);
    font-size: 11px;
    font-weight: 600;
    line-height: 120%;
    white-space: nowrap;
  }

  &__value {
    color: var(--color-gray-text);
    font-size: 11px;
    font-weight: 400;
    line-height: 145%;
    white-space: nowrap;
  }

  &:hover {
    .additional-prices {
      &__prices-block {
        opacity: 1;
        pointer-events: auto;
      }

      &__icon {
        svg {
          circle {
            fill: var(--color-gray-text);
          }
        }
      }
    }
  }
}

@include respond-up('large') {
  .additional-prices {
    &__prices-block {

    }
  }
}

@include respond-up('medium') {
  .additional-prices {

  }
}

@include respond('medium') {
  .additional-prices {

  }
}

@include respond-down('medium') {
  .additional-prices {

  }
}

@include respond-down('small') {
  .additional-prices {

  }
}
.stat-table {
  background-color: white;
  padding: 20px 20px 0;
  border: 2px solid var(--color-gray-bg);

  &._loading {
    .stat-table {
      &__content {
        min-height: 200px;
      }
    }
  }

  &__title {
    font-size: 18px;
    font-weight: 700;
    line-height: 120%;
  }

  &__form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px var(--grid-gap);
    padding: 20px 0;
  }

  &__now {
    grid-column: 1/3;
  }

  &__field {
    flex: 0 0 50%;
  }

  &__label {

  }

  &__input {

  }

  &__row {
    &:nth-child(odd) {
      background-color: var(--color-gray-bg);
    }

    display: flex;
    padding: 8px 20px;
    margin: 0 -20px;
  }

  &__key {
    flex: 0 0 65%;
    font-size: 16px;
    font-weight: 400;
    line-height: 150%;
    color: var(--color-gray-text);
  }

  &__value {
    flex: 1 1 auto;
    font-size: 16px;
    font-weight: 400;
    line-height: 150%;
    color: var(--color-dark);
  }

  &__link {
    flex: 1 1 auto;
    padding-right: 15px;
    font-size: 14px;
    transition: {
      property: color;
      duration: var(--default-transition-time);
    }

    &:hover {
      color: var(--primary-color);
    }
  }

  &__count {
    flex: 0 0 90px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__headers {
    display: flex;
    justify-content: space-between;
    padding: 5px 0;
  }

  &__header-item {
    font-weight: bold;

    &._count {
      flex: 0 0 90px;
    }
  }

  &__content {
    position: relative;
  }

  &__empty {
    color: var(--color-gray-text);
    font-size: 16px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 200px;
  }
}

.socials {
  &__icon {
    display: flex;

    svg {
      transition: opacity var(--default-transition-time) var(--default-timing-function);
      opacity: 0.3;

      path {
        transition: fill var(--default-transition-time) var(--default-timing-function);
      }
    }
  }

  &__link {
    display: inline-flex;

    &:hover {
      .socials__icon {
        svg {
          opacity: 1;

          path {
            fill: var(--primary-color);
          }
        }
      }
    }
  }
}

.catalog-list {
  // Для админа + входная цена
  &_for-admin {
    &:not(._hide-price) {
      .catalog-list-header {
        .price {
          grid-column: 10/11;
        }

        .incoming {
          grid-column: 11/12;
          display: block;
        }
      }
    }
  }

  // Для своих манагеров + цена сайта
  &_for-internal {
    &:not(._hide-price) {
      .catalog-list-header {
        .name {
          grid-column: 3/5;
        }

        .internal-price {
          grid-column: 5/6;
          display: block;
        }
      }

      .catalog-product {
        &__internal-price {
          grid-column: 5/6;
          display: block;
        }
        &__product-wrap {
          grid-column: 2/5;
          grid-template-columns: repeat(3, 1fr);
        }

        &__name-block {
          grid-column: 2/4;
        }
      }
    }
  }

  // Скрываем цену, двигаем столбы
  &._hide-price {
    .catalog-list-header {
      &__column {
        &.price {
          display: none;
        }

        //&.storage {
        //  grid-column: 7/10;
        //}
        //
        //&.quantity {
        //  grid-column: 10/12;
        //}

        &.sell-price {
          grid-column: 10/12;
        }
      }
    }
    .catalog-avail-list {
      &__price-block {
        display: none;
      }

      &__storage {
        grid-column: 1/3;
      }

      &__quantity {
         grid-column: 3/4;
      }
    }

    .catalog-product {
      &__internal-price {
        display: none;
      }
    }
  }

  &:not(._hide-price) {
    .catalog-list-header {
      grid-template-columns: 2fr 3fr 3fr 3fr 3fr 3fr 3fr 1fr 3fr 1fr 4fr 3fr 3fr;
    }

    .catalog-avail-list {
      &__item {
        grid-template-columns:  3fr 2fr 3fr 2fr 3fr 3fr 3fr;
      }
    }
  }

  &__item {
    border-bottom: 1px solid var(--color-gray-border);

    &_pair {
      margin-bottom: 20px;
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    }
  }

  &_for-admin,
  &_for-internal {
    .catalog-list-header {
      .sell-price {
        display: none;
      }
    }

    .catalog-product {
      &__sell-price {
        display: none;
      }
    }

    &:not(._hide-price) {
      .catalog-list-header {
        grid-template-columns: 2fr 3fr 3fr 3fr 3fr 3fr 3fr 3fr 3fr 2fr 2fr 3fr 3fr;
      }

      .catalog-avail-list {
        &__item {
          grid-template-columns:  3fr 3fr 3fr 2fr 2fr 3fr 3fr;
        }
      }
    }
  }
}

@include respond-up('large') {
  .catalog-list {

  }
}

@include respond-down('medium') {
  .catalog-list {
    width: 1280px;
    padding: 0 var(--grid-spacer);
  }
}

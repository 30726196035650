.vue-range-slider {
  &._disabled {
    pointer-events: none;

    .vue-range-slider {
      &__slider {
        .vue-slider .vue-slider-rail {
          .vue-slider-process, .vue-slider-dot-handle {
            background-color: var(--color-gray-bg);
          }
        }
      }
    }
  }

  &__label {

  }

  &__inputs {
    display: flex;
  }

  &__input {
    flex: 1 1 auto;
  }

  &__slider {
    .vue-slider {
      padding: 0 !important;
      height: 2px !important;

      .vue-slider-rail {
        background-color: var(--t);

        .vue-slider-process {
          background-color: var(--primary-color);
        }

        .vue-slider-dot-handle {
          background-color: var(--primary-color);
          position: relative;
          box-shadow: none !important;

          &::before {
            content: '';
            position: absolute;
            background-color: white;
            height: 4px;
            width: 4px;
            top: calc(50% - 2px);
            left: calc(50% - 2px);
            border-radius: 50px;
          }
        }
      }
    }
  }
}

.chat-content {
  background-color: white;  // delete
  height: 733px;  // delete
  display: flex;

  &_client {
    .chat-content {
      &__chat-wrap {
        flex: 1 1 auto;
      }
    }
  }

  &_manager {
    .chat-content {
      &__chat-wrap {
        flex: 1 1 auto;
      }
    }
  }

  &__chat-wrap {
    display: flex;
  }

  &__chat {
    flex: 0 0 100%;
  }
}

@include respond-up('large') {
  .chat-content {
    &_manager {
      .chat-content {
        &__clients-wrap {
          flex: 0 0 var(--grid-column3);
          border-right: 1px solid var(--color-gray-border);
        }
      }
    }
  }
}

@include respond-down('medium') {
  .chat-content {
    &_manager {
      .chat-content {
        &__clients-wrap {
          display: none;
        }
      }
    }

    &_manager-clients-list {
      .chat-content {
        &__chat-wrap {
          display: none;
        }

        &__clients-wrap {
          display: block;
          flex: 0 0 100%;
        }
      }
    }
  }
}
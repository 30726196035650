.identify-index-page {
  &__content {
  }

  &__total {
    color: var(--text-color-gray);
    padding: 15px 0;
    display: flex;
    justify-content: flex-end;
  }

  &__total-wrap {
    display: flex;
    justify-content: space-between;
    padding: var(--grid-gap) 0;
  }

  &__remove {
  }
}

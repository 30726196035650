.arrows {
  display: flex;
  flex-direction: row;
  align-items: center;

  .swiper-button-disabled{
    opacity: .5;
  }

  &__arrow {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background: var(--t);
    outline: 1px solid var(--color-white-text-20);
    transition: background-color var(--default-timing-function) var(--default-transition-time), opacity var(--default-timing-function) var(--default-transition-time);
  }

  &__arrow_right {
    svg {
      transform: rotate(180deg);
    }
  }
}

@include respond-up('large') {
  .arrows {
    &__arrow {
      width: 60px;
      height: 60px;

      &:hover {
        background: var(--color-white-text-20);
      }
    }

    &__arrow_left {
      margin-right: 20px;
    }
  }
}

@include respond-down('medium') {
  .arrows {
    &__arrow {
      width: 40px;
      height: 40px;

      svg {
        width: 14px;
        height: 19px;
      }
    }

    &__arrow_left {
      margin-right: 8px;
    }
  }
}

.gray-plus-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-gray-bg);

  &._extend {
    .gray-plus-button {
      &__line {
        &:nth-child(2) {
          height: 0;
          top: 50%;
        }
      }
    }
  }

  &__circle {
    position: relative;
    flex: 0 0 24px;
    height: 24px;
    width: 24px;
    margin-right: 6px;
  }

  &__line {
    position: absolute;
    background-color: var(--color-dark);

    &:nth-child(1) {
      width: 14px;
      height: 2px;
      top: calc(50% - 1px);
      left: calc(50% - 7px);
    }

    &:nth-child(2) {
      width: 2px;
      height: 14px;
      top: calc(50% - 7px);
      left: calc(50% - 1px);
      transition: {
        property: height, top;
        duration: .2s;
        timing-function: linear;
      };
    }
  }

  &__text {
    color: var(--color-dark);
    font-weight: 700;
    font-size: 15px;
    line-height: 133%;
  }
}

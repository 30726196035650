.recover-page {

  &__content-wrap {
  }

  &__inner {
    position: relative;

    &._loading {
      .recover-page {
        &__preloader {
          opacity: 1;
          pointer-events: all;
        }
      }
    }
  }

  &__form, &__finish {
    background-color: white;
    border-radius: 2px;
    padding: var(--grid-spacer);
    transition: {
      property: opacity;
      duration: var(--default-transition-time);
    };
  }

  &__button-wrapper, &__link-wrapper {
    padding-top: 20px;
    display: flex;
    justify-content: center;
  }

  &__header {
    margin-bottom: 40px;
  }

  &__title {
    margin-bottom: 10px;
  }

  &__subtitle {
    margin-bottom: 10px;
  }

  &__fields {
    .form-field {
      margin-bottom: 20px;
    }
  }

  &__link {
  }

  &__preloader {
    pointer-events: none;
    opacity: 0;
    transition: {
      property: opacity, transform;
      duration: var(--default-transition-time);
    };
  }
}

@include respond-up('large') {
  .recover-page {
    padding-top: var(--index-header-height, 83px);

    &__content-wrap {
      padding: 100px 0;
    }

    &__inner {
      grid-column: 3/10;
    }

    &__fields {
      width: 300px;
    }

    &__link {
      min-width: 150px;
    }
  }
}

@include respond-down('medium') {
  .recover-page {
    padding-top: var(--index-header-height, 50px);

    &__content-wrap {
      padding: 60px 0;
    }

    &__inner {
      grid-column: 1/5;
    }

    &__fields {
      width: 100%;
    }

    &__link {
      width: 100%;
    }
  }
}

.stat-price-move {
  padding-top: 60px;

  &__tabs {
    display: flex;
    margin-bottom: 40px;
  }

  &__tab-contents {
    .stat-graph {
      opacity: 0;
      position: absolute;
      pointer-events: none;

      &._active {
        opacity: 1;
        position: relative;
        pointer-events: all;
      }
    }
  }
}
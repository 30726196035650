.markup-edit-page {
  &__back-link {
    display: flex;
    align-items: center;
    color: var(--primary-color);
    margin-bottom: 30px;

    svg {
      margin-right: 9px;
    }
  }

  &__tree {
    padding: 60px 0 100px;
  }
}
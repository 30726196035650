.one-c-products {
  &__h3 {

  }

  &__headers {

  }

  &__headers, &__opener {
    display: grid;
    grid-template-columns: repeat(9, 1fr);
  }

  &__header, &__value {
    &:first-child {
      padding-left: var(--grid-spacer);
    }
    &:last-child {
      padding-right: var(--grid-spacer);
    }

    &_number {grid-column: 1/2}
    &_name {grid-column: 2/6}
    &_quantity {grid-column: 6/7}
    &_price {grid-column: 7/8}
    &_state {grid-column: 8/10}

    &_price, &_quantity {
      text-align: center;
      justify-content: center;
    }
  }

  &__header {

  }

  &__list {

  }

  &__item {
    &:nth-child(n + 2) {
      border-top: 1px solid var(--border-color);
    }
  }

  &__opener {
    position: relative;
    background-color: white;
    grid-gap: var(--grid-gap);
    padding: 16px 0;
  }

  &__value {

  }

  &__open-icon {
    position: absolute;
    right: 16px;
    top: 0;
    height: 100%;
    display: flex;
    align-items: center;
  }

  &__additional-wrap {
    background-color: rgba(241,241,244,.7);
    overflow: hidden;
    transition: height .4s;
    height: 0;
  }

  &__additional {
    padding: 20px 0;
  }

  &__info {
  }

  &__title {
    padding: 20px 0 15px;
  }
}

@include respond-up('large') {
  .one-c-products {

  }
}

@include respond-down('medium') {
  .one-c-products {

  }
}